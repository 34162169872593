const ContactUs = () => {
  return (
    <div className="card card-info">
      <div className="card-header">
        <h3 className="card-title">Bize Ulaşın</h3>
      </div>
      {/* /.card-header */}
      {/* form start */}
      <div className="card-body row">
        <div className="col-12 col-sm-6 col-md-6 d-flex align-items-stretch flex-column">
          <div className="card bg-light d-flex flex-fill">
            <div className="card-header text-muted text-lg border-bottom-0">
              Yazılım Geliştirici
            </div>
            <div className="card-body pt-0">
              <div className="row">
                <div className="col-7">
                  <br />
                  <h2 className="lead">
                    <b>İbrahim Yılmaz</b>
                  </h2>
                  <br />
                  <p className="text-muted text-m">
                    <b>Hakkında: </b> Web Tasarımcı / Mobil Tasarımcı{" "}
                  </p>
                  <ul className="ml-4 mb-0 fa-ul text-muted text-m">
                    <li>
                      <span className="fa-li">
                        <i className="fas fa-lg fa-phone" />
                      </span>
                      <a href="https://wa.me/905368306456" target="_blank">
                        +90 - (536) 830-6456
                      </a>
                    </li>
                    <br />
                    <li>
                      <span className="fa-li">
                        <i className="fas fa-lg fa-envelope" />
                      </span>
                      <a href="mailto:ibrahimyilmaz026@gmail.com">
                        ibrahimyilmaz026@gmail.com
                      </a>
                    </li>
                    <br />
                    <li>
                      <span className="fa-li">
                        <i className="fab fa-lg fa-github" />
                      </span>
                      <a href="https://github.com/ylmzibrahim" target="_blank">
                        https://github.com/ylmzibrahim
                      </a>
                    </li>
                    <br />
                    <li>
                      <span className="fa-li">
                        <i className="fab fa-lg fa-linkedin" />
                      </span>
                      <a
                        href="https://www.linkedin.com/in/ibrahim-yilmaz-94aaba177"
                        target="_blank"
                      >
                        https://www.linkedin.com/in/ibrahim-yilmaz-94aaba177
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-5 text-center">
                  <img
                    src={require("./assets/img/ibrahim.png")}
                    alt="user-avatar"
                    className="img-circle img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-md-6 d-flex align-items-stretch flex-column">
          <div className="card bg-light d-flex flex-fill">
            <div className="card-header text-muted text-lg border-bottom-0">
              Yazılım Geliştirici
            </div>
            <div className="card-body pt-0">
              <div className="row">
                <div className="col-7">
                  <br />
                  <h2 className="lead">
                    <b>Yusuf Nadaroğlu</b>
                  </h2>
                  <br />
                  <p className="text-muted text-m">
                    <b>Hakkında: </b> Web Tasarımcı / Mobil Tasarımcı
                  </p>
                  <ul className="ml-4 mb-0 fa-ul text-muted">
                    <li>
                      <span className="fa-li">
                        <i className="fas fa-lg fa-phone" />
                      </span>
                      <a href="https://wa.me/905529339668" target="_blank">
                        + 90 - (552) 933-9668
                      </a>
                    </li>
                    <br />
                    <li>
                      <span className="fa-li">
                        <i className="fas fa-lg fa-envelope" />
                      </span>
                      <a href="mailto:yusufnadaroglu@gmail.com">
                        yusufnadaroglu@gmail.com
                      </a>
                    </li>
                    <br />
                    <li>
                      <span className="fa-li">
                        <i className="fab fa-lg fa-github" />
                      </span>
                      <a href="https://github.com/yusufnadar" target="_blank">
                        https://github.com/yusufnadar
                      </a>
                    </li>
                    <br />
                    <li>
                      <span className="fa-li">
                        <i className="fab fa-lg fa-linkedin" />
                      </span>
                      <a
                        href="https://www.linkedin.com/in/yusuf-nadaroğlu/"
                        target="_blank"
                      >
                        https://www.linkedin.com/in/yusuf-nadaroğlu/
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-5 text-center">
                  <img
                    src={require("./assets/img/yusuf.png")}
                    alt="user-avatar"
                    className="img-circle img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
