import Header from "./Header";
import Menu from "./Menu";
import Dashboard from "./Dashboard";
import Footer from "./Footer";

const MainPage = ({ page }) => {
  return (
    <div className="wrapper">
      <Header />
      <Menu page={page} />
      <Dashboard page={page} />
      <Footer />
    </div>
  );
};

export default MainPage;
