import ContactUs from "./ContactUs";
import DashboardPage from "./DashboardPage";
import QuestionBank from "./QuestionBank";
import DictionaryPage from "./DictionaryPage";
import ReadPage from "./ReadPage";
import WatchPage from "./WatchPage";
import { Link } from "react-router-dom";
import AskPage from "./AskPage";
import AskAnswerPage from "./AskAnswerPage";
import BlogPage from "./BlogPage";
import SnapPage from "./SnapPage";
import CalendarPage from "./CalendarPage";
import PastLiveVideoPage from "./PastLiveVideoPage";
import MembersPage from "./MembersPage";
import CreateMemberPage from "./CreateMemberPage";
import ClassesPage from "./ClassesPage";
import PollPage from "./PollPage";
import MobilePage from "./MobilePage";
import PackagesPage from "./PackagesPage";
import References from "./References";
import LiveClasses from "./LiveClasses";
import CreateBills from "./CreateBills";
import Panels from "./Panels";
import Finans from "./Finans";

const Dashboard = ({ page }) => {
  return (
    <div>
      {/* Content Wrapper. Contains page content */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">{page}</h1>
              </div>
              {/* /.col */}
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Genel</Link>
                  </li>
                  <li className="breadcrumb-item active">{page}</li>
                </ol>
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container-fluid */}
        </div>
        {/* /.content-header */}
        {/* Main content */}
        <div className="content">
          <div className="row d-flex align-items-center">
            <div className="col-md-12">
              {page === "Anasayfa" && <DashboardPage />}
              {page === "Soru Bankası" && <QuestionBank />}
              {page === "Sözlük" && <DictionaryPage />}
              {page === "Oku" && <ReadPage />}
              {page === "İzle" && <WatchPage />}
              {page === "Sorular" && <AskPage />}
              {page === "Soru Cevapla" && <AskAnswerPage />}
              {page === "Blog" && <BlogPage />}
              {page === "Snap" && <SnapPage />}
              {page === "Anket" && <PollPage />}
              {page === "Geçmiş Canlı Dersler" && <PastLiveVideoPage />}
              {page === "Canlı Ders Takvimi" && <CalendarPage />}
              {page === "Üye İşlemleri" && <MembersPage />}
              {page === "Üye Oluşturma" && <CreateMemberPage />}
              {page === "Sınıf İşlemleri" && <ClassesPage />}
              {page === "Ödeme Paketleri" && <PackagesPage />}
              {page === "Mobil Uygulama" && <MobilePage />}
              {page === "Bize Ulaşın" && <ContactUs />}
              {page === "Referanslar" && <References />}
              {page === "Canlı Dersler" && <LiveClasses />}
              {page === "Fatura Oluştur" && <CreateBills />}
              {page === "Paneller" && <Panels />}
              {page === "Finans" && <Finans />}
            </div>
          </div>
        </div>
        {/* /.content */}
      </div>
      {/* /.content-wrapper */}
    </div>
  );
};

export default Dashboard;
