import React from "react";
import Modal from "react-modal";
import { HiOutlineNewspaper } from "react-icons/hi2";
import { MdProductionQuantityLimits } from "react-icons/md";
const ModalComp = ({ isOpen, closeModal, setModalState }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel="Modal içeriği"
    >
      {" "}
      <h3 className=" flex justify-center mt-10 text-2xl italic text-blue-800">
        Merhaba hoş geldiniz. Ekleme yapmak istediğiniz kategoriyi seçin.
      </h3>
      <div className="flex flex-col justify-start mt-10 items-center  bg-white ">
        <div className="grid grid-cols-4 gap-5 w-4/6">
          <button
            className=" p-1 flex justify-center items-center flex-col rounded-md relative  h-[8rem] hover:shadow-lg"
            onClick={() => {
              closeModal();
              setModalState(1);
            }}
          >
            <img
              className="w-[5rem]"
              src="https://w1.pngwing.com/pngs/57/138/png-transparent-newspaper-icon-world-news-headline-google-news-plugin-newsroom-icon-design-yellow-thumbnail.png"
            />
            <p className="text-lg italic absolute bottom-0 mb-1 text-slate-600">
              Haberler
            </p>
          </button>
          <button
            className=" p-1 flex justify-center items-center flex-col rounded-md relative  h-[8rem] hover:shadow-lg"
            onClick={() => {
              closeModal();
              setModalState(2);
            }}
          >
            <img
              className="w-[5rem]"
              src="https://img2.pngindir.com/20180528/vx/kisspng-advertising-campaign-management-organization-marke-campaign-5b0bfd7950b6e9.8843188615275124413306.jpg"
            />

            <p className="text-lg italic absolute bottom-0 mb-1 text-slate-600">
              Kampanyalar
            </p>
          </button>
          <button
            className=" p-1 flex justify-center items-center flex-col rounded-md relative h-[8rem] hover:shadow-lg"
            onClick={() => {
              closeModal();
              setModalState(3);
            }}
          >
            <img
              className="w-[5rem]"
              src="https://w7.pngwing.com/pngs/409/593/png-transparent-two-women-and-one-man-in-corporate-attire-discussing-business-icon-business-enterprise-design-business-woman-photography-people-thumbnail.png"
            />
            <p className="text-lg italic absolute bottom-0 mb-1 text-slate-600">
              {" "}
              Kurumsal
            </p>
          </button>
          <button
            className=" p-1 flex justify-center items-center flex-col rounded-md relative h-[8rem] hover:shadow-lg"
            onClick={() => {
              closeModal();
              setModalState(4);
            }}
          >
            <img
              className="w-[5rem]"
              src="https://img1.pngindir.com/20180531/qay/kisspng-environmentally-friendly-environmental-protection-social-responsibility-5b0fb708ca46a8.9186356815277565528285.jpg"
            />
            <p className="text-lg italic absolute bottom-0 mb-1 text-slate-600">
              {" "}
              Sosyal Sorumluluk
            </p>
          </button>
          <button
            className=" p-1 flex justify-center items-center flex-col rounded-md relative h-[8rem] hover:shadow-lg"
            onClick={() => {
              closeModal();
              setModalState(5);
            }}
          >
            <img
              className="w-[5rem]"
              src="https://img.lovepik.com/element/45007/0586.png_860.png"
            />
            <p className="text-lg italic absolute bottom-0 mb-1 text-slate-600">
              {" "}
              Blog
            </p>
          </button>
          <button
            className=" p-1 flex justify-center items-center flex-col rounded-md relative h-[8rem] hover:shadow-lg"
            onClick={() => {
              closeModal();
              setModalState(6);
            }}
          >
            <img
              className="w-[5rem]"
              src="https://i0.wp.com/kartaca.com/wp-content/uploads/2020/08/kartaca-press.png?w=1180&ssl=1"
            />
            <p className="text-lg italic absolute bottom-0 mb-1 text-slate-600">
              Basında Biz
            </p>
          </button>
          <button
            className=" p-1 flex justify-center items-center flex-col rounded-md relative h-[8rem] hover:shadow-lg"
            onClick={() => {
              closeModal();
              setModalState(7);
            }}
          >
            <img
              className="h-[4rem]"
              src="https://w7.pngwing.com/pngs/571/388/png-transparent-career-rxe9sumxe9-job-fair-career-advancement-s-template-text-poster-thumbnail.png"
            />
            <p className="text-lg italic absolute bottom-0 mb-1 text-slate-600">
              Kariyer
            </p>
          </button>
          <button
            className=" p-1 flex justify-center items-center flex-col rounded-md relative h-[8rem] hover:shadow-lg"
            onClick={() => {
              setModalState(8);
              closeModal();
            }}
          >
            <img
              className="w-[5rem]"
              src="https://www.pngmart.com/files/7/Support-PNG-Transparent-Picture.png"
            />
            <p className="text-lg italic absolute bottom-0 mb-1 text-slate-600">
              {" "}
              Destek
            </p>
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalComp;
