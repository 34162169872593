import { Route, Routes } from "react-router-dom";
import MainPage from "./MainPage";
import NotFound from "./NotFound";
import { useCookies } from "react-cookie";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import Var from "./variables";
import Login from "./Login";

function App() {
  const [cookies, setCookie, removeCookie] = useCookies();
  const navigate = useNavigate();

  useEffect(() => {
    if (cookies.admin_token == undefined) {
      navigate("/giris");
    } else {
      fetch(`${Var.api_url}/user/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${cookies.admin_token}`,
        },
      })
        .then((res) => {
          if (!res.ok) {
            removeCookie("admin_user");
            removeCookie("admin_token");
            navigate("/giris");
            throw Error("Token süreniz dolmuştur.");
          }
          return res.json();
        })
        .then((data) => {
          setCookie("admin_user", data, { path: "/" });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  return (
    <div>
      <Routes>
        <Route exact path="/" element={<MainPage page={"Anasayfa"} />} />
        <Route exact path="/giris" element={<Login />} />
        <Route
          exact
          path="/soru-bankasi"
          element={<MainPage page={"Soru Bankası"} />}
        />
        <Route exact path="/sozluk" element={<MainPage page={"Sözlük"} />} />
        <Route exact path="/oku" element={<MainPage page={"Oku"} />} />
        <Route exact path="/izle" element={<MainPage page={"İzle"} />} />
        <Route exact path="/sorular" element={<MainPage page={"Sorular"} />} />
        <Route
          exact
          path="/soru-cevapla"
          element={<MainPage page={"Soru Cevapla"} />}
        />
        <Route exact path="/blog" element={<MainPage page={"Blog"} />} />
        <Route exact path="/snap" element={<MainPage page={"Snap"} />} />
        <Route exact path="/anket" element={<MainPage page={"Anket"} />} />
        <Route
          exact
          path="/gecmis-canli-dersler"
          element={<MainPage page={"Geçmiş Canlı Dersler"} />}
        />
        <Route
          exact
          path="/canli-ders-takvimi"
          element={<MainPage page={"Canlı Ders Takvimi"} />}
        />
        <Route
          exact
          path="/uye-islemleri"
          element={<MainPage page={"Üye İşlemleri"} />}
        />
        <Route
          exact
          path="/uye-olusturma"
          element={<MainPage page={"Üye Oluşturma"} />}
        />
        <Route
          exact
          path="/sinif-islemleri"
          element={<MainPage page={"Sınıf İşlemleri"} />}
        />
        <Route
          exact
          path="/odeme-paketleri"
          element={<MainPage page={"Ödeme Paketleri"} />}
        />
        <Route
          exact
          path="/referanslar"
          element={<MainPage page={"Referanslar"} />}
        />
        <Route
          exact
          path="/canli-dersler"
          element={<MainPage page={"Canlı Dersler"} />}
        />
        <Route
          exact
          path="/fatura-olustur"
          element={<MainPage page={"Fatura Oluştur"} />}
        />
        <Route
          exact
          path="/paneller"
          element={<MainPage page={"Paneller"} />}
        />
        <Route exact path="/finans" element={<MainPage page={"Finans"} />} />
        {/* <Route exact path="/mobil-uygulama" element={<MainPage page={"Mobil Uygulama"} />} /> */}

        <Route
          exact
          path="/bize-ulasin"
          element={<MainPage page={"Bize Ulaşın"} />}
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
