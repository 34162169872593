import { useState, useEffect } from "react";
import Swal from "sweetalert2";
import Var from "./variables";
import { useCookies } from "react-cookie";
import { MDBDataTableV5 } from "mdbreact";
import Select from "react-select";
import JoditEditor from "jodit-react";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/solid";
import Moment from "moment";

const MembersPage = () => {
  const [name, setName] = useState();
  const [surname, setSurname] = useState();
  const [classId, setClassId] = useState();
  const [classTitle, setClassTitle] = useState();
  const [number, setNumber] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [birthday, setBirthday] = useState();
  const [city, setCity] = useState();
  const [country, setCountry] = useState();
  const [email, setEmail] = useState();
  const [gender, setGender] = useState();
  const [isAccess, setIsAccess] = useState();
  const [isAdmin, setIsAdmin] = useState();
  const [isLeader, setIsLeader] = useState();
  const [isPaid, setIsPaid] = useState();
  const [level, setLevel] = useState();
  const [week, setWeek] = useState();
  const [score, setScore] = useState();
  const [purpose, setPurpose] = useState();
  const [note, setNote] = useState();
  const [references, setReferences] = useState();
  const [sector, setSector] = useState();
  const [profilePhoto, setProfilePhoto] = useState(null);

  const [resetPassword, setResetPassword] = useState(false);

  const [userID, setUserID] = useState();
  const [classes, setClasses] = useState([]);

  const [updateClicked, setUpdateClicked] = useState(false);

  const [selectClasses, setSelectClasses] = useState([]);
  const [classesReady, setClassesReady] = useState(false);
  const [dataReady, setDataReady] = useState(false);
  const [cookies] = useCookies();

  const active = <CheckCircleIcon className="fill-green-500 w-7" />;
  const passive = <XCircleIcon className="fill-red-500 w-7" />;

  const config = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
  };

  const [tempData, setTempData] = useState([]);
  const [datatable, setDatatable] = useState({
    columns: [
      {
        label: "Tarih",
        field: "date",
      },
      {
        label: "Profil",
        field: "profilePhoto",
      },
      {
        label: "No",
        field: "number",
      },

      {
        label: "Ad",
        field: "name",
        sort: "asc",
      },
      {
        label: "Soyad",
        field: "surname",
      },
      {
        label: "Email",
        field: "email",
      },
      {
        label: "Tel",
        field: "phoneNumber",
      },
      {
        label: "Cinsiyet",
        field: "gender",
      },
      {
        label: "Doğum Günü",
        field: "birthday",
      },
      {
        label: "Ülke",
        field: "country",
      },
      {
        label: "Şehir",
        field: "city",
      },

      {
        label: "Sınıf",
        field: "classId",
      },
      {
        label: "Sinif adi",
        field: "className",
      },
      // {
      //   label: "Aktif",
      //   field: "isAccess",
      // },
      // {
      //   label: "Ödedi",
      //   field: "isPaid",
      // },
      // {
      //   label: "Not",
      //   field: "note",
      // },
      // {
      //   label: "Referanslar",
      //   field: "references",
      // },

      // {
      //   label: "Admin",
      //   field: "isAdmin",
      // },
      // {
      //   label: "Lider",
      //   field: "isLeader",
      // },
      // {
      //   label: "Seviye",
      //   field: "level",
      // },
      // {
      //   label: "Hafta",
      //   field: "week",
      // },
      // {
      //   label: "Amaç",
      //   field: "purpose",
      // },
      // {
      //   label: "Sektör",
      //   field: "sector",
      // },
      // {
      //   label: "Skor",
      //   field: "score",
      // },
    ],
    rows: [],
  });
  const [checkbox, setCheckbox] = useState("");

  var Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 5000,
  });

  useEffect(() => {
    fetch(`${Var.api_url}/user/users`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookies.admin_token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => setTempData(data))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (tempData.length !== 0) {
      fetch(`${Var.api_url}/class/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${cookies.admin_token}`,
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          console.log(data);
          setClasses(data);
          setClassesCLickHandler(data);
        })
        .catch((err) => console.log(err));
    }
  }, [tempData]);

  useEffect(() => {
    if (classes.length !== 0) {
      let tempArray = [...tempData];
      tempData.map((data, index) => {
        tempArray[index].profilePhoto = (
          <div className="w-10 h-10">
            <img
              className="w-10 h-10 rounded-full"
              src={tempArray[index].profilePhoto}
              alt=""
            />
          </div>
        );

        tempArray[index].className = getClassByID(tempArray[index].classId);

        tempArray[index].isAccess = tempArray[index].isAccess
          ? active
          : passive;

        tempArray[index].isPaid = tempArray[index].isPaid ? active : passive;

        tempArray[index].isAdmin =
          tempArray[index].isAdmin === 1 ? "Evet" : "Hayır";

        tempArray[index].isLeader =
          tempArray[index].isLeader === 1 ? "Evet" : "Hayır";

        tempArray[index].gender =
          tempArray[index].gender === 0
            ? "Erkek"
            : tempArray[index].gender === 1
            ? "Kadın"
            : "Belirtmek İstemiyorum";

        if (tempArray[index].birthday !== null)
          tempArray[index].birthday = Moment(tempArray[index].birthday).format(
            "DD/MM/YYYY"
          );

        tempArray[index].date = Moment(tempArray[index].date).format(
          "DD/MM/YYYY HH:mm:ss"
        );
      });
      setDatatable({ ...datatable, rows: tempArray });
      setDataReady(true);
    }
  }, [classes]);

  const getClassByID = (id) => {
    let string = "";
    classes.filter((c) => {
      if (c.id == id) {
        string = c.title;
      }
    });
    return string;
  };

  const getClassTitleByID = (id) => {
    return classes.filter(function (classes) {
      if (classes.id == id) {
        return classes.title;
      }
    });
  };

  const getClassByTitle = (title) => {
    return classes.filter(function (classes) {
      return classes.title == title;
    });
  };

  useEffect(() => {
    if (selectClasses.length !== 0) {
      setClassesReady(true);
    }
  }, [selectClasses]);

  const setClassesCLickHandler = (datas) => {
    let tempClasses = [];
    datas.map((data, i) => {
      tempClasses.push({
        value: data.id,
        label: data.title,
      });
    });
    setSelectClasses(tempClasses);
  };

  const updateUser = () => {
    if (
      name !== "" &&
      surname !== "" &&
      email !== "" &&
      phoneNumber !== "" &&
      classId !== ""
    ) {
      if (resetPassword) {
        fetch(`${Var.api_url}/user/${userID}`, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookies.admin_token}`,
          },
          body: JSON.stringify({
            name,
            surname,
            email,
            password: "idlactr22",
            number,
            phoneNumber,
            city,
            classId,
            country,
            sector,
            purpose,
            note,
            references,
            isLeader: isLeader === "Evet" || isLeader == 1 ? 1 : 0,
            isAdmin: isAdmin === "Evet" || isAdmin == 1 ? 1 : 0,
            gender:
              gender === "Kadın" || gender == 1
                ? 1
                : gender === "Erkek" || gender == 0
                ? 0
                : 2,
            isPaid,
            level,
            week,
            birthday,
            isAccess: isAccess === "1" ? true : false,
          }),
        })
          .then((res) => {
            if (!res.ok) {
              Toast.fire({
                icon: "error",
                title: "Bir hata oluştu",
              });
            }
            return res.json();
          })
          .then((data) => {
            if (profilePhoto !== null) {
              changePhotoHandler();
            } else {
              Toast.fire({
                icon: "success",
                title: data.message,
              });
              // setTimeout(() => {
              //   window.location.reload();
              // }, 1000);
            }
          });
      } else {
        fetch(`${Var.api_url}/user/${userID}`, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookies.admin_token}`,
          },
          body: JSON.stringify({
            name,
            surname,
            email,
            number,
            phoneNumber,
            city,
            classId,
            country,
            sector,
            purpose,
            note,
            references,
            isLeader: isLeader === "Evet" || isLeader == 1 ? 1 : 0,
            isAdmin: isAdmin === "Evet" || isAdmin == 1 ? 1 : 0,
            gender:
              gender === "Kadın" || gender == 1
                ? 1
                : gender === "Erkek" || gender == 0
                ? 0
                : 2,
            isPaid,
            level,
            week,
            birthday,
            isAccess: isAccess === "1" ? true : false,
          }),
        })
          .then((res) => {
            if (!res.ok) {
              Toast.fire({
                icon: "error",
                title: "Bir hata oluştu",
              });
            }
            return res.json();
          })
          .then((data) => {
            if (profilePhoto !== null) {
              changePhotoHandler();
            } else {
              Toast.fire({
                icon: "success",
                title: data.message,
              });
              // setTimeout(() => {
              //   window.location.reload();
              // }, 1000);
            }
          });
      }
    } else {
      alert("Tüm boşlukları doğru bir şekilde doldurunuz");
    }
  };

  const changePhotoHandler = () => {
    const formData = new FormData();
    formData.append("photo", profilePhoto);

    fetch(`${Var.api_url}/user/photo/${userID}`, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${cookies.token}`,
      },
      body: formData,
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        Toast.fire({
          icon: "success",
          title: data.message,
        });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((err) => console.log(err));
  };

  const getClassId = (title) => {
    let id = 0;
    classes.filter((c) => {
      if (c.title == title) {
        id = c.id;
      }
    });
    return id;
  };

  const updateClickHandler = () => {
    if (checkbox !== "") {
      setUpdateClicked(true);
      setNumber(checkbox.number);
      setName(checkbox.name);
      setSurname(checkbox.surname);
      setPhoneNumber(checkbox.phoneNumber);
      setClassId(checkbox.classId);
      setClassTitle(checkbox.className);
      checkbox.isAccess.props.className === active.props.className
        ? setIsAccess("1")
        : setIsAccess("0");
      checkbox.isPaid.props.className === active.props.className
        ? setIsPaid("1")
        : setIsPaid("0");
      setBirthday(checkbox.birthday);
      setCity(checkbox.city);
      setCountry(checkbox.country);
      setEmail(checkbox.email);
      setGender(checkbox.gender);
      setIsAdmin(checkbox.isAdmin);
      setIsLeader(checkbox.isLeader);
      setLevel(checkbox.level);
      setWeek(checkbox.week);
      setPurpose(checkbox.purpose);
      setSector(checkbox.sector);
      setNote(checkbox.note);
      setReferences(checkbox.references);
      if (profilePhoto !== null) setProfilePhoto(checkbox.profilePhoto);


      setUserID(checkbox.id);
    } else {
      Toast.fire({
        icon: "error",
        title: "Önce seçim yapınız!",
      });
    }
  };

  const deleteClickHandler = () => {
    if (checkbox) {
      fetch(`${Var.api_url}/user/${checkbox.id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${cookies.admin_token}`,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          Swal.fire({
            title: "Başarılı!",
            text: res.message,
            icon: "success",
            confirmButtonText: "Tamam",
          }).then(() => {
            window.location.reload();
          });
        });
    }
  };

  return (
    <div>
      {updateClicked && (
        <div className="card card-info">
          <div className="card-header">
            <h3 className="card-title">Üye Güncelleme</h3>
          </div>
          {/* /.card-header */}
          {/* form start */}
          <div className="card-body row">
            <form className="col-md-12">
              <div className="card">
                <div className="card-header row">
                  <div>
                    Üye listesinde gözükmesini istediklerinizi aşağıda
                    güncelleyebilirsiniz.
                  </div>
                </div>
                {/* /.card-header */}
                <div className="card-body">
                  <label>Profil Fotoğrafı</label>
                  <input
                    id="input-changePhoto"
                    type="file"
                    className="form-control"
                    onChange={(e) => setProfilePhoto(e.target.files[0])}
                  />
                  <br />
                  <label>
                    Ad <label className="text-danger">*</label>
                  </label>
                  <input
                    className="form-control"
                    placeholder="Adı..."
                    value={name || ""}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <br />
                  <label>
                    Soyad <label className="text-danger">*</label>
                  </label>
                  <input
                    className="form-control"
                    placeholder="Adı..."
                    value={surname || ""}
                    onChange={(e) => setSurname(e.target.value)}
                  />
                  <br />
                  <label>
                    Sınıf <label className="text-danger">*</label>
                  </label>
                  {classesReady && (
                    <Select
                      className="w-full"
                      defaultValue={{ label: classTitle }}
                      onChange={(e) => setClassId(e.value)}
                      options={selectClasses}
                      placeholder={"Sınıf"}
                    />
                  )}
                  <br />
                  <label>
                    Öğrenci No <label className="text-danger">*</label>
                  </label>
                  <input
                    className="form-control"
                    placeholder="Öğrenci No..."
                    value={number || ""}
                    onChange={(e) => setNumber(e.target.value)}
                  />
                  <br />
                  <label>
                    Telefon No <label className="text-danger">*</label>
                  </label>
                  <input
                    className="form-control"
                    placeholder="Telefon No..."
                    value={phoneNumber || ""}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                  <br />
                  <label>
                    Email <label className="text-danger">*</label>
                  </label>
                  <input
                    className="form-control"
                    placeholder="Email..."
                    value={email || ""}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <br />
                  {/* <input
                    type="checkbox"
                    className="mx-2"
                    onChange={(e) => setResetPassword(e.target.checked)}
                  />
                  <label className="my-4">
                    Şifre Sıfırla{" "}
                    <label className="text-slate-600">
                      {" "}
                      (Şifre idlactr22 olarak değiştirilecektir)
                    </label>
                  </label>
                  <br /> */}
                  <label>
                    Cinsiyet <label className="text-danger">*</label>
                  </label>
                  <Select
                    className="w-40"
                    defaultValue={gender}
                    onChange={(e) => setGender(e.value)}
                    options={Var.genders}
                    placeholder={gender}
                  />
                  <br />
                  <label>Doğum Günü</label>
                  <input
                    className="form-control w-40"
                    placeholder="Doğum Günü..."
                    type="date"
                    defaultValue={Moment(birthday).format("YYYY-MM-DD") || ""}
                    onChange={(e) => {
                      setBirthday(e.target.value);
                    }}
                  />
                  <br />
                  <label>Şehir</label>
                  <input
                    className="form-control"
                    placeholder="Şehir..."
                    value={city || ""}
                    onChange={(e) => setCity(e.target.value)}
                  />
                  <br />
                  <label>Ülke</label>
                  <input
                    className="form-control"
                    placeholder="Ülke..."
                    value={country || ""}
                    onChange={(e) => setCountry(e.target.value)}
                  />
                  <br />
                  <label>Amaç</label>
                  <input
                    className="form-control"
                    placeholder="Amaç..."
                    value={purpose || ""}
                    onChange={(e) => setPurpose(e.target.value)}
                  />
                  <label>Not</label>
                  <input
                    className="form-control"
                    placeholder="Not..."
                    value={note || ""}
                    onChange={(e) => setNote(e.target.value)}
                  />
                  <label>Referans</label>
                  <input
                    className="form-control"
                    placeholder="Referans..."
                    value={references || ""}
                    onChange={(e) => setReferences(e.target.value)}
                  />
                  <br />
                  <div className="col-md-12 d-flex flex-row mb-5">
                    <div className="d-flex flex-row align-items-center col-md-3">
                      <label className="mr-2" type="text">
                        Seviye
                      </label>
                      <Select
                        defaultValue={level}
                        onChange={(e) => setLevel(e.value)}
                        options={Var.optionsLevel}
                        placeholder={level}
                      />
                    </div>
                    <div className="d-flex flex-row align-items-center col-md-3">
                      <label className="mr-2" type="text">
                        Hafta
                      </label>
                      <Select
                        defaultValue={week}
                        onChange={(e) => setWeek(e.value)}
                        options={Var.optionsWeek}
                        placeholder={week}
                      />
                    </div>
                    <div className="d-flex flex-row align-items-center col-md-3">
                      <label className="mr-2" type="text">
                        Kapsam
                      </label>
                      <Select isDisabled />
                    </div>
                    <div className="d-flex flex-row align-items-center col-md-3">
                      <label className="mr-2" type="text">
                        Sektör
                      </label>
                      <Select
                        className="w-40"
                        defaultValue={sector}
                        onChange={(e) => setSector(e.value)}
                        options={Var.sectors}
                        placeholder={sector}
                      />
                    </div>
                  </div>
                  <br />
                  <div className="col-md-12 d-flex flex-row mb-5">
                    <div className="d-flex flex-row align-items-center col-md-3">
                      <label className="mr-2" type="text">
                        Aktif
                      </label>
                      <Select
                        defaultValue={isAccess}
                        onChange={(e) => setIsAccess(e.value)}
                        options={Var.isTrue}
                        placeholder={isAccess}
                      />
                    </div>
                    <div className="d-flex flex-row align-items-center col-md-3">
                      <label className="mr-2" type="text">
                        Ödedi
                      </label>
                      <Select
                        defaultValue={isPaid}
                        onChange={(e) => setIsPaid(e.value)}
                        options={Var.isTrue}
                        placeholder={isPaid}
                      />
                    </div>
                    <div className="d-flex flex-row align-items-center col-md-3">
                      <label className="mr-2" type="text">
                        Admin
                      </label>
                      <Select
                        defaultValue={isAdmin}
                        onChange={(e) => setIsAdmin(e.value)}
                        options={Var.isTrue}
                        placeholder={isAdmin}
                      />
                    </div>
                    <div className="d-flex flex-row align-items-center col-md-3">
                      <label className="mr-2" type="text">
                        Lider
                      </label>
                      <Select
                        defaultValue={isLeader}
                        onChange={(e) => setIsLeader(e.value)}
                        options={Var.isTrue}
                        placeholder={isLeader}
                      />
                    </div>
                  </div>
                  <br />
                  <button
                    type="button"
                    className="btn bg-green-700 hover:bg-green-800 text-white btn-block col-md-2 ml-0"
                    onClick={() => {
                      updateUser();
                    }}
                  >
                    Güncelle
                  </button>
                </div>
                {/* /.card-body */}
              </div>
              {/* /.card */}
            </form>
          </div>
        </div>
      )}

      {/* Oku Listesi */}
      <div className="card card-info">
        <div className="card-header">
          <h3 className="card-title">Üye Listesi</h3>
        </div>
        {/* /.card-header */}
        {/* form start */}
        <div className="card-body row">
          <form className="col-md-12">
            <div className="card">
              <div className="card-header row">
                <div>
                  Üye listesindeki detayları burada düzenleyebilirsiniz.
                </div>
                <div className="col-md-4 ml-auto flex flex-wrap items-center justify-end">
                  <button
                    type="button"
                    className="btn bg-red-700 hover:bg-red-800 text-white w-40 h-10 m-1"
                    onClick={() => deleteClickHandler()}
                  >
                    Sil
                  </button>
                  {!updateClicked && (
                    <button
                      type="button"
                      className="btn bg-green-700 hover:bg-green-800 text-white w-40 h-10 m-1"
                      onClick={() => updateClickHandler()}
                    >
                      Güncelle
                    </button>
                  )}
                  {updateClicked && (
                    <button
                      type="button"
                      className="btn bg-yellow-500 hover:bg-yellow-600 text-white w-40 h-10 m-1"
                      onClick={() => setUpdateClicked(false)}
                    >
                      Vazgeç
                    </button>
                  )}
                </div>
              </div>
              {/* /.card-header */}
              <div className="card-body">
                {dataReady && (
                  <MDBDataTableV5
                    hover
                    entriesOptions={[25, 50, 100]}
                    entries={25}
                    pagesAmount={4}
                    data={datatable}
                    // scrollX
                    checkbox="true"
                    searchTop
                    searchBottom={false}
                    headCheckboxID="id2"
                    bodyCheckboxID="checkboxes2"
                    getValueCheckBox={(e) => {
                      checkbox === ""
                        ? setCheckbox(e)
                        : checkbox.id === e.id
                        ? setCheckbox("")
                        : setCheckbox(e);
                    }}
                  />
                )}
              </div>
              {/* /.card-body */}
            </div>
            {/* /.card */}
          </form>
        </div>
      </div>
    </div>
  );
};

export default MembersPage;
