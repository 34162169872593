import { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import Select from 'react-select';
import Var from './variables';
import { useCookies } from 'react-cookie';
import { MDBDataTableV5 } from 'mdbreact';
import { PlusSmIcon, TrashIcon } from '@heroicons/react/solid';
import Moment from 'moment';

const PollPage = () => {
  const [title, setTitle] = useState('');
  const [classId, setClassId] = useState('');
  const [selectedClassId, setSelectedClassId] = useState('');
  const [type, setType] = useState('');
  const [answer1, setAnswer1] = useState('');
  const [answer2, setAnswer2] = useState('');
  const [answer3, setAnswer3] = useState('');
  const [answer4, setAnswer4] = useState('');
  const [answer5, setAnswer5] = useState('');
  const [pollID, setPollID] = useState('');

  const [classes, setClasses] = useState([]);
  const [classesInput, setClassesInput] = useState([]);

  const [choiceNumber, setChoiceNumber] = useState(1);
  const [updateClicked, setUpdateClicked] = useState(false);

  const [dataReady, setDataReady] = useState(false);
  const [dataReadyAnswers, setDataReadyAnswers] = useState(false);
  const [cookies] = useCookies();

  const config = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
  };

  const [tempData, setTempData] = useState([]);
  const [tempDataAnswers, setTempDataAnswers] = useState([]);
  const [datatable, setDatatable] = useState({
    columns: [
      {
        label: 'Başlık',
        field: 'title',
      },
      {
        label: 'Sınıf',
        field: 'classId',
      },
      {
        label: 'Anket tipi',
        field: 'type',
      },
      {
        label: 'Cevap-1',
        field: 'answer1',
      },
      {
        label: 'Cevap-2',
        field: 'answer2',
      },
      {
        label: 'Cevap-3',
        field: 'answer3',
      },
      {
        label: 'Cevap-4',
        field: 'answer4',
      },
      {
        label: 'Cevap-5',
        field: 'answer5',
      },
      {
        label: 'Tarih',
        field: 'date',
        sort: 'asc',
      },
    ],
    rows: [],
  });
  const [datatableAnswers, setDatatableAnswers] = useState({
    columns: [
      {
        label: 'Fotoğraf',
        field: 'profilePhoto',
      },
      {
        label: 'Adı',
        field: 'name',
      },
      {
        label: 'Sınıf',
        field: 'classId',
      },
      {
        label: 'Anket Başlığı',
        field: 'pollTitle',
      },
      {
        label: 'Cevap',
        field: 'answer',
      },
      {
        label: 'Tarih',
        field: 'date',
      },
    ],
    rows: [],
  });
  const [checkbox, setCheckbox] = useState('');

  const deleteClickHandler = () => {
    if (checkbox !== '') {
      if (
        window.confirm(
          checkbox.title + ' konusunu silmek istedğinize emin misiniz?'
        )
      ) {
        fetch(`${Var.api_url}/poll/${checkbox.id}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${cookies.admin_token}`,
          },
        })
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            Toast.fire({
              icon: 'success',
              title: data.message,
            });
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else {
      Toast.fire({
        icon: 'error',
        title: 'Önce seçim yapınız!',
      });
    }
  };

  var Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 5000,
  });

  const addClickHandler = () => {
    if (
      (title !== '' &&
        classId !== '' &&
        type === 'choice' &&
        answer1 !== '' &&
        !(choiceNumber > 1 && answer2 === '') &&
        !(choiceNumber > 2 && answer3 === '') &&
        !(choiceNumber > 3 && answer4 === '') &&
        !(choiceNumber > 4 && answer5 === '')) ||
      (title !== '' && classId !== '' && type === 'input')
    ) {
      fetch(`${Var.api_url}/poll/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${cookies.admin_token}`,
        },
        body: JSON.stringify({
          title,
          classId,
          type,
          answer1,
          answer2,
          answer3,
          answer4,
          answer5,
        }),
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          Toast.fire({
            icon: 'success',
            title: data.message,
          });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch((err) => alert(err));
    } else {
      alert('Tüm boşlukları doğru bir şekilde doldurunuz');
    }
  };

  useEffect(async () => {
    await GetClasses();
    await GetPolls();
  }, []);

  const GetPolls = () => {
    fetch(`${Var.api_url}/poll/admin`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${cookies.admin_token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => setTempData(data))
      .then(() => setDataReady(true))
      .catch((err) => console.log(err));
  };

  const GetPollAnswers = () => {
    // Fix Here
    // fetch(`${Var.api_url}/poll/getAnswersForAdmin`, {
    //   method: 'GET',
    //   headers: {
    //     'Content-Type': 'application/json',
    //     Authorization: `Bearer ${cookies.admin_token}`,
    //   },
    // })
    //   .then((res) => {
    //     return res.json();
    //   })
    //   .then((data) => setTempDataAnswers(data.reverse()))
    //   .then(() => setDataReadyAnswers(true))
    //   .catch((err) => console.log(err));
  };

  const GetClasses = () => {
    fetch(`${Var.api_url}/class/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${cookies.admin_token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => setClasses(data))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (tempData.length !== 0) {
      let tempArray = [...tempData];
      tempData.map((data, index) => {
        tempArray[index].date = Moment(tempArray[index].date).format(
          'DD/MM/YYYY HH:mm:ss'
        );
      });
      setDatatable({ ...datatable, rows: tempArray });
      setDataReady(true);
    }
  }, [tempData]);

  useEffect(() => {
    if (classes.length !== 0) {
      GetPollAnswers();
    }
  }, [classes]);

  useEffect(() => {
    if (tempDataAnswers.length !== 0) {
      let tempArray = [...tempDataAnswers];
      tempDataAnswers.map((data, index) => {
        tempArray[index].profilePhoto = (
          <div className='w-10 h-10'>
            <img
              className='w-10 h-10 rounded-full'
              src={tempArray[index].user.profilePhoto}
              alt=''
            />
          </div>
        );
        tempArray[index].name = tempArray[index].user.name;
        tempArray[index].pollTitle = tempArray[index].poll.title;
        tempArray[index].classId = getClassByID(
          tempArray[index].user.classId
        )[0].title;
        tempArray[index].date = Moment(tempArray[index].date).format(
          'DD/MM/YYYY HH:mm:ss'
        );
      });
      setDatatableAnswers({ ...datatableAnswers, rows: tempArray });
      setDataReadyAnswers(true);
    }
  }, [tempDataAnswers]);

  const updatePoll = () => {
    if (
      title !== '' &&
      classId !== '' &&
      type !== '' &&
      answer1 !== '' &&
      !(choiceNumber > 1 && answer2 === '') &&
      !(choiceNumber > 2 && answer3 === '') &&
      !(choiceNumber > 3 && answer4 === '') &&
      !(choiceNumber > 4 && answer5 === '')
    ) {
      fetch(`${Var.api_url}/poll/${pollID}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${cookies.admin_token}`,
        },
        body: JSON.stringify({
          title,
          classId,
          type,
          answer1,
          answer2,
          answer3,
          answer4,
          answer5,
        }),
      })
        .then((res) => {
          if (!res.ok) {
            Toast.fire({
              icon: 'error',
              title: 'Bir hata oluştu',
            });
          }
          return res.json();
        })
        .then((data) => {
          Toast.fire({
            icon: 'success',
            title: data.message,
          });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        });
    } else {
      alert('Tüm boşlukları doğru bir şekilde doldurunuz');
    }
  };

  const updateClickHandler = () => {
    if (checkbox !== '') {
      setUpdateClicked(true);

      setTitle(checkbox.title);
      setClassId(checkbox.classId);
      setType(checkbox.type);
      setAnswer1(checkbox.answer1);
      setAnswer2(checkbox.answer2);
      setAnswer3(checkbox.answer3);
      setAnswer4(checkbox.answer4);
      setAnswer5(checkbox.answer5);

      setPollID(checkbox.id);
    } else {
      Toast.fire({
        icon: 'error',
        title: 'Önce seçim yapınız!',
      });
    }
  };

  const getClassByID = (id) => {
    return classes.filter(function (classes) {
      return classes.id == id;
    });
  };

  useEffect(() => {
    if (datatable.length !== 0) {
      fetch(`${Var.api_url}/class/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${cookies.admin_token}`,
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          setClassesCLickHandler(data);
          setClasses(data);
        })
        .catch((err) => console.log(err));
    }
  }, [datatable]);

  const setClassesCLickHandler = (datas) => {
    let tempClasses = [];
    datas.map((data, i) => {
      tempClasses.push({
        value: data.id,
        label: data.title,
      });
    });
    setClassesInput(tempClasses);
  };

  const addClassId = () => {
    if (selectedClassId !== '') {
      if (
        !classId.toString().split(',').includes(selectedClassId.toString()) &&
        classId !== ''
      ) {
        setClassId(classId + ',' + selectedClassId);
      } else if (classId.toString() === '') {
        setClassId(selectedClassId);
      } else {
        Toast.fire({
          icon: 'warning',
          title: 'Bu sınıf zaten kayıtlı!',
        });
      }
    } else {
      Toast.fire({
        icon: 'warning',
        title: 'Lütfen seçim yapınız!',
      });
    }
  };

  const removeClassId = () => {
    if (selectedClassId !== '') {
      if (classId.toString().split(',').includes(selectedClassId.toString())) {
        let tempClassIdArray = classId.split(',');
        for (let i = 0; i < tempClassIdArray.length; i++)
          if (tempClassIdArray[i] === selectedClassId.toString())
            tempClassIdArray.splice(i, 1);
        setClassId(tempClassIdArray.join(','));
      } else {
        Toast.fire({
          icon: 'warning',
          title: 'Bu sınıf zaten kayıtlı değil!',
        });
      }
    } else {
      Toast.fire({
        icon: 'warning',
        title: 'Lütfen seçim yapınız!',
      });
    }
  };

  return (
    <div>
      <div className='card card-info'>
        <div className='card-header'>
          <h3 className='card-title'>Anket Ekleme</h3>
        </div>
        {/* /.card-header */}
        {/* form start */}
        <div className='card-body row'>
          <form className='col-md-12'>
            <div className='card'>
              <div className='card-header row'>
                <div>
                  Anket listesinde gözükmesini istediklerinizi aşağıya
                  ekleyebilirsiniz.
                </div>
              </div>
              {/* /.card-header */}
              <div className='card-body'>
                <label>
                  Başlık <label className='text-danger'>*</label>
                </label>
                <input
                  className='form-control'
                  placeholder='Başlık...'
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
                <br />
                <label>
                  Sınıfları <label className='text-danger'>*</label>
                </label>
                <div className='flex flex-row mb-4'>
                  {classId !== '' &&
                    classId.toString().includes(',') &&
                    classId.split(',').map((id, i) => {
                      if (id !== '')
                        return (
                          <p className='border rounded-xl p-2 mr-2' key={i}>
                            {getClassByID(id)[0].title}
                          </p>
                        );
                    })}
                  {classId !== '' && !classId.toString().includes(',') && (
                    <p className='border rounded-xl p-2 mr-2'>
                      {getClassByID(classId)[0].title}
                    </p>
                  )}
                </div>
                <div className='flex flex-row'>
                  <Select
                    className='w-60 mr-2'
                    onChange={(e) => setSelectedClassId(e.value)}
                    options={classesInput}
                    placeholder={'Sınıf'}
                  />
                  <PlusSmIcon
                    className='w-7 cursor-pointer'
                    onClick={addClassId}
                  />
                  <div className='border-r mx-2' />
                  <TrashIcon
                    className='w-7 cursor-pointer'
                    onClick={removeClassId}
                  />
                </div>
                <br />
                <label>
                  Anket Tipi <label className='text-danger'>*</label>
                </label>
                <Select
                  className='w-1/3'
                  defaultValue={type}
                  onChange={(e) => setType(e.value)}
                  options={Var.pollType}
                  placeholder={'Anket Tipi'}
                />
                <br />
                {type === 'choice' && (
                  <>
                    <div className='d-flex flex-row align-items-center mb-2'>
                      <label className='col-md-2' htmlFor='input-answer1'>
                        Doğru Cevap
                      </label>
                      <input
                        id='input-answer1'
                        className='form-control'
                        placeholder='Cevap...'
                        value={answer1}
                        onChange={(e) => setAnswer1(e.target.value)}
                      />
                    </div>
                    {choiceNumber > 1 && (
                      <div className='d-flex flex-row align-items-center mb-2'>
                        <label className='col-md-2' htmlFor='input-answer2'>
                          Cevap - 2
                        </label>
                        <input
                          id='input-answer2'
                          className='form-control'
                          placeholder='Cevap...'
                          value={answer2}
                          onChange={(e) => setAnswer2(e.target.value)}
                        />
                      </div>
                    )}
                    {choiceNumber > 2 && (
                      <div className='d-flex flex-row align-items-center mb-2'>
                        <label className='col-md-2' htmlFor='input-answer3'>
                          Cevap - 3
                        </label>
                        <input
                          id='input-answer3'
                          className='form-control'
                          placeholder='Cevap...'
                          value={answer3}
                          onChange={(e) => setAnswer3(e.target.value)}
                        />
                      </div>
                    )}
                    {choiceNumber > 3 && (
                      <div className='d-flex flex-row align-items-center mb-2'>
                        <label className='col-md-2' htmlFor='input-answer4'>
                          Cevap - 4
                        </label>
                        <input
                          id='input-answer4'
                          className='form-control'
                          placeholder='Cevap...'
                          value={answer4}
                          onChange={(e) => setAnswer4(e.target.value)}
                        />
                      </div>
                    )}
                    {choiceNumber > 4 && (
                      <div className='d-flex flex-row align-items-center mb-2'>
                        <label className='col-md-2' htmlFor='input-answer5'>
                          Cevap - 5
                        </label>
                        <input
                          id='input-answer5'
                          className='form-control'
                          placeholder='Cevap...'
                          value={answer5}
                          onChange={(e) => setAnswer5(e.target.value)}
                        />
                      </div>
                    )}
                    <PlusSmIcon
                      className='ml-auto w-8 h-8 cursor-pointer'
                      onClick={() => setChoiceNumber(choiceNumber + 1)}
                    />
                  </>
                )}

                <br />
                <button
                  type='button'
                  className='btn bg-green-700 hover:bg-green-800 text-white btn-block col-md-2 ml-0'
                  onClick={() => {
                    updateClicked ? updatePoll() : addClickHandler();
                  }}
                >
                  {updateClicked ? 'Güncelle' : 'Ekle'}
                </button>
              </div>
              {/* /.card-body */}
            </div>
            {/* /.card */}
          </form>
        </div>
      </div>

      {/* Anket Listesi */}
      <div className='card card-info'>
        <div className='card-header'>
          <h3 className='card-title'>Anket Listesi</h3>
        </div>
        {/* /.card-header */}
        {/* form start */}
        <div className='card-body row'>
          <form className='col-md-12'>
            <div className='card'>
              <div className='card-header row'>
                <div>
                  Anket listesindeki detayları burada düzenleyebilirsiniz.
                </div>
                <div className='col-md-4 ml-auto flex flex-wrap items-center justify-end'>
                  {!updateClicked && (
                    <button
                      type='button'
                      className='btn bg-green-700 hover:bg-green-800 text-white w-40 h-10 m-1'
                      onClick={() => updateClickHandler()}
                    >
                      Güncelle
                    </button>
                  )}
                  {updateClicked && (
                    <button
                      type='button'
                      className='btn bg-yellow-500 hover:bg-yellow-600 text-white w-40 h-10 m-1'
                      onClick={() => setUpdateClicked(false)}
                    >
                      Vazgeç
                    </button>
                  )}
                  <button
                    type='button'
                    className='btn bg-red-700 hover:bg-red-800 text-white w-40 h-10 m-1'
                    onClick={() => deleteClickHandler()}
                  >
                    Sil
                  </button>
                </div>
              </div>
              {/* /.card-header */}
              <div className='card-body'>
                {dataReady && (
                  <MDBDataTableV5
                    hover
                    entriesOptions={[5, 20, 25]}
                    entries={5}
                    pagesAmount={4}
                    data={datatable}
                    // scrollX
                    checkbox='true'
                    searchTop
                    searchBottom={false}
                    headCheckboxID='id1'
                    bodyCheckboxID='checkboxes1'
                    getValueCheckBox={(e) => {
                      checkbox === ''
                        ? setCheckbox(e)
                        : checkbox.id === e.id
                        ? setCheckbox('')
                        : setCheckbox(e);
                    }}
                  />
                )}
              </div>
              {/* /.card-body */}
            </div>
            {/* /.card */}
          </form>
        </div>
      </div>

      {/* Anket Cevapları Listesi */}
      <div className='card card-info'>
        <div className='card-header'>
          <h3 className='card-title'>Anket Cevapları Listesi</h3>
        </div>
        {/* /.card-header */}
        {/* form start */}
        <div className='card-body row'>
          <form className='col-md-12'>
            <div className='card'>
              <div className='card-header row'>
                <div>
                  Anket cevapları listesindeki detayları burada
                  düzenleyebilirsiniz.
                </div>
              </div>
              {/* /.card-header */}
              <div className='card-body'>
                {dataReadyAnswers && (
                  <MDBDataTableV5
                    hover
                    entriesOptions={[5, 20, 25]}
                    entries={5}
                    pagesAmount={4}
                    data={datatableAnswers}
                    // scrollX
                    searchTop
                    searchBottom={false}
                    headCheckboxID='id2'
                    bodyCheckboxID='checkboxes2'
                    getValueCheckBox={(e) => {
                      checkbox === ''
                        ? setCheckbox(e)
                        : checkbox.id === e.id
                        ? setCheckbox('')
                        : setCheckbox(e);
                    }}
                  />
                )}
              </div>
              {/* /.card-body */}
            </div>
            {/* /.card */}
          </form>
        </div>
      </div>
    </div>
  );
};

export default PollPage;
