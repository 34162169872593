
const DashboardPage = () => {
  return (
    <div>
      <div className="card card-info">
        <div className="card-header">
          <h3 className="card-title">Dashboard</h3>
        </div>
        {/* /.card-header */}
        {/* form start */}
        <div className="card-body row">
          <form className="col-md-12">
            <div className="card">
              <div className="card-header row">
                <div>Çok yakında...</div>
              </div>
              {/* /.card-header */}
              <div className="card-body"></div>
              {/* /.card-body */}
            </div>
            {/* /.card */}
          </form>
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;
