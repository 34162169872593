const Footer = () => {
  return (
    <div>
      <footer className="main-footer">
        <div className="float-right d-none d-sm-inline">Anything you want</div>
        <strong>
          Copyright © 2014-2021 <a href="https://adminlte.io">AdminLTE.io</a>.
        </strong>{" "}
        All rights reserved.
      </footer>
    </div>
  );
};

export default Footer;
