import { useState, useEffect, useRef } from "react";
import Swal from "sweetalert2";
import Var from "./variables";
import { useCookies } from "react-cookie";
import { MDBDataTableV5 } from "mdbreact";
import Select from "react-select";
import Moment from "moment";
import Parser from "html-react-parser";

const AskPage = () => {
  const [title, setTitle] = useState("");
  const [question, setQuestion] = useState("");
  const [level, setLevel] = useState("");
  const [week, setWeek] = useState("");
  const [unit, setUnit] = useState("");
  const [answer, setAnswer] = useState("");
  const [questionID, setQuestionID] = useState(null);

  const [dataReady, setDataReady] = useState(false);
  const [cookies] = useCookies();

  const [isQna, setIsQna] = useState(false);
  const inputPinned = useRef(null);
  const [pinnedQuestion, setPinnedQuestion] = useState(false);
  const [isPrivate, setIsPrivate] = useState();

  const [tempData, setTempData] = useState([]);
  const [datatable, setDatatable] = useState({
    columns: [
      {
        label: "Fotoğraf",
        field: "profilePhoto",
      },
      {
        label: "Adı",
        field: "username",
      },
      {
        label: "Sınıf",
        field: "classId",
      },
      {
        label: "Başlık",
        field: "title",
      },
      {
        label: "Soru",
        field: "question",
      },
      {
        label: "Cevap",
        field: "answer",
      },
      {
        label: "Birim",
        field: "unit",
      },
      {
        label: "Hafta",
        field: "week",
      },
      {
        label: "Seviye",
        field: "level",
      },
      {
        label: "Tarih",
        field: "date",
        sort: "asc",
      },
    ],
    rows: [],
  });
  const [checkbox, setCheckbox] = useState("");

  const deleteClickHandler = () => {
    if (checkbox !== "") {
      if (
        window.confirm(
          checkbox.title + " sorusunu silmek istedğinize emin misiniz?"
        )
      ) {
        fetch(`${Var.api_url}/question/${checkbox.id}`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookies.admin_token}`,
          },
        })
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            Toast.fire({
              icon: "success",
              title: data.message,
            });
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else {
      Toast.fire({
        icon: "error",
        title: "Önce seçim yapınız!",
      });
    }
  };

  const deleteAnswerClickHandler = () => {
    if (checkbox !== "") {
      if (
        window.confirm(
          checkbox.answer + " cevabını silmek istedğinize emin misiniz?"
        )
      ) {
        fetch(`${Var.api_url}/question/answer/${checkbox.id}`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookies.admin_token}`,
          },
        })
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            Toast.fire({
              icon: "success",
              title: data.message,
            });
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else {
      Toast.fire({
        icon: "error",
        title: "Önce seçim yapınız!",
      });
    }
  };

  var Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 5000,
  });

  useEffect(() => {
    fetch(`${Var.api_url}/question/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookies.admin_token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => setTempData(data))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (tempData.length !== 0) {
      let tempArray = [...tempData];
      tempData.map((data, index) => {
        tempArray[index].username = tempArray[index].user.name;
        tempArray[index].classId = tempArray[index].user.class.title;
        tempArray[index].title = Parser(tempArray[index].title);
        tempArray[index].date = Moment(tempArray[index].date).format(
          "DD/MM/YYYY HH:mm:ss"
        );

        tempArray[index].profilePhoto = (
          <div className="w-10 h-10">
            <img
              className="w-10 h-10 rounded-full"
              src={tempArray[index].user.profilePhoto}
              alt=""
            />
          </div>
        );
      });
      setDatatable({ ...datatable, rows: tempArray });
      setDataReady(true);
    }
  }, [tempData]);

  const updateClickHandler = () => {
    if (checkbox !== "") {
      setTitle(checkbox.title);
      setQuestion(checkbox.question);
      setLevel(checkbox.level);
      setWeek(checkbox.week);
      setUnit(checkbox.unit);
      setAnswer(checkbox.answer);
      setIsQna(checkbox.unit === "Q&A" ? true : false);
      setIsPrivate(checkbox.isPrivate === 1 ? true : false);
      setPinnedQuestion(checkbox.isPinned === 1 ? true : false);

      setQuestionID(checkbox.id);
    } else {
      Toast.fire({
        icon: "error",
        title: "Önce seçim yapınız!",
      });
    }
  };

  const updateAnswer = () => {
    if (answer !== "") {
      fetch(`${Var.api_url}/question/answer/${questionID}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${cookies.admin_token}`,
        },
        body: JSON.stringify({ answer }),
      })
        .then((res) => {
          if (!res.ok) {
            Toast.fire({
              icon: "error",
              title: "Bir hata oluştu",
            });
          }
          return res.json();
        })
        .then((data) => {
          PinnedQuestionClickHandler();
        });
    } else {
      alert("Tüm boşlukları doğru bir şekilde doldurunuz");
    }
  };

  const PinnedQuestionClickHandler = () => {
    fetch(`${Var.api_url}/question/pinned/${questionID}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookies.admin_token}`,
      },
      body: JSON.stringify({ isPinned: pinnedQuestion ? 1 : 0 }),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        Toast.fire({
          icon: "success",
          title: data.message,
        });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((err) => alert(err));
  };

  useEffect(() => {
    if (questionID !== null) {
      inputPinned.current.checked = pinnedQuestion;
    }
  }, [pinnedQuestion]);

  return (
    <div>
      <div className="card card-info">
        <div className="card-header">
          <h3 className="card-title">Cevap Güncelleme</h3>
        </div>
        {/* /.card-header */}
        {/* form start */}
        <div className="card-body row">
          <form className="col-md-12">
            <div className="card">
              <div className="card-header row">
                <div>
                  Üyenin görmesini istediğiniz mesajı aşağıda
                  güncelleyebilirsiniz.
                </div>
              </div>
              {/* /.card-header */}

              {questionID !== null && (
                <div className="card-body">
                  <label>Başlık </label>
                  <input className="form-control" value={title} readOnly />
                  <br />
                  <label>Soru</label>
                  <textarea
                    className="form-control"
                    rows={5}
                    value={question}
                    readOnly
                  />
                  <br />
                  <div className="col-md-12 d-flex flex-row mb-5 justify-content-around">
                    <div className="d-flex flex-row align-items-center">
                      <label className="mr-2" type="text">
                        Seviye
                      </label>
                      <Select placeholder={level} isDisabled />
                    </div>
                    <div className="d-flex flex-row align-items-center">
                      <label className="mr-2" type="text">
                        Hafta
                      </label>
                      <Select placeholder={week} isDisabled />
                    </div>
                    <div className="d-flex flex-row align-items-center">
                      <label className="mr-2" type="text">
                        Birim
                      </label>
                      <Select placeholder={unit} isDisabled />
                    </div>
                    <br />
                  </div>
                  <label>
                    Cevabınız <label className="text-danger">*</label>
                  </label>
                  <textarea
                    className="form-control"
                    rows={5}
                    placeholder="Cevabınız..."
                    value={answer || ""}
                    onChange={(e) => setAnswer(e.target.value)}
                  />
                  <br />

                  {isQna && (
                    <div
                      className={
                        "flex flex-row items-center space-x-2 mb-4 max-w-fit " +
                        (isPrivate ? " pointer-events-none " : "cursor-pointer")
                      }
                      onClick={() =>
                        setPinnedQuestion(!inputPinned.current.checked)
                      }
                    >
                      <input
                        className="cursor-pointer"
                        name="pinnedQuestion"
                        type="checkbox"
                        ref={inputPinned}
                        defaultChecked={pinnedQuestion}
                        onChange={(e) => {
                          setPinnedQuestion(e.target.checked);
                        }}
                      />
                      <p>Soruyu Pinle</p>
                      {isPrivate && (
                        <p className="text-sm text-red-500">
                          (Bu soru gizli sorulmuştur. Pinleyemezsiniz!)
                        </p>
                      )}
                    </div>
                  )}

                  <button
                    type="button"
                    className="btn bg-green-700 hover:bg-green-800 text-white btn-block col-md-2 ml-0"
                    onClick={() => updateAnswer()}
                  >
                    Güncelle
                  </button>
                </div>
              )}
              {/* /.card-body */}
            </div>
            {/* /.card */}
          </form>
        </div>
      </div>

      {/* Soru Listesi */}
      <div className="card card-info">
        <div className="card-header">
          <h3 className="card-title">Soru Listesi</h3>
        </div>
        {/* /.card-header */}
        {/* form start */}
        <div className="card-body row">
          <form className="col-md-12">
            <div className="card">
              <div className="card-header row">
                <div className="col-md-8">
                  Soru listesindeki detayları burada düzenleyebilirsiniz.
                </div>
                <div className="col-md-4 ml-auto flex flex-wrap items-center justify-end">
                  <button
                    type="button"
                    className="btn bg-green-700 hover:bg-green-800 text-white w-40 h-10 m-1"
                    onClick={() => updateClickHandler()}
                  >
                    Cevabı Güncelle
                  </button>
                  <button
                    type="button"
                    className="btn bg-blue-700 hover:bg-blue-800 text-white w-40 h-10 m-1"
                    onClick={() => deleteAnswerClickHandler()}
                  >
                    Cevabı Sil
                  </button>
                  <button
                    type="button"
                    className="btn bg-red-700 hover:bg-red-800 text-white w-40 h-10 m-1"
                    onClick={() => deleteClickHandler()}
                  >
                    Soruyu Sil
                  </button>
                </div>
              </div>
              {/* /.card-header */}
              <div className="card-body">
                {dataReady && (
                  <MDBDataTableV5
                    hover
                    entriesOptions={[5, 20, 25]}
                    entries={5}
                    pagesAmount={4}
                    data={datatable}
                    // scrollX
                    checkbox="true"
                    searchTop
                    searchBottom={false}
                    headCheckboxID="id2"
                    bodyCheckboxID="checkboxes2"
                    getValueCheckBox={(e) => {
                      checkbox === ""
                        ? setCheckbox(e)
                        : checkbox.id === e.id
                        ? setCheckbox("")
                        : setCheckbox(e);
                    }}
                  />
                )}
              </div>
              {/* /.card-body */}
            </div>
            {/* /.card */}
          </form>
        </div>
      </div>
    </div>
  );
};

export default AskPage;
