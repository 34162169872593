import { useState, useEffect } from "react";
import Swal from "sweetalert2";
import Var from "./variables";

import { useCookies } from "react-cookie";
import { MDBDataTableV5 } from "mdbreact";
import Select from "react-select";
import JoditEditor from "jodit-react";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/solid";
import Moment from "moment";

const ClassesPage = () => {
  const [title, setTitle] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [classID, setClassID] = useState("");
  const [newClassId, setNewClassId] = useState("");
  const [memberIDs, setMemberIDs] = useState([]);

  const [updateClicked, setUpdateClicked] = useState(false);

  const [classes, setClasses] = useState([]);
  const [selectClasses, setSelectClasses] = useState([]);
  const [tempDataClasses, setTempDataClasses] = useState([]);
  const [tempData, setTempData] = useState([]);
  const [dataReady, setDataReady] = useState(false);
  const [membersReady, setMembersReady] = useState(false);
  const [classesReady, setClassesReady] = useState(false);

  const [cookies] = useCookies();

  const config = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
  };

  const [datatable, setDatatable] = useState({
    columns: [
      {
        label: "ID",
        field: "id",
        sort: "asc",
      },
      {
        label: "Başlık",
        field: "title",
      },
      {
        label: "Üye",
        field: "count",
      },
      {
        label: "Başlangıç Tarihi",
        field: "startDate",
      },
      {
        label: "Bitiş Tarihi",
        field: "endDate",
      },
    ],
    rows: [],
  });
  const [checkbox, setCheckbox] = useState("");

  const [membersDatatable, setMembersDatatable] = useState({
    columns: [
      {
        label: "Profil",
        field: "profilePhoto",
      },
      {
        label: "No",
        field: "number",
      },
      {
        label: "Ad",
        field: "name",
        sort: "asc",
      },
      {
        label: "Soyad",
        field: "surname",
      },
      {
        label: "Sınıf",
        field: "classId",
      },
      {
        label: "Tel",
        field: "phoneNumber",
      },
      {
        label: "Email",
        field: "email",
      },
      {
        label: "Referans",
        field: "reference",
      },
    ],
    rows: [],
  });
  const [membersCheckbox, setMembersCheckbox] = useState([]);

  const deleteClickHandler = () => {
    if (checkbox !== "") {
      if (
        window.confirm(
          checkbox.title + " sınıfını silmek istedğinize emin misiniz?"
        )
      ) {
        if (checkbox.id !== 6 && checkbox.id !== 8 && checkbox.id !== 9) {
          fetch(`${Var.api_url}/class/${checkbox.id}`, {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${cookies.admin_token}`,
            },
          })
            .then((res) => {
              return res.json();
            })
            .then((data) => {
              Toast.fire({
                icon: "success",
                title: data.message,
              });
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          Toast.fire({
            icon: "error",
            title: "Bu sınıfı silemezsiniz!",
          });
        }
      }
    } else {
      Toast.fire({
        icon: "error",
        title: "Önce seçim yapınız!",
      });
    }
  };

  var Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 5000,
  });

  const addClickHandler = () => {
    if (title !== "" && startDate !== "" && endDate !== "") {
      fetch(`${Var.api_url}/class/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${cookies.admin_token}`,
        },
        body: JSON.stringify({ title, startDate, endDate }),
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          Toast.fire({
            icon: "success",
            title: data.message,
          });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch((err) => console.log(err));
    } else {
      alert("Tüm boşlukları doğru bir şekilde doldurunuz");
    }
  };

  useEffect(() => {
    fetch(`${Var.api_url}/class/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookies.admin_token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setClasses(data);
        setTempDataClasses(data);
        setClassesCLickHandler(data);
      })
      .then(() => setDataReady(true))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    console.log(tempDataClasses);
    if (tempDataClasses.length !== 0) {
      let tempArray = [...tempDataClasses];
      tempDataClasses.map((data, index) => {
        tempArray[index].startDate = Moment(tempArray[index].startDate).format(
          "DD/MM/YYYY"
        );
        tempArray[index].endDate = Moment(tempArray[index].endDate).format(
          "DD/MM/YYYY"
        );
        console.log(tempArray[index].count);
      });
      setDatatable({ ...datatable, rows: tempArray });
      setDataReady(true);
    }
    console.log(datatable);
  }, [tempDataClasses]);

  useEffect(() => {
    if (dataReady) {
      fetch(`${Var.api_url}/user/users`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${cookies.admin_token}`,
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => setTempData(data))
        .catch((err) => console.log(err));
    }
  }, [dataReady]);

  useEffect(() => {
    if (tempData.length !== 0) {
      let tempArray = [...tempData];
      tempData.map((data, index) => {
        tempArray[index].profilePhoto = (
          <div className="w-10 h-10">
            <img
              className="w-10 h-10 rounded-full"
              src={tempArray[index].profilePhoto}
              alt=""
            />
          </div>
        );
        tempArray[index].classId = getClassByID(tempArray[index].classId);
        tempArray[index].isAccess = tempArray[index].isAccess ? (
          <CheckCircleIcon className="fill-green-500 w-7" />
        ) : (
          <XCircleIcon className="fill-red-500 w-7" />
        );
        tempArray[index].isPaid = tempArray[index].isPaid ? (
          <CheckCircleIcon className="fill-green-500 w-7" />
        ) : (
          <XCircleIcon className="fill-red-500 w-7" />
        );
      });
      setMembersDatatable({ ...membersDatatable, rows: tempArray });
      setMembersReady(true);
    }
  }, [tempData]);

  const getClassByID = (id) => {
    let string = "";
    classes.filter((c) => {
      if (c.id == id) {
        string = c.title;
      }
    });
    return string;
  };

  useEffect(() => {
    if (selectClasses.length !== 0) {
      setClassesReady(true);
    }
  }, [selectClasses]);

  const setClassesCLickHandler = (datas) => {
    let tempClasses = [];
    datas.map((data, i) => {
      tempClasses.push({
        value: data.id,
        label: data.title,
      });
    });
    setSelectClasses(tempClasses);
  };

  const updateClass = () => {
    if (title !== "" && startDate !== "" && endDate !== "") {
      fetch(`${Var.api_url}/class/${classID}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${cookies.admin_token}`,
        },
        body: JSON.stringify({ title, startDate, endDate }),
      })
        .then((res) => {
          if (!res.ok) {
            Toast.fire({
              icon: "error",
              title: "Bir hata oluştu",
            });
          }
          return res.json();
        })
        .then((data) => {
          Toast.fire({
            icon: "success",
            title: data.message,
          });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        });
    } else {
      alert("Tüm boşlukları doğru bir şekilde doldurunuz");
    }
  };

  const updateClickHandler = () => {
    if (checkbox !== "") {
      setUpdateClicked(true);

      setTitle(checkbox.title);
      setStartDate(checkbox.startDate);
      setEndDate(checkbox.endDate);
      setClassID(checkbox.id);
    } else {
      Toast.fire({
        icon: "error",
        title: "Önce seçim yapınız!",
      });
    }
  };

  const updateClassesClickHandler = () => {
    if (membersCheckbox.length !== 0) {
      if (newClassId !== "") {
        fetch(`${Var.api_url}/user/classess`, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookies.admin_token}`,
          },
          body: JSON.stringify({ users: membersCheckbox, classId: newClassId }),
        })
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            Toast.fire({
              icon: "success",
              title: data.message + "\n" + data.length + " kişi güncellendi",
            });
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          })
          .catch((err) => console.log(err));
      } else {
        Toast.fire({
          icon: "error",
          title: "Lütfen bir sınıf seçiniz!",
        });
      }
    } else {
      Toast.fire({
        icon: "error",
        title: "Öncelikle güncellenecek üyeleri seçiniz!",
      });
    }
  };

  const memberCheckboxClickHandler = (e) => {
    if (e.checked) {
      setMembersCheckbox([...membersCheckbox, e.id]);
    } else {
      let tempMembersCheckbox = [...membersCheckbox];
      tempMembersCheckbox.map((data, i) => {
        if (data === e.id) {
          tempMembersCheckbox.splice(i, 1);
          setMembersCheckbox(tempMembersCheckbox);
        }
      });
    }
  };

  const memberCheckboxAllClickHandler = (e) => {
    if (e[0].checked) {
      let tempMembersCheckbox = [];
      e.map((user) => {
        tempMembersCheckbox.push(user.id);
      });
      setMembersCheckbox([...tempMembersCheckbox]);
    } else {
      setMembersCheckbox([]);
    }
  };

  return (
    <div>
      <div className="card card-info">
        <div className="card-header">
          <h3 className="card-title">Sınıf Ekleme</h3>
        </div>
        {/* /.card-header */}
        {/* form start */}
        <div className="card-body row">
          <form className="col-md-12">
            <div className="card">
              <div className="card-header row">
                <div>
                  Sınıf listesinde gözükmesini istediklerinizi aşağıya
                  ekleyebilirsiniz.
                </div>
              </div>
              {/* /.card-header */}
              <div className="card-body">
                <label>
                  Başlık <label className="text-danger">*</label>
                </label>
                <input
                  className="form-control"
                  placeholder="Başlık..."
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
                <br />
                <label>
                  Başlangıç Tarihi <label className="text-danger">*</label>
                </label>
                <input
                  className="form-control w-60"
                  id="login-birthday"
                  type="date"
                  value={startDate}
                  onChange={(e) => {
                    setStartDate(e.target.value);
                  }}
                />
                <br />
                <label>
                  Bitiş Tarihi <label className="text-danger">*</label>
                </label>
                <input
                  className="form-control w-60"
                  id="login-birthday"
                  type="date"
                  value={endDate}
                  onChange={(e) => {
                    setEndDate(e.target.value);
                  }}
                />
                <br />
                <button
                  type="button"
                  className="btn bg-green-700 hover:bg-green-800 text-white btn-block col-md-2 ml-0"
                  onClick={() => {
                    updateClicked ? updateClass() : addClickHandler();
                  }}
                >
                  {updateClicked ? "Güncelle" : "Ekle"}
                </button>
              </div>
              {/* /.card-body */}
            </div>
            {/* /.card */}
          </form>
        </div>
      </div>

      {/* Sınıf Listesi */}
      <div className="card card-info">
        <div className="card-header">
          <h3 className="card-title">Sınıf Listesi</h3>
        </div>
        {/* /.card-header */}
        {/* form start */}
        <div className="card-body row">
          <form className="col-md-12">
            <div className="card">
              <div className="card-header row">
                <div>
                  Sınıf listesindeki detayları burada düzenleyebilirsiniz.
                </div>
                <div className="col-md-4 ml-auto flex flex-wrap items-center justify-end">
                  {!updateClicked && (
                    <button
                      type="button"
                      className="btn bg-green-700 hover:bg-green-800 text-white w-40 h-10 m-1"
                      onClick={() => updateClickHandler()}
                    >
                      Güncelle
                    </button>
                  )}
                  {updateClicked && (
                    <button
                      type="button"
                      className="btn bg-yellow-500 hover:bg-yellow-600 text-white w-40 h-10 m-1"
                      onClick={() => setUpdateClicked(false)}
                    >
                      Vazgeç
                    </button>
                  )}
                  <button
                    type="button"
                    className="btn bg-red-700 hover:bg-red-800 text-white w-40 h-10 m-1"
                    onClick={() => deleteClickHandler()}
                  >
                    Sil
                  </button>
                </div>
              </div>
              {/* /.card-header */}
              <div className="card-body">
                {dataReady && (
                  <MDBDataTableV5
                    hover
                    entriesOptions={[5, 10]}
                    entries={5}
                    pagesAmount={4}
                    data={datatable}
                    // scrollX
                    checkbox="true"
                    searchTop
                    searchBottom={false}
                    headCheckboxID="id1"
                    bodyCheckboxID="checkboxes1"
                    getValueCheckBox={(e) => {
                      checkbox === ""
                        ? setCheckbox(e)
                        : checkbox.id === e.id
                        ? setCheckbox("")
                        : setCheckbox(e);
                    }}
                  />
                )}
              </div>
              {/* /.card-body */}
            </div>
            {/* /.card */}
          </form>
        </div>
      </div>

      {/* Sınıf Atama */}
      <div className="card card-info">
        <div className="card-header">
          <h3 className="card-title">Sınıf Atama</h3>
        </div>
        {/* /.card-header */}
        {/* form start */}
        <div className="card-body row">
          <form className="col-md-12">
            <div className="card">
              <div className="card-header row">
                <div>
                  Öğrencilere toplu sınıf atama işlemini burada
                  düzenleyebilirsiniz.
                </div>
                <div className="col-md-6 ml-auto flex flex-wrap !items-center justify-end">
                  <p className="mr-2">{"Seçili: " + membersCheckbox.length}</p>

                  {classesReady && (
                    <Select
                      className="w-1/3"
                      defaultValue={newClassId}
                      onChange={(e) => setNewClassId(e.value)}
                      options={selectClasses}
                      placeholder={"Sınıf"}
                    />
                  )}
                  <button
                    type="button"
                    className="btn bg-green-700 hover:bg-green-800 text-white w-40 h-10 m-1"
                    onClick={() => updateClassesClickHandler()}
                  >
                    Güncelle
                  </button>
                </div>
              </div>
              {/* /.card-header */}
              <div className="card-body">
                {membersReady && (
                  <MDBDataTableV5
                    hover
                    sortable
                    entriesOptions={[25, 50, 100]}
                    entries={25}
                    pagesAmount={4}
                    data={membersDatatable}
                    // scrollX
                    checkbox="true"
                    searchTop
                    searchBottom={false}
                    headCheckboxID="id2"
                    bodyCheckboxID="checkboxes2"
                    getValueCheckBox={(e) => {
                      memberCheckboxClickHandler(e);
                    }}
                    getValueAllCheckBoxes={(e) => {
                      memberCheckboxAllClickHandler(e);
                    }}
                    multipleCheckboxes
                  />
                )}
              </div>
              {/* /.card-body */}
            </div>
            {/* /.card */}
          </form>
        </div>
      </div>
    </div>
  );
};

export default ClassesPage;
