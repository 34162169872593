import React, { useState, useEffect, useRef } from "react";
import Swal from "sweetalert2";
import Var from "./variables";
import { useCookies } from "react-cookie";
import { MDBDataTableV5 } from "mdbreact";
import Select from "react-select";
import Phonetic from "./Phonetic";
import Moment from "moment";
import {
  PlusSmIcon,
  PlayIcon,
  PauseIcon,
  SpeakerphoneIcon,
  CloudUploadIcon,
} from "@heroicons/react/solid";

const QuestionBank = () => {
  // "Hafta", "Seviye", "Soru Tipi"
  const [level, setLevel] = useState("1");
  const [week, setWeek] = useState("1");
  const [getAdminLevel, setGetAdminLevel] = useState("1");
  const [getAdminWeek, setGetAdminWeek] = useState("1");
  const [type, setType] = useState("");
  const [testID, setTestID] = useState("");
  const [values, setValues] = useState(null);

  const [updateClicked, setUpdateClicked] = useState(false);

  // For "Öğe Sıralama"
  const [title1, setTitle1] = useState("");
  const [question1, setQuestion1] = useState("");
  const [answer1, setAnswer1] = useState("");
  const audio1 = useRef(null);
  const [voice1, setVoice1] = useState(null);

  const [title2, setTitle2] = useState("");
  const [question2, setQuestion2] = useState("");
  const [answer2, setAnswer2] = useState("");
  const audio2 = useRef(null);
  const [voice2, setVoice2] = useState(null);

  const [title3, setTitle3] = useState("");
  const [question3, setQuestion3] = useState("");
  const [answer3, setAnswer3] = useState("");
  const audio3 = useRef(null);
  const [voice3, setVoice3] = useState(null);

  // For "Seçenekli Sorular"
  const [title4, setTitle4] = useState("");
  const [question4, setQuestion4] = useState("");
  const [answer41, setAnswer41] = useState("");
  const [answer42, setAnswer42] = useState("");
  const [answer43, setAnswer43] = useState("");
  const [answer44, setAnswer44] = useState("");
  const [answer45, setAnswer45] = useState("");

  const [title5, setTitle5] = useState("");
  const [question5, setQuestion5] = useState("");
  const [answer51, setAnswer51] = useState("");
  const [answer52, setAnswer52] = useState("");
  const [answer53, setAnswer53] = useState("");
  const [answer54, setAnswer54] = useState("");
  const [answer55, setAnswer55] = useState("");

  const [title6, setTitle6] = useState("");
  const [question6, setQuestion6] = useState("");
  const [answer61, setAnswer61] = useState("");
  const [answer62, setAnswer62] = useState("");
  const [answer63, setAnswer63] = useState("");
  const [answer64, setAnswer64] = useState("");
  const [answer65, setAnswer65] = useState("");

  // For "Seçenekli Sorular"s choice number
  const [choiceNumber1, setChoiceNumber1] = useState(1);
  const [choiceNumber2, setChoiceNumber2] = useState(1);
  const [choiceNumber3, setChoiceNumber3] = useState(1);

  // For "Boşluk Doldur"
  const [title7, setTitle7] = useState("");
  const [question7, setQuestion7] = useState("");
  const [answer7, setAnswer7] = useState("");

  const [title8, setTitle8] = useState("");
  const [question8, setQuestion8] = useState("");
  const [answer8, setAnswer8] = useState("");

  const [title9, setTitle9] = useState("");
  const [question9, setQuestion9] = useState("");
  const [answer9, setAnswer9] = useState("");

  // For "Öğe Sıralama + Yanıltıcı Cevaplı"
  const [title10, setTitle10] = useState("");
  const [question10, setQuestion10] = useState("");
  const [answer10, setAnswer10] = useState("");
  const [answer101, setAnswer101] = useState("");
  const audio10 = useRef(null);
  const [voice10, setVoice10] = useState(null);

  const [title11, setTitle11] = useState("");
  const [question11, setQuestion11] = useState("");
  const [answer11, setAnswer11] = useState("");
  const [answer111, setAnswer111] = useState("");
  const audio11 = useRef(null);
  const [voice11, setVoice11] = useState(null);

  const [title12, setTitle12] = useState("");
  const [question12, setQuestion12] = useState("");
  const [answer12, setAnswer12] = useState("");
  const [answer121, setAnswer121] = useState("");
  const audio12 = useRef(null);
  const [voice12, setVoice12] = useState(null);

  // For "Cümleden Kelime Seçme"
  const [title13, setTitle13] = useState("");
  const [question13, setQuestion13] = useState("");
  const [answer13, setAnswer13] = useState("");

  const [title14, setTitle14] = useState("");
  const [question14, setQuestion14] = useState("");
  const [answer14, setAnswer14] = useState("");

  const [title15, setTitle15] = useState("");
  const [question15, setQuestion15] = useState("");
  const [answer15, setAnswer15] = useState("");

  // For "Fonetik Alfabeli Soru"
  const [isPhoneticHidden, setIsPhoneticHidden] = useState(true);

  const [title16, setTitle16] = useState("");
  const [question16, setQuestion16] = useState("");
  const audio16 = useRef(null);
  const [voice16, setVoice16] = useState(null);
  const [answer16, setAnswer16] = useState("");
  const [answer161, setAnswer161] = useState("");
  const [answer162, setAnswer162] = useState("");
  const [answer163, setAnswer163] = useState("");

  const [title17, setTitle17] = useState("");
  const [question17, setQuestion17] = useState("");
  const audio17 = useRef(null);
  const [voice17, setVoice17] = useState(null);
  const [answer17, setAnswer17] = useState("");
  const [answer171, setAnswer171] = useState("");
  const [answer172, setAnswer172] = useState("");
  const [answer173, setAnswer173] = useState("");

  const [title18, setTitle18] = useState("");
  const [question18, setQuestion18] = useState("");
  const audio18 = useRef(null);
  const [voice18, setVoice18] = useState(null);
  const [answer18, setAnswer18] = useState("");
  const [answer181, setAnswer181] = useState("");
  const [answer182, setAnswer182] = useState("");
  const [answer183, setAnswer183] = useState("");

  // General variables
  const [isPlaying, setIsPlaying] = useState(false);
  const [dataReady, setDataReady] = useState(false);
  const [tempData, setTempData] = useState([]);
  const [cookies] = useCookies();
  const [questionType, setQuestionType] = useState([]);
  const [optionQuestionTypeValue, setOptionQuestionTypeValue] = useState();
  const [error, setError] = useState(false);
  let isPlayingVoice = [];

  const optionsQuestionType = [
    { value: "all", label: "Hepsi" },
    { value: "sort1", label: "1. Öğe Sıralama" },
    { value: "sort2", label: "2. Öğe Sıralama" },
    { value: "sort3", label: "3. Öğe Sıralama" },
    { value: "choice1", label: "4. Seçenekli Sorular" },
    { value: "choice2", label: "5. Seçenekli Sorular" },
    { value: "choice3", label: "6. Seçenekli Sorular" },
    { value: "fill1", label: "7. Boşluk Doldur" },
    { value: "fill2", label: "8. Boşluk Doldur" },
    { value: "fill3", label: "9. Boşluk Doldur" },
    { value: "sortWithWrong1", label: "10. Öğe Sıralama + Yanıltıcı Cevaplı" },
    { value: "sortWithWrong2", label: "11. Öğe Sıralama + Yanıltıcı Cevaplı" },
    { value: "sortWithWrong3", label: "12. Öğe Sıralama + Yanıltıcı Cevaplı" },
    { value: "chooseWord1", label: "13. Cümleden Kelime Seçme" },
    { value: "chooseWord2", label: "14. Cümleden Kelime Seçme" },
    { value: "chooseWord3", label: "15. Cümleden Kelime Seçme" },
    { value: "phonetic1", label: "16. Fonetik Alfabeli Soru" },
    { value: "phonetic2", label: "17. Fonetik Alfabeli Soru" },
    { value: "phonetic3", label: "18. Fonetik Alfabeli Soru" },
  ];

  const [checkbox, setCheckbox] = useState("");
  const [datatable, setDatatable] = useState({
    columns: [
      {
        label: "Başlık",
        field: "title",
      },
      {
        label: "Soru",
        field: "question",
      },
      {
        label: "Soru (Ses)",
        field: "voiceLink",
      },
      {
        label: "Hafta",
        field: "week",
        sort: "asc",
      },
      {
        label: "Seviye",
        field: "level",
      },
      {
        label: "Tür",
        field: "type",
      },
      {
        label: "Doğru Cevap",
        field: "answer",
      },
      {
        label: "Cevap 1",
        field: "answer1",
      },
      {
        label: "Cevap 2",
        field: "answer2",
      },
      {
        label: "Cevap 3",
        field: "answer3",
      },
      {
        label: "Cevap 4",
        field: "answer4",
      },
    ],
    rows: [],
  });

  var Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 5000,
  });

  const deleteClickHandler = () => {
    if (checkbox !== "") {
      if (
        window.confirm(
          checkbox.title + " sorusunu silmek istedğinize emin misiniz?"
        )
      ) {
        fetch(`${Var.api_url}/test/${checkbox.id}`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookies.admin_token}`,
          },
        })
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            Toast.fire({
              icon: "success",
              title: data.message,
            });
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          })
          .catch((err) => console.log(err));
      }
    } else {
      Toast.fire({
        icon: "error",
        title: "Önce seçim yapınız!",
      });
    }
  };

  const addClickHandler = () => {
    if (questionType.includes("all") || questionType.includes("sort1")) {
      if (title1 !== "" && question1 !== "" && answer1 !== "") {
        const formData = new FormData();
        formData.append("title", title1);
        formData.append("question", question1);
        formData.append("week", week);
        formData.append("level", level);
        formData.append("type", "sort");
        formData.append("answer", answer1);
        if (voice1 !== null) formData.append("voice", voice1);

        fetch(`${Var.api_url}/test/`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${cookies.admin_token}`,
          },
          body: formData,
        })
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            Toast.fire({
              icon: "success",
              title: data.message + " (" + title1 + ")",
            });
          })
          .catch((err) => {
            alert(err);
            setError(true);
          });
      } else {
        alert("Tüm boşlukları doğru bir şekilde doldurunuz");
      }
    }

    if (questionType.includes("all") || questionType.includes("sort2")) {
      if (title2 !== "" && question2 !== "" && answer2 !== "") {
        const formData = new FormData();
        formData.append("title", title2);
        formData.append("question", question2);
        formData.append("week", week);
        formData.append("level", level);
        formData.append("type", "sort");
        formData.append("answer", answer2);
        if (voice2 !== null) formData.append("voice", voice2);

        fetch(`${Var.api_url}/test/`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${cookies.admin_token}`,
          },
          body: formData,
        })
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            Toast.fire({
              icon: "success",
              title: data.message + " (" + title2 + ")",
            });
          })
          .catch((err) => {
            alert(err);
            setError(true);
          });
      } else {
        alert("Tüm boşlukları doğru bir şekilde doldurunuz");
      }
    }

    if (questionType.includes("all") || questionType.includes("sort3")) {
      if (title3 !== "" && question3 !== "" && answer3 !== "") {
        const formData = new FormData();
        formData.append("title", title3);
        formData.append("question", question3);
        formData.append("week", week);
        formData.append("level", level);
        formData.append("type", "sort");
        formData.append("answer", answer3);
        if (voice3 !== null) formData.append("voice", voice3);

        fetch(`${Var.api_url}/test/`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${cookies.admin_token}`,
          },
          body: formData,
        })
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            Toast.fire({
              icon: "success",
              title: data.message + " (" + title3 + ")",
            });
          })
          .catch((err) => {
            alert(err);
            setError(true);
          });
      } else {
        alert("Tüm boşlukları doğru bir şekilde doldurunuz");
      }
    }

    if (questionType.includes("all") || questionType.includes("choice1")) {
      if (
        title4 !== "" &&
        question4 !== "" &&
        answer41 !== "" &&
        !(choiceNumber1 > 1 && answer42 === "") &&
        !(choiceNumber1 > 2 && answer43 === "") &&
        !(choiceNumber1 > 3 && answer44 === "") &&
        !(choiceNumber1 > 4 && answer45 === "")
      ) {
        fetch(`${Var.api_url}/test/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookies.admin_token}`,
          },
          body: JSON.stringify({
            title: title4,
            question: question4,
            week,
            level,
            type: "choice",
            answer: answer41,
            answer1: answer42,
            answer2: answer43,
            answer3: answer44,
            answer4: answer45,
          }),
        })
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            Toast.fire({
              icon: "success",
              title: data.message + " (" + title4 + ")",
            });
          })
          .catch((err) => {
            alert(err);
            setError(true);
          });
      } else {
        alert("Tüm boşlukları doğru bir şekilde doldurunuz");
      }
    }

    if (questionType.includes("all") || questionType.includes("choice2")) {
      if (
        title5 !== "" &&
        question5 !== "" &&
        answer51 !== "" &&
        !(choiceNumber2 > 1 && answer52 === "") &&
        !(choiceNumber2 > 2 && answer53 === "") &&
        !(choiceNumber2 > 3 && answer54 === "") &&
        !(choiceNumber2 > 4 && answer55 === "")
      ) {
        fetch(`${Var.api_url}/test/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookies.admin_token}`,
          },
          body: JSON.stringify({
            title: title5,
            question: question5,
            week,
            level,
            type: "choice",
            answer: answer51,
            answer1: answer52,
            answer2: answer53,
            answer3: answer54,
            answer4: answer55,
          }),
        })
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            Toast.fire({
              icon: "success",
              title: data.message + " (" + title5 + ")",
            });
          })
          .catch((err) => {
            alert(err);
            setError(true);
          });
      } else {
        alert("Tüm boşlukları doğru bir şekilde doldurunuz");
      }
    }

    if (questionType.includes("all") || questionType.includes("choice3")) {
      if (
        title6 !== "" &&
        question6 !== "" &&
        answer61 !== "" &&
        !(choiceNumber3 > 1 && answer62 === "") &&
        !(choiceNumber3 > 2 && answer63 === "") &&
        !(choiceNumber3 > 3 && answer64 === "") &&
        !(choiceNumber3 > 4 && answer65 === "")
      ) {
        fetch(`${Var.api_url}/test/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookies.admin_token}`,
          },
          body: JSON.stringify({
            title: title6,
            question: question6,
            week,
            level,
            type: "choice",
            answer: answer61,
            answer1: answer62,
            answer2: answer63,
            answer3: answer64,
            answer4: answer65,
          }),
        })
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            Toast.fire({
              icon: "success",
              title: data.message + " (" + title6 + ")",
            });
          })
          .catch((err) => {
            alert(err);
            setError(true);
          });
      } else {
        alert("Tüm boşlukları doğru bir şekilde doldurunuz");
      }
    }

    if (questionType.includes("all") || questionType.includes("fill1")) {
      if (title7 !== "" && question7 !== "" && answer7 !== "") {
        fetch(`${Var.api_url}/test/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookies.admin_token}`,
          },
          body: JSON.stringify({
            title: title7,
            question: question7,
            week,
            level,
            type: "fill",
            answer: answer7,
          }),
        })
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            Toast.fire({
              icon: "success",
              title: data.message + " (" + title7 + ")",
            });
          })
          .catch((err) => {
            alert(err);
            setError(true);
          });
      } else {
        alert("Tüm boşlukları doğru bir şekilde doldurunuz");
      }
    }

    if (questionType.includes("all") || questionType.includes("fill2")) {
      if (title8 !== "" && question8 !== "" && answer8 !== "") {
        fetch(`${Var.api_url}/test/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookies.admin_token}`,
          },
          body: JSON.stringify({
            title: title8,
            question: question8,
            week,
            level,
            type: "fill",
            answer: answer8,
          }),
        })
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            Toast.fire({
              icon: "success",
              title: data.message + " (" + title8 + ")",
            });
          })
          .catch((err) => {
            alert(err);
            setError(true);
          });
      } else {
        alert("Tüm boşlukları doğru bir şekilde doldurunuz");
      }
    }

    if (questionType.includes("all") || questionType.includes("fill3")) {
      if (title9 !== "" && question9 !== "" && answer9 !== "") {
        fetch(`${Var.api_url}/test/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookies.admin_token}`,
          },
          body: JSON.stringify({
            title: title9,
            question: question9,
            week,
            level,
            type: "fill",
            answer: answer9,
          }),
        })
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            Toast.fire({
              icon: "success",
              title: data.message + " (" + title9 + ")",
            });
          })
          .catch((err) => {
            alert(err);
            setError(true);
          });
      } else {
        alert("Tüm boşlukları doğru bir şekilde doldurunuz");
      }
    }

    if (
      questionType.includes("all") ||
      questionType.includes("sortWithWrong1")
    ) {
      if (
        title10 !== "" &&
        question10 !== "" &&
        answer10 !== "" &&
        answer101 !== ""
      ) {
        const formData = new FormData();
        formData.append("title", title10);
        formData.append("question", question10);
        formData.append("week", week);
        formData.append("level", level);
        formData.append("type", "sortWithWrong");
        formData.append("answer", answer10);
        formData.append("answer1", answer101);
        if (voice10 !== null) formData.append("voice", voice10);

        fetch(`${Var.api_url}/test/`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${cookies.admin_token}`,
          },
          body: formData,
        })
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            Toast.fire({
              icon: "success",
              title: data.message + " (" + title10 + ")",
            });
          })
          .catch((err) => {
            alert(err);
            setError(true);
          });
      } else {
        alert("Tüm boşlukları doğru bir şekilde doldurunuz");
      }
    }

    if (
      questionType.includes("all") ||
      questionType.includes("sortWithWrong2")
    ) {
      if (
        title11 !== "" &&
        question11 !== "" &&
        answer11 !== "" &&
        answer111 !== ""
      ) {
        const formData = new FormData();
        formData.append("title", title11);
        formData.append("question", question11);
        formData.append("week", week);
        formData.append("level", level);
        formData.append("type", "sortWithWrong");
        formData.append("answer", answer11);
        formData.append("answer1", answer111);
        if (voice11 !== null) formData.append("voice", voice11);

        fetch(`${Var.api_url}/test/`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${cookies.admin_token}`,
          },
          body: formData,
        })
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            Toast.fire({
              icon: "success",
              title: data.message + " (" + title11 + ")",
            });
          })
          .catch((err) => {
            alert(err);
            setError(true);
          });
      } else {
        alert("Tüm boşlukları doğru bir şekilde doldurunuz");
      }
    }

    if (
      questionType.includes("all") ||
      questionType.includes("sortWithWrong3")
    ) {
      if (
        title12 !== "" &&
        question12 !== "" &&
        answer12 !== "" &&
        answer121 !== ""
      ) {
        const formData = new FormData();
        formData.append("title", title12);
        formData.append("question", question12);
        formData.append("week", week);
        formData.append("level", level);
        formData.append("type", "sortWithWrong");
        formData.append("answer", answer12);
        formData.append("answer1", answer121);
        if (voice12 !== null) formData.append("voice", voice12);

        fetch(`${Var.api_url}/test/`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${cookies.admin_token}`,
          },
          body: formData,
        })
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            Toast.fire({
              icon: "success",
              title: data.message + " (" + title12 + ")",
            });
          })
          .catch((err) => {
            alert(err);
            setError(true);
          });
      } else {
        alert("Tüm boşlukları doğru bir şekilde doldurunuz");
      }
    }

    if (questionType.includes("all") || questionType.includes("chooseWord1")) {
      if (title13 !== "" && question13 !== "" && answer13 !== "") {
        fetch(`${Var.api_url}/test/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookies.admin_token}`,
          },
          body: JSON.stringify({
            title: title13,
            question: question13,
            week,
            level,
            type: "chooseWord",
            answer: answer13,
          }),
        })
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            Toast.fire({
              icon: "success",
              title: data.message + " (" + title13 + ")",
            });
          })
          .catch((err) => {
            alert(err);
            setError(true);
          });
      } else {
        alert("Tüm boşlukları doğru bir şekilde doldurunuz");
      }
    }

    if (questionType.includes("all") || questionType.includes("chooseWord2")) {
      if (title14 !== "" && question14 !== "" && answer14 !== "") {
        fetch(`${Var.api_url}/test/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookies.admin_token}`,
          },
          body: JSON.stringify({
            title: title14,
            question: question14,
            week,
            level,
            type: "chooseWord",
            answer: answer14,
          }),
        })
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            Toast.fire({
              icon: "success",
              title: data.message + " (" + title14 + ")",
            });
          })
          .catch((err) => {
            alert(err);
            setError(true);
          });
      } else {
        alert("Tüm boşlukları doğru bir şekilde doldurunuz");
      }
    }

    if (questionType.includes("all") || questionType.includes("chooseWord3")) {
      if (title15 !== "" && question15 !== "" && answer15 !== "") {
        fetch(`${Var.api_url}/test/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookies.admin_token}`,
          },
          body: JSON.stringify({
            title: title15,
            question: question15,
            week,
            level,
            type: "chooseWord",
            answer: answer15,
          }),
        })
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            Toast.fire({
              icon: "success",
              title: data.message + " (" + title15 + ")",
            });
          })
          .catch((err) => {
            alert(err);
            setError(true);
          });
      } else {
        alert("Tüm boşlukları doğru bir şekilde doldurunuz");
      }
    }

    if (questionType.includes("all") || questionType.includes("phonetic1")) {
      if (title16 !== "" && question16 !== "" && answer16 !== "") {
        const formData = new FormData();

        formData.append("title", title16);
        formData.append("question", question16);
        formData.append("week", week);
        formData.append("level", level);
        formData.append("type", "phonetic");
        formData.append("answer", answer16);
        if (answer161 !== "") formData.append("answer1", answer161);
        if (answer162 !== "") formData.append("answer2", answer162);
        if (answer163 !== "") formData.append("answer3", answer163);
        if (voice16 !== null) formData.append("voice", voice16);

        fetch(`${Var.api_url}/test/`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${cookies.admin_token}`,
          },
          body: formData,
        })
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            Toast.fire({
              icon: "success",
              title: data.message + " (" + title16 + ")",
            });
          })
          .catch((err) => {
            alert(err);
            setError(true);
          });
      } else {
        alert("Tüm boşlukları doğru bir şekilde doldurunuz");
      }
    }

    if (questionType.includes("all") || questionType.includes("phonetic2")) {
      if (title17 !== "" && question17 !== "" && answer17 !== "") {
        const formData = new FormData();
        formData.append("title", title17);
        formData.append("question", question17);
        formData.append("week", week);
        formData.append("level", level);
        formData.append("type", "phonetic");
        formData.append("answer", answer17);
        if (answer171 !== "") formData.append("answer1", answer171);
        if (answer172 !== "") formData.append("answer2", answer172);
        if (answer173 !== "") formData.append("answer3", answer173);
        if (voice17 !== null) formData.append("voice", voice17);
        fetch(`${Var.api_url}/test/`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${cookies.admin_token}`,
          },
          body: formData,
        })
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            Toast.fire({
              icon: "success",
              title: data.message + " (" + title17 + ")",
            });
          })
          .catch((err) => {
            alert(err);
            setError(true);
          });
      } else {
        alert("Tüm boşlukları doğru bir şekilde doldurunuz");
      }
    }

    if (questionType.includes("all") || questionType.includes("phonetic3")) {
      if (title18 !== "" && question18 !== "" && answer18 !== "") {
        const formData = new FormData();
        formData.append("title", title18);
        formData.append("question", question18);
        formData.append("week", week);
        formData.append("level", level);
        formData.append("type", "phonetic");
        formData.append("answer", answer18);
        if (answer181 !== "") formData.append("answer1", answer181);
        if (answer182 !== "") formData.append("answer2", answer182);
        if (answer183 !== "") formData.append("answer3", answer183);
        if (voice18 !== null) formData.append("voice", voice18);
        fetch(`${Var.api_url}/test/`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${cookies.admin_token}`,
          },
          body: formData,
        })
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            Toast.fire({
              icon: "success",
              title: data.message + " (" + title18 + ")",
            });
          })
          .catch((err) => {
            alert(err);
            setError(true);
          });
      } else {
        alert("Tüm boşlukları doğru bir şekilde doldurunuz");
      }
    }

    setTimeout(() => {
      if (!error) {
        window.location.reload();
      }
    }, 2500);
  };

  // useEffect(() => {
  //   fetch(`${Var.api_url}/test/getForAdmin`, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${cookies.admin_token}`,
  //     },
  //   })
  //     .then((res) => {
  //       return res.json();
  //     })
  //     .then((data) => setDatatable({ ...datatable, rows: data }))
  //     .then(() => setDataReady(true))
  //     .catch((err) => console.log(err));
  // }, []);

  const GetAdminForWeek = () => {
    setDataReady(false);
    fetch(`${Var.api_url}/test/admin/${getAdminWeek}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookies.admin_token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        console.log(data);
        setTempData(data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (tempData.length !== 0) {
      let tempArray = [...tempData];
      tempData.map((data, index) => {
        isPlayingVoice.push(false);
        if (tempArray[index].voice !== null) {
          let audioLink = new Audio(tempArray[index].voice);
          tempArray[index].voiceLink = (
            <>
              <PlayIcon
                id={"icon-play-" + index}
                className="!w-7 cursor-pointer"
                onClick={() => voicePlayed(audioLink, index)}
              />
              <PauseIcon
                id={"icon-pause-" + index}
                className="!w-7 cursor-pointer hidden"
                onClick={() => voicePlayed(audioLink, index)}
              />
            </>
          );
        }
        tempArray[index].date = Moment(tempArray[index].date).format(
          "DD/MM/YYYY HH:mm:ss"
        );

        const levelLabel = Var.optionsLevel.find(
          (level) => level.value === String(data.level)
        )?.label;
        tempArray[index].level = levelLabel || data.level;

        const weekLabel = Var.optionsWeek.find(
          (week) => week.value === String(data.week)
        )?.label;
        tempArray[index].week = weekLabel || data.week;
      });
      setDatatable({ ...datatable, rows: tempArray });
      setDataReady(true);
    }
  }, [tempData]);

  const voicePlayed = (audioLink, index) => {
    if (!isPlayingVoice[index]) {
      audioLink.play();
      isPlayingVoice[index] = true;
      document.getElementById(`icon-play-${index}`).classList.add("hidden");
      document.getElementById(`icon-pause-${index}`).classList.remove("hidden");
    } else if (isPlayingVoice[index]) {
      audioLink.pause();
      isPlayingVoice[index] = false;
      document.getElementById(`icon-play-${index}`).classList.remove("hidden");
      document.getElementById(`icon-pause-${index}`).classList.add("hidden");
    }
  };

  useEffect(() => {
    if (values !== null) {
      if (
        (type === "sort" &&
          title1 !== "" &&
          question1 !== "" &&
          answer1 !== "") ||
        (type === "choice" &&
          title4 !== "" &&
          question4 !== "" &&
          answer41 !== "" &&
          !(choiceNumber1 > 1 && answer42 === "") &&
          !(choiceNumber1 > 2 && answer43 === "") &&
          !(choiceNumber1 > 3 && answer44 === "") &&
          !(choiceNumber1 > 4 && answer45 === "")) ||
        (type === "fill" &&
          title7 !== "" &&
          question7 !== "" &&
          answer7 !== "") ||
        (type === "sortWithWrong" &&
          title10 !== "" &&
          question10 !== "" &&
          answer10 !== "" &&
          answer101 !== "") ||
        (type === "chooseWord" &&
          title13 !== "" &&
          question13 !== "" &&
          answer13 !== "") ||
        (type === "phonetic" &&
          title16 !== "" &&
          question16 !== "" &&
          answer16 !== "")
      ) {
        const headerValues =
          type === "phonetic" || type === "sort" || type === "sortWithWrong"
            ? { Authorization: `Bearer ${cookies.admin_token}` }
            : {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${cookies.admin_token}`,
              };

        fetch(`${Var.api_url}/test/${testID}`, {
          method: "PATCH",
          headers: headerValues,
          body: values,
        })
          .then((res) => {
            if (!res.ok) {
              Toast.fire({
                icon: "error",
                title: "Bir hata oluştu",
              });
            }
            return res.json();
          })
          .then((data) => {
            Toast.fire({
              icon: "success",
              title: data.message,
            });
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          });
      } else {
        alert("Tüm boşlukları doğru bir şekilde doldurunuz");
      }
    }
  }, [values]);

  const updateTest = () => {
    if (checkbox.type === "sort") {
      const formData = new FormData();
      formData.append("title", title1);
      formData.append("question", question1);
      formData.append("week", week);
      formData.append("level", level);
      formData.append("type", type);
      formData.append("answer", answer1);
      if (voice1 !== null) formData.append("voice", voice1);
      setValues(formData);
    } else if (checkbox.type === "choice") {
      setValues(
        JSON.stringify({
          title: title4,
          question: question4,
          week,
          level,
          type,
          answer: answer41,
          answer1: answer42,
          answer2: answer43,
          answer3: answer44,
          answer4: answer45,
        })
      );
    } else if (checkbox.type === "fill") {
      setValues(
        JSON.stringify({
          title: title7,
          question: question7,
          week,
          level,
          type,
          answer: answer7,
        })
      );
    } else if (checkbox.type === "sortWithWrong") {
      const formData = new FormData();
      formData.append("title", title10);
      formData.append("question", question10);
      formData.append("week", week);
      formData.append("level", level);
      formData.append("type", type);
      formData.append("answer", answer10);
      formData.append("answer1", answer101);
      if (voice10 !== null) formData.append("voice", voice10);
      setValues(formData);
    } else if (checkbox.type === "chooseWord") {
      setValues(
        JSON.stringify({
          title: title13,
          question: question13,
          week,
          level,
          type,
          answer: answer13,
        })
      );
    } else if (checkbox.type === "phonetic") {
      const formData = new FormData();
      formData.append("title", title16);
      formData.append("question", question16);
      formData.append("week", week);
      formData.append("level", level);
      formData.append("type", "phonetic");
      formData.append("answer", answer16);
      if (answer161 !== "") formData.append("answer1", answer161);
      if (answer162 !== "") formData.append("answer2", answer162);
      if (answer163 !== "") formData.append("answer3", answer163);
      if (voice16 !== null) formData.append("voice", voice16);
      setValues(formData);
    }
    // Update fetch is in useEffect [values]
  };

  const updateClickHandler = () => {
    if (checkbox !== "") {
      setUpdateClicked(true);

      setTestID(checkbox.id);
      setLevel(checkbox.level);
      setWeek(checkbox.week);
      setType(checkbox.type);
      setQuestionType(`${checkbox.type}1`);

      if (checkbox.type === "sort") {
        setTitle1(checkbox.title);
        setQuestion1(checkbox.question);
        setAnswer1(checkbox.answer);
        if (checkbox.voice !== null) setVoice1(checkbox.voice);
      } else if (checkbox.type === "choice") {
        setTitle4(checkbox.title);
        setQuestion4(checkbox.question);
        setAnswer41(checkbox.answer);
        setAnswer42(checkbox.answer1);
        setAnswer43(checkbox.answer2);
        setAnswer44(checkbox.answer3);
        setAnswer45(checkbox.answer4);
      } else if (checkbox.type === "fill") {
        setTitle7(checkbox.title);
        setQuestion7(checkbox.question);
        setAnswer7(checkbox.answer);
      } else if (checkbox.type === "sortWithWrong") {
        setTitle10(checkbox.title);
        setQuestion10(checkbox.question);
        setAnswer10(checkbox.answer);
        setAnswer101(checkbox.answer1);
        if (checkbox.voice !== null) setVoice10(checkbox.voice);
      } else if (checkbox.type === "chooseWord") {
        setTitle13(checkbox.title);
        setQuestion13(checkbox.question);
        setAnswer13(checkbox.answer);
      } else if (checkbox.type === "phonetic") {
        setTitle16(checkbox.title);
        setQuestion16(checkbox.question);
        setAnswer16(checkbox.answer);
        if (checkbox.answer1 !== null) setAnswer161(checkbox.answer1);
        if (checkbox.answer2 !== null) setAnswer162(checkbox.answer2);
        if (checkbox.answer3 !== null) setAnswer163(checkbox.answer3);
        if (checkbox.voice !== null) setVoice16(checkbox.voice);
      }
    } else {
      Toast.fire({
        icon: "error",
        title: "Önce seçim yapınız!",
      });
    }
  };

  useEffect(() => {
    if (!updateClicked) setQuestionType([]);
  }, [updateClicked]);

  const voicePlayedWithRef = (ref) => {
    if (!isPlaying) {
      ref.current.play();
      setIsPlaying(true);
    } else if (isPlaying) {
      ref.current.pause();
      setIsPlaying(false);
    }
  };

  return (
    <div>
      <div className="card card-info">
        <div className="card-header">
          <h3 className="card-title">Soru Ekleme</h3>
        </div>
        {/* /.card-header */}
        {/* form start */}
        <div className="card-body row">
          <form className="col-md-12">
            <div className="card">
              <div className="card-header row">
                <div>
                  Soru listesinde gözükmesini istediklerinizi aşağıya
                  ekleyebilirsiniz.
                </div>
              </div>
              {/* /.card-header */}
              <div className="card-body">
                {/* <div className="d-flex flex-row align-items-center mb-2">
                  <label className="col-md-2" htmlFor="input-turkish">
                    Türkçe
                  </label>
                  <input
                    id="input-turkish"
                    className="form-control"
                    placeholder="Türkçe cümle..."
                    onChange={(e) => setQuestion1(e.target.value)}
                  />
                </div>
                <div className="d-flex flex-row align-items-center">
                  <label className="col-md-2" htmlFor="input-english">
                    İngilizce
                  </label>
                  <input
                    id="input-english"
                    className="form-control"
                    placeholder="İngilizce cümle..."
                    onChange={(e) => setQuestion2(e.target.value)}
                  />
                </div> */}
                <br />
                <div className="col-md-12 d-flex flex-row mb-5 justify-content-around">
                  <div className="d-flex  flex-row align-items-center">
                    <label className="mr-2" type="text">
                      Seviye
                    </label>
                    <Select
                      defaultValue={level}
                      onChange={(e) => setLevel(e.value)}
                      options={Var.optionsLevel}
                      placeholder={level}
                      className="w-48"
                    />
                  </div>
                  <div className="d-flex flex-row align-items-center">
                    <label className="mr-2" type="text">
                      Hafta
                    </label>
                    <Select
                      defaultValue={week}
                      onChange={(e) => setWeek(e.value)}
                      options={Var.optionsWeek}
                      placeholder={week}
                      className="w-48"
                    />
                  </div>
                  <div className="d-flex flex-row align-items-center">
                    <label className="mr-2" type="text">
                      Kapsam
                    </label>
                    <Select isDisabled />
                  </div>
                </div>

                {/* <Phonetic phoneticSentence={ document.activeElement.innerHTML } /> */}

                {/* 1. Öğe Sıralama */}
                {(questionType.includes("all") ||
                  questionType.includes("sort1")) && (
                  <div className="mb-3 border p-3 rounded-3xl">
                    <p className="mb-3">
                      <span className="h4 mr-3">1. Öğe Sıralama</span>
                      Cevapların arasını virgül işareti (,) ile ayırınız.
                    </p>
                    <div className="d-flex flex-row align-items-center mb-2">
                      <label className="col-md-2" htmlFor="input-title1">
                        Başlık
                      </label>
                      <input
                        id="input-title1"
                        className="form-control"
                        placeholder="Başlık..."
                        value={title1}
                        onChange={(e) => setTitle1(e.target.value)}
                      />
                    </div>
                    <div className="d-flex flex-row align-items-center mb-2">
                      <label className="col-md-2" htmlFor="input-question1">
                        Soru
                      </label>
                      <input
                        id="input-question1"
                        className="form-control"
                        placeholder="Soru..."
                        value={question1}
                        onChange={(e) => setQuestion1(e.target.value)}
                      />
                    </div>
                    <div className="d-flex flex-row align-items-center mb-2">
                      <label className="col-md-2" htmlFor="input-turkish">
                        Ses
                      </label>
                      <audio ref={audio1} src={voice1}></audio>
                      <CloudUploadIcon
                        className="!w-7 cursor-pointer ml-2"
                        onClick={() =>
                          document.getElementById("input-voice1").click()
                        }
                      />
                      <input
                        id="input-voice1"
                        type="file"
                        className="hidden"
                        onChange={(e) => setVoice1(e.target.files[0])}
                      />
                      {voice1 !== null && <p>&nbsp;{voice1.name}</p>}
                    </div>
                    <div className="d-flex flex-row align-items-center mb-2">
                      <label className="col-md-2" htmlFor="input-answer1">
                        Cevap
                      </label>
                      <input
                        id="input-answer1"
                        className="form-control"
                        placeholder="Cevap..."
                        value={answer1}
                        onChange={(e) => setAnswer1(e.target.value)}
                      />
                    </div>
                  </div>
                )}

                {/* 2. Öğe Sıralama */}
                {(questionType.includes("all") ||
                  questionType.includes("sort2")) && (
                  <div className="mb-3 border p-3 rounded-3xl">
                    <p className="mb-3">
                      <span className="h4 mr-3">2. Öğe Sıralama</span>
                      Cevapların arasını virgül işareti (,) ile ayırınız.
                    </p>
                    <div className="d-flex flex-row align-items-center mb-2">
                      <label className="col-md-2" htmlFor="input-title2">
                        Başlık
                      </label>
                      <input
                        id="input-title2"
                        className="form-control"
                        placeholder="Başlık..."
                        value={title2}
                        onChange={(e) => setTitle2(e.target.value)}
                      />
                    </div>
                    <div className="d-flex flex-row align-items-center mb-2">
                      <label className="col-md-2" htmlFor="input-question2">
                        Soru
                      </label>
                      <input
                        id="input-question2"
                        className="form-control"
                        placeholder="Soru..."
                        value={question2}
                        onChange={(e) => setQuestion2(e.target.value)}
                      />
                    </div>
                    <div className="d-flex flex-row align-items-center mb-2">
                      <label className="col-md-2" htmlFor="input-turkish">
                        Ses
                      </label>
                      <audio ref={audio2} src={voice2}></audio>
                      <CloudUploadIcon
                        className="!w-7 cursor-pointer ml-2"
                        onClick={() =>
                          document.getElementById("input-voice2").click()
                        }
                      />
                      <input
                        id="input-voice2"
                        type="file"
                        className="hidden"
                        onChange={(e) => setVoice2(e.target.files[0])}
                      />
                      {voice2 !== null && <p>&nbsp;{voice2.name}</p>}
                    </div>
                    <div className="d-flex flex-row align-items-center mb-2">
                      <label className="col-md-2" htmlFor="input-answer2">
                        Cevap
                      </label>
                      <input
                        id="input-answer2"
                        className="form-control"
                        placeholder="Cevap..."
                        value={answer2}
                        onChange={(e) => setAnswer2(e.target.value)}
                      />
                    </div>
                  </div>
                )}

                {/* 3. Öğe Sıralama */}
                {(questionType.includes("all") ||
                  questionType.includes("sort3")) && (
                  <div className="mb-3 border p-3 rounded-3xl">
                    <p className="mb-3">
                      <span className="h4 mr-3">3. Öğe Sıralama</span>
                      Cevapların arasını virgül işareti (,) ile ayırınız.
                    </p>
                    <div className="d-flex flex-row align-items-center mb-2">
                      <label className="col-md-2" htmlFor="input-title3">
                        Başlık
                      </label>
                      <input
                        id="input-title3"
                        className="form-control"
                        placeholder="Başlık..."
                        value={title3}
                        onChange={(e) => setTitle3(e.target.value)}
                      />
                    </div>
                    <div className="d-flex flex-row align-items-center mb-2">
                      <label className="col-md-2" htmlFor="input-question3">
                        Soru
                      </label>
                      <input
                        id="input-question3"
                        className="form-control"
                        placeholder="Soru..."
                        value={question3}
                        onChange={(e) => setQuestion3(e.target.value)}
                      />
                    </div>
                    <div className="d-flex flex-row align-items-center mb-2">
                      <label className="col-md-2" htmlFor="input-turkish">
                        Ses
                      </label>
                      <audio ref={audio3} src={voice3}></audio>
                      <CloudUploadIcon
                        className="!w-7 cursor-pointer ml-2"
                        onClick={() =>
                          document.getElementById("input-voice3").click()
                        }
                      />
                      <input
                        id="input-voice3"
                        type="file"
                        className="hidden"
                        onChange={(e) => setVoice3(e.target.files[0])}
                      />
                      {voice3 !== null && <p>&nbsp;{voice3.name}</p>}
                    </div>
                    <div className="d-flex flex-row align-items-center mb-2">
                      <label className="col-md-2" htmlFor="input-answer3">
                        Cevap
                      </label>
                      <input
                        id="input-answer3"
                        className="form-control"
                        placeholder="Cevap..."
                        value={answer3}
                        onChange={(e) => setAnswer3(e.target.value)}
                      />
                    </div>
                  </div>
                )}

                {/* 4. Seçenekli Sorular */}
                {(questionType.includes("all") ||
                  questionType.includes("choice1")) && (
                  <div className="mb-3 border p-3 rounded-3xl">
                    <p className="mb-3">
                      <span className="h4 mr-3">4. Seçenekli Sorular</span>
                      Doğru cevaplar en üst satırda olmalıdır.
                    </p>
                    <div className="d-flex flex-row align-items-center mb-2">
                      <label className="col-md-2" htmlFor="input-title4">
                        Başlık
                      </label>
                      <input
                        id="input-title4"
                        className="form-control"
                        placeholder="Başlık..."
                        value={title4}
                        onChange={(e) => setTitle4(e.target.value)}
                      />
                    </div>
                    <div className="d-flex flex-row align-items-center mb-2">
                      <label className="col-md-2" htmlFor="input-question4">
                        Soru
                      </label>
                      <input
                        id="input-question4"
                        className="form-control"
                        placeholder="Soru..."
                        value={question4}
                        onChange={(e) => setQuestion4(e.target.value)}
                      />
                    </div>
                    <div className="d-flex flex-row align-items-center mb-2">
                      <label className="col-md-2" htmlFor="input-answer41">
                        Doğru Cevap
                      </label>
                      <input
                        id="input-answer41"
                        className="form-control"
                        placeholder="Cevap..."
                        value={answer41}
                        onChange={(e) => setAnswer41(e.target.value)}
                      />
                    </div>
                    {choiceNumber1 > 1 && (
                      <div className="d-flex flex-row align-items-center mb-2">
                        <label className="col-md-2" htmlFor="input-answer42">
                          Cevap - 2
                        </label>
                        <input
                          id="input-answer42"
                          className="form-control"
                          placeholder="Cevap..."
                          value={answer42}
                          onChange={(e) => setAnswer42(e.target.value)}
                        />
                      </div>
                    )}
                    {choiceNumber1 > 2 && (
                      <div className="d-flex flex-row align-items-center mb-2">
                        <label className="col-md-2" htmlFor="input-answer43">
                          Cevap - 3
                        </label>
                        <input
                          id="input-answer43"
                          className="form-control"
                          placeholder="Cevap..."
                          value={answer43}
                          onChange={(e) => setAnswer43(e.target.value)}
                        />
                      </div>
                    )}
                    {choiceNumber1 > 3 && (
                      <div className="d-flex flex-row align-items-center mb-2">
                        <label className="col-md-2" htmlFor="input-answer44">
                          Cevap - 4
                        </label>
                        <input
                          id="input-answer44"
                          className="form-control"
                          placeholder="Cevap..."
                          value={answer44}
                          onChange={(e) => setAnswer44(e.target.value)}
                        />
                      </div>
                    )}
                    {choiceNumber1 > 4 && (
                      <div className="d-flex flex-row align-items-center mb-2">
                        <label className="col-md-2" htmlFor="input-answer45">
                          Cevap - 5
                        </label>
                        <input
                          id="input-answer45"
                          className="form-control"
                          placeholder="Cevap..."
                          value={answer45}
                          onChange={(e) => setAnswer45(e.target.value)}
                        />
                      </div>
                    )}
                    <PlusSmIcon
                      className="ml-auto w-8 h-8 cursor-pointer"
                      onClick={() => setChoiceNumber1(choiceNumber1 + 1)}
                    />
                  </div>
                )}

                {/* 5. Seçenekli Sorular */}
                {(questionType.includes("all") ||
                  questionType.includes("choice2")) && (
                  <div className="mb-3 border p-3 rounded-3xl">
                    <p className="mb-3">
                      <span className="h4 mr-3">5. Seçenekli Sorular</span>
                      Doğru cevaplar en üst satırda olmalıdır.
                    </p>
                    <div className="d-flex flex-row align-items-center mb-2">
                      <label className="col-md-2" htmlFor="input-title5">
                        Başlık
                      </label>
                      <input
                        id="input-title5"
                        className="form-control"
                        placeholder="Başlık..."
                        value={title5}
                        onChange={(e) => setTitle5(e.target.value)}
                      />
                    </div>
                    <div className="d-flex flex-row align-items-center mb-2">
                      <label className="col-md-2" htmlFor="input-question5">
                        Soru
                      </label>
                      <input
                        id="input-question5"
                        className="form-control"
                        placeholder="Soru..."
                        value={question5}
                        onChange={(e) => setQuestion5(e.target.value)}
                      />
                    </div>
                    <div className="d-flex flex-row align-items-center mb-2">
                      <label className="col-md-2" htmlFor="input-answer51">
                        Doğru Cevap
                      </label>
                      <input
                        id="input-answer51"
                        className="form-control"
                        placeholder="Cevap..."
                        value={answer51}
                        onChange={(e) => setAnswer51(e.target.value)}
                      />
                    </div>
                    {choiceNumber2 > 1 && (
                      <div className="d-flex flex-row align-items-center mb-2">
                        <label className="col-md-2" htmlFor="input-answer52">
                          Cevap - 2
                        </label>
                        <input
                          id="input-answer52"
                          className="form-control"
                          placeholder="Cevap..."
                          value={answer52}
                          onChange={(e) => setAnswer52(e.target.value)}
                        />
                      </div>
                    )}
                    {choiceNumber2 > 2 && (
                      <div className="d-flex flex-row align-items-center mb-2">
                        <label className="col-md-2" htmlFor="input-answer53">
                          Cevap - 3
                        </label>
                        <input
                          id="input-answer53"
                          className="form-control"
                          placeholder="Cevap..."
                          value={answer53}
                          onChange={(e) => setAnswer53(e.target.value)}
                        />
                      </div>
                    )}
                    {choiceNumber2 > 3 && (
                      <div className="d-flex flex-row align-items-center mb-2">
                        <label className="col-md-2" htmlFor="input-answer54">
                          Cevap - 4
                        </label>
                        <input
                          id="input-answer54"
                          className="form-control"
                          placeholder="Cevap..."
                          value={answer54}
                          onChange={(e) => setAnswer54(e.target.value)}
                        />
                      </div>
                    )}
                    {choiceNumber2 > 4 && (
                      <div className="d-flex flex-row align-items-center mb-2">
                        <label className="col-md-2" htmlFor="input-answer55">
                          Cevap - 5
                        </label>
                        <input
                          id="input-answer55"
                          className="form-control"
                          placeholder="Cevap..."
                          value={answer55}
                          onChange={(e) => setAnswer55(e.target.value)}
                        />
                      </div>
                    )}
                    <PlusSmIcon
                      className="ml-auto w-8 h-8 cursor-pointer"
                      onClick={() => setChoiceNumber2(choiceNumber2 + 1)}
                    />
                  </div>
                )}

                {/* 6. Seçenekli Sorular */}
                {(questionType.includes("all") ||
                  questionType.includes("choice3")) && (
                  <div className="mb-3 border p-3 rounded-3xl">
                    <p className="mb-3">
                      <span className="h4 mr-3">6. Seçenekli Sorular</span>
                      Doğru cevaplar en üst satırda olmalıdır.
                    </p>
                    <div className="d-flex flex-row align-items-center mb-2">
                      <label className="col-md-2" htmlFor="input-title6">
                        Başlık
                      </label>
                      <input
                        id="input-titl64"
                        className="form-control"
                        placeholder="Başlık..."
                        value={title6}
                        onChange={(e) => setTitle6(e.target.value)}
                      />
                    </div>
                    <div className="d-flex flex-row align-items-center mb-2">
                      <label className="col-md-2" htmlFor="input-question6">
                        Soru
                      </label>
                      <input
                        id="input-question6"
                        className="form-control"
                        placeholder="Soru..."
                        value={question6}
                        onChange={(e) => setQuestion6(e.target.value)}
                      />
                    </div>
                    <div className="d-flex flex-row align-items-center mb-2">
                      <label className="col-md-2" htmlFor="input-answer61">
                        Doğru Cevap
                      </label>
                      <input
                        id="input-answer61"
                        className="form-control"
                        placeholder="Cevap..."
                        value={answer61}
                        onChange={(e) => setAnswer61(e.target.value)}
                      />
                    </div>
                    {choiceNumber3 > 1 && (
                      <div className="d-flex flex-row align-items-center mb-2">
                        <label className="col-md-2" htmlFor="input-answer62">
                          Cevap - 2
                        </label>
                        <input
                          id="input-answer62"
                          className="form-control"
                          placeholder="Cevap..."
                          value={answer62}
                          onChange={(e) => setAnswer62(e.target.value)}
                        />
                      </div>
                    )}
                    {choiceNumber3 > 2 && (
                      <div className="d-flex flex-row align-items-center mb-2">
                        <label className="col-md-2" htmlFor="input-answer63">
                          Cevap - 3
                        </label>
                        <input
                          id="input-answer63"
                          className="form-control"
                          placeholder="Cevap..."
                          value={answer63}
                          onChange={(e) => setAnswer63(e.target.value)}
                        />
                      </div>
                    )}
                    {choiceNumber3 > 3 && (
                      <div className="d-flex flex-row align-items-center mb-2">
                        <label className="col-md-2" htmlFor="input-answer64">
                          Cevap - 4
                        </label>
                        <input
                          id="input-answer64"
                          className="form-control"
                          placeholder="Cevap..."
                          value={answer64}
                          onChange={(e) => setAnswer64(e.target.value)}
                        />
                      </div>
                    )}
                    {choiceNumber3 > 4 && (
                      <div className="d-flex flex-row align-items-center mb-2">
                        <label className="col-md-2" htmlFor="input-answer65">
                          Cevap - 5
                        </label>
                        <input
                          id="input-answer65"
                          className="form-control"
                          placeholder="Cevap..."
                          value={answer65}
                          onChange={(e) => setAnswer65(e.target.value)}
                        />
                      </div>
                    )}
                    <PlusSmIcon
                      className="ml-auto w-8 h-8 cursor-pointer"
                      onClick={() => setChoiceNumber3(choiceNumber3 + 1)}
                    />
                  </div>
                )}

                {/* 7. Boşluk Doldur */}
                {(questionType.includes("all") ||
                  questionType.includes("fill1")) && (
                  <div className="mb-3 border p-3 rounded-3xl">
                    <p className="mb-3">
                      <span className="h4 mr-3">7. Boşluk Doldur</span>Cümle
                      içerisinde, başında ve sonunda artı işareti (+) bulunan
                      kelime doğru cevaptır. Cevapların arasını virgül işareti
                      (,) ile ayırınız.
                    </p>
                    <div className="d-flex flex-row align-items-center mb-2">
                      <label className="col-md-2" htmlFor="input-title7">
                        Başlık
                      </label>
                      <input
                        id="input-title7"
                        className="form-control"
                        placeholder="Başlık..."
                        value={title7}
                        onChange={(e) => setTitle7(e.target.value)}
                      />
                    </div>
                    <div className="d-flex flex-row align-items-center mb-2">
                      <label className="col-md-2" htmlFor="input-question7">
                        Soru
                      </label>
                      <input
                        id="input-question7"
                        className="form-control"
                        placeholder="Soru..."
                        value={question7}
                        onChange={(e) => setQuestion7(e.target.value)}
                      />
                    </div>
                    <div className="d-flex flex-row align-items-center mb-2">
                      <label className="col-md-2" htmlFor="input-answer7">
                        Cevap
                      </label>
                      <input
                        id="input-answer7"
                        className="form-control"
                        placeholder="Cevap..."
                        value={answer7}
                        onChange={(e) => setAnswer7(e.target.value)}
                      />
                    </div>
                  </div>
                )}

                {/* 8. Boşluk Doldur */}
                {(questionType.includes("all") ||
                  questionType.includes("fill2")) && (
                  <div className="mb-3 border p-3 rounded-3xl">
                    <p className="mb-3">
                      <span className="h4 mr-3">8. Boşluk Doldur</span>Cümle
                      içerisinde, başında ve sonunda artı işareti (+) bulunan
                      kelime doğru cevaptır. Cevapların arasını virgül işareti
                      (,) ile ayırınız.
                    </p>
                    <div className="d-flex flex-row align-items-center mb-2">
                      <label className="col-md-2" htmlFor="input-title8">
                        Başlık
                      </label>
                      <input
                        id="input-title8"
                        className="form-control"
                        placeholder="Başlık..."
                        value={title8}
                        onChange={(e) => setTitle8(e.target.value)}
                      />
                    </div>
                    <div className="d-flex flex-row align-items-center mb-2">
                      <label className="col-md-2" htmlFor="input-question8">
                        Soru
                      </label>
                      <input
                        id="input-question8"
                        className="form-control"
                        placeholder="Soru..."
                        value={question8}
                        onChange={(e) => setQuestion8(e.target.value)}
                      />
                    </div>
                    <div className="d-flex flex-row align-items-center mb-2">
                      <label className="col-md-2" htmlFor="input-answer8">
                        Cevap
                      </label>
                      <input
                        id="input-answer8"
                        className="form-control"
                        placeholder="Cevap..."
                        value={answer8}
                        onChange={(e) => setAnswer8(e.target.value)}
                      />
                    </div>
                  </div>
                )}

                {/* 9. Boşluk Doldur */}
                {(questionType.includes("all") ||
                  questionType.includes("fill3")) && (
                  <div className="mb-3 border p-3 rounded-3xl">
                    <p className="mb-3">
                      <span className="h4 mr-3">9. Boşluk Doldur</span>Cümle
                      içerisinde, başında ve sonunda artı işareti (+) bulunan
                      kelime doğru cevaptır. Cevapların arasını virgül işareti
                      (,) ile ayırınız.
                    </p>
                    <div className="d-flex flex-row align-items-center mb-2">
                      <label className="col-md-2" htmlFor="input-title9">
                        Başlık
                      </label>
                      <input
                        id="input-title9"
                        className="form-control"
                        placeholder="Başlık..."
                        value={title9}
                        onChange={(e) => setTitle9(e.target.value)}
                      />
                    </div>
                    <div className="d-flex flex-row align-items-center mb-2">
                      <label className="col-md-2" htmlFor="input-question9">
                        Soru
                      </label>
                      <input
                        id="input-question9"
                        className="form-control"
                        placeholder="Soru..."
                        value={question9}
                        onChange={(e) => setQuestion9(e.target.value)}
                      />
                    </div>
                    <div className="d-flex flex-row align-items-center mb-2">
                      <label className="col-md-2" htmlFor="input-answer9">
                        Cevap
                      </label>
                      <input
                        id="input-answer9"
                        className="form-control"
                        placeholder="Cevap..."
                        value={answer9}
                        onChange={(e) => setAnswer9(e.target.value)}
                      />
                    </div>
                  </div>
                )}

                {/* 10. Öğe Sıralama + Yanıltıcı Cevaplı */}
                {(questionType.includes("all") ||
                  questionType.includes("sortWithWrong1")) && (
                  <div className="mb-3 border p-3 rounded-3xl">
                    <p className="mb-3">
                      <span className="h4 mr-3">
                        10. Öğe Sıralama + Yanıltıcı Cevaplı
                      </span>
                      Cevapların arasını virgül işareti (,) ile ayırınız.
                    </p>
                    <div className="d-flex flex-row align-items-center mb-2">
                      <label className="col-md-2" htmlFor="input-title10">
                        Başlık
                      </label>
                      <input
                        id="input-title10"
                        className="form-control"
                        placeholder="Başlık..."
                        value={title10}
                        onChange={(e) => setTitle10(e.target.value)}
                      />
                    </div>
                    <div className="d-flex flex-row align-items-center mb-2">
                      <label className="col-md-2" htmlFor="input-question10">
                        Soru
                      </label>
                      <input
                        id="input-question10"
                        className="form-control"
                        placeholder="Soru..."
                        value={question10}
                        onChange={(e) => setQuestion10(e.target.value)}
                      />
                    </div>
                    <div className="d-flex flex-row align-items-center mb-2">
                      <label className="col-md-2" htmlFor="input-turkish">
                        Ses
                      </label>
                      <audio ref={audio10} src={voice10}></audio>
                      <CloudUploadIcon
                        className="!w-7 cursor-pointer ml-2"
                        onClick={() =>
                          document.getElementById("input-voice10").click()
                        }
                      />
                      <input
                        id="input-voice10"
                        type="file"
                        className="hidden"
                        onChange={(e) => setVoice10(e.target.files[0])}
                      />
                      {voice10 !== null && <p>&nbsp;{voice10.name}</p>}
                    </div>
                    <div className="d-flex flex-row align-items-center mb-2">
                      <label className="col-md-2" htmlFor="input-answer10">
                        Doğru Cevap
                      </label>
                      <input
                        id="input-answer10"
                        className="form-control"
                        placeholder="Cevap..."
                        value={answer10}
                        onChange={(e) => setAnswer10(e.target.value)}
                      />
                    </div>
                    <div className="d-flex flex-row align-items-center mb-2">
                      <label className="col-md-2" htmlFor="input-answer101">
                        Yanlış Cevap
                      </label>
                      <input
                        id="input-answer101"
                        className="form-control"
                        placeholder="Cevap..."
                        value={answer101}
                        onChange={(e) => setAnswer101(e.target.value)}
                      />
                    </div>
                  </div>
                )}

                {/* 11. Öğe Sıralama + Yanıltıcı Cevaplı */}
                {(questionType.includes("all") ||
                  questionType.includes("sortWithWrong2")) && (
                  <div className="mb-3 border p-3 rounded-3xl">
                    <p className="mb-3">
                      <span className="h4 mr-3">
                        11. Öğe Sıralama + Yanıltıcı Cevaplı
                      </span>
                      Cevapların arasını virgül işareti (,) ile ayırınız.
                    </p>
                    <div className="d-flex flex-row align-items-center mb-2">
                      <label className="col-md-2" htmlFor="input-title11">
                        Başlık
                      </label>
                      <input
                        id="input-title11"
                        className="form-control"
                        placeholder="Başlık..."
                        value={title11}
                        onChange={(e) => setTitle11(e.target.value)}
                      />
                    </div>
                    <div className="d-flex flex-row align-items-center mb-2">
                      <label className="col-md-2" htmlFor="input-question11">
                        Soru
                      </label>
                      <input
                        id="input-question11"
                        className="form-control"
                        placeholder="Soru..."
                        value={question11}
                        onChange={(e) => setQuestion11(e.target.value)}
                      />
                    </div>
                    <div className="d-flex flex-row align-items-center mb-2">
                      <label className="col-md-2" htmlFor="input-turkish">
                        Ses
                      </label>
                      <audio ref={audio11} src={voice11}></audio>
                      <CloudUploadIcon
                        className="!w-7 cursor-pointer ml-2"
                        onClick={() =>
                          document.getElementById("input-voice11").click()
                        }
                      />
                      <input
                        id="input-voice11"
                        type="file"
                        className="hidden"
                        onChange={(e) => setVoice11(e.target.files[0])}
                      />
                      {voice11 !== null && <p>&nbsp;{voice11.name}</p>}
                    </div>
                    <div className="d-flex flex-row align-items-center mb-2">
                      <label className="col-md-2" htmlFor="input-answer11">
                        Doğru Cevap
                      </label>
                      <input
                        id="input-answer11"
                        className="form-control"
                        placeholder="Cevap..."
                        value={answer11}
                        onChange={(e) => setAnswer11(e.target.value)}
                      />
                    </div>
                    <div className="d-flex flex-row align-items-center mb-2">
                      <label className="col-md-2" htmlFor="input-answer111">
                        Yanlış Cevap
                      </label>
                      <input
                        id="input-answer111"
                        className="form-control"
                        placeholder="Cevap..."
                        value={answer111}
                        onChange={(e) => setAnswer111(e.target.value)}
                      />
                    </div>
                  </div>
                )}

                {/* 12. Öğe Sıralama + Yanıltıcı Cevaplı */}
                {(questionType.includes("all") ||
                  questionType.includes("sortWithWrong3")) && (
                  <div className="mb-3 border p-3 rounded-3xl">
                    <p className="mb-3">
                      <span className="h4 mr-3">
                        12. Öğe Sıralama + Yanıltıcı Cevaplı
                      </span>
                      Cevapların arasını virgül işareti (,) ile ayırınız.
                    </p>
                    <div className="d-flex flex-row align-items-center mb-2">
                      <label className="col-md-2" htmlFor="input-title12">
                        Başlık
                      </label>
                      <input
                        id="input-title12"
                        className="form-control"
                        placeholder="Başlık..."
                        value={title12}
                        onChange={(e) => setTitle12(e.target.value)}
                      />
                    </div>
                    <div className="d-flex flex-row align-items-center mb-2">
                      <label className="col-md-2" htmlFor="input-question12">
                        Soru
                      </label>
                      <input
                        id="input-question12"
                        className="form-control"
                        placeholder="Soru..."
                        value={question12}
                        onChange={(e) => setQuestion12(e.target.value)}
                      />
                    </div>
                    <div className="d-flex flex-row align-items-center mb-2">
                      <label className="col-md-2" htmlFor="input-turkish">
                        Ses
                      </label>
                      <audio ref={audio12} src={voice12}></audio>
                      <CloudUploadIcon
                        className="!w-7 cursor-pointer ml-2"
                        onClick={() =>
                          document.getElementById("input-voice12").click()
                        }
                      />
                      <input
                        id="input-voice12"
                        type="file"
                        className="hidden"
                        onChange={(e) => setVoice12(e.target.files[0])}
                      />
                      {voice12 !== null && <p>&nbsp;{voice12.name}</p>}
                    </div>
                    <div className="d-flex flex-row align-items-center mb-2">
                      <label className="col-md-2" htmlFor="input-answer12">
                        Doğru Cevap
                      </label>
                      <input
                        id="input-answer12"
                        className="form-control"
                        placeholder="Cevap..."
                        value={answer12}
                        onChange={(e) => setAnswer12(e.target.value)}
                      />
                    </div>
                    <div className="d-flex flex-row align-items-center mb-2">
                      <label className="col-md-2" htmlFor="input-answer121">
                        Yanlış Cevap
                      </label>
                      <input
                        id="input-answer121"
                        className="form-control"
                        placeholder="Cevap..."
                        value={answer121}
                        onChange={(e) => setAnswer121(e.target.value)}
                      />
                    </div>
                  </div>
                )}

                {/* 13. Cümleden Kelime Seçme */}
                {(questionType.includes("all") ||
                  questionType.includes("chooseWord1")) && (
                  <div className="mb-3 border p-3 rounded-3xl">
                    <p className="mb-3">
                      <span className="h4 mr-3">13. Cümleden Kelime Seçme</span>
                      Cevapların arasını virgül işareti (,) ile ayırınız.
                    </p>
                    <div className="d-flex flex-row align-items-center mb-2">
                      <label className="col-md-2" htmlFor="input-title13">
                        Başlık
                      </label>
                      <input
                        id="input-title13"
                        className="form-control"
                        placeholder="Başlık..."
                        value={title13}
                        onChange={(e) => setTitle13(e.target.value)}
                      />
                    </div>
                    <div className="d-flex flex-row align-items-center mb-2">
                      <label className="col-md-2" htmlFor="input-question13">
                        Soru
                      </label>
                      <input
                        id="input-question13"
                        className="form-control"
                        placeholder="Soru..."
                        value={question13}
                        onChange={(e) => setQuestion13(e.target.value)}
                      />
                    </div>
                    <div className="d-flex flex-row align-items-center mb-2">
                      <label className="col-md-2" htmlFor="input-answer13">
                        Cevap
                      </label>
                      <input
                        id="input-answer13"
                        className="form-control"
                        placeholder="Cevap..."
                        value={answer13}
                        onChange={(e) => setAnswer13(e.target.value)}
                      />
                    </div>
                  </div>
                )}

                {/* 14. Cümleden Kelime Seçme */}
                {(questionType.includes("all") ||
                  questionType.includes("chooseWord2")) && (
                  <div className="mb-3 border p-3 rounded-3xl">
                    <p className="mb-3">
                      <span className="h4 mr-3">14. Cümleden Kelime Seçme</span>
                      Cevapların arasını virgül işareti (,) ile ayırınız.
                    </p>
                    <div className="d-flex flex-row align-items-center mb-2">
                      <label className="col-md-2" htmlFor="input-title14">
                        Başlık
                      </label>
                      <input
                        id="input-title14"
                        className="form-control"
                        placeholder="Başlık..."
                        value={title14}
                        onChange={(e) => setTitle14(e.target.value)}
                      />
                    </div>
                    <div className="d-flex flex-row align-items-center mb-2">
                      <label className="col-md-2" htmlFor="input-question14">
                        Soru
                      </label>
                      <input
                        id="input-question14"
                        className="form-control"
                        placeholder="Soru..."
                        value={question14}
                        onChange={(e) => setQuestion14(e.target.value)}
                      />
                    </div>
                    <div className="d-flex flex-row align-items-center mb-2">
                      <label className="col-md-2" htmlFor="input-answer14">
                        Cevap
                      </label>
                      <input
                        id="input-answer14"
                        className="form-control"
                        placeholder="Cevap..."
                        value={answer14}
                        onChange={(e) => setAnswer14(e.target.value)}
                      />
                    </div>
                  </div>
                )}

                {/* 15. Cümleden Kelime Seçme */}
                {(questionType.includes("all") ||
                  questionType.includes("chooseWord3")) && (
                  <div className="mb-3 border p-3 rounded-3xl">
                    <p className="mb-3">
                      <span className="h4 mr-3">15. Cümleden Kelime Seçme</span>
                      Cevapların arasını virgül işareti (,) ile ayırınız.
                    </p>
                    <div className="d-flex flex-row align-items-center mb-2">
                      <label className="col-md-2" htmlFor="input-title15">
                        Başlık
                      </label>
                      <input
                        id="input-title15"
                        className="form-control"
                        placeholder="Başlık..."
                        value={title15}
                        onChange={(e) => setTitle15(e.target.value)}
                      />
                    </div>
                    <div className="d-flex flex-row align-items-center mb-2">
                      <label className="col-md-2" htmlFor="input-question15">
                        Soru
                      </label>
                      <input
                        id="input-question15"
                        className="form-control"
                        placeholder="Soru..."
                        value={question15}
                        onChange={(e) => setQuestion15(e.target.value)}
                      />
                    </div>
                    <div className="d-flex flex-row align-items-center mb-2">
                      <label className="col-md-2" htmlFor="input-answer15">
                        Cevap
                      </label>
                      <input
                        id="input-answer15"
                        className="form-control"
                        placeholder="Cevap..."
                        value={answer15}
                        onChange={(e) => setAnswer15(e.target.value)}
                      />
                    </div>
                  </div>
                )}

                {/* 16. Fonetik Alfabeli Soru */}
                {(questionType.includes("all") ||
                  questionType.includes("phonetic1")) && (
                  <div className="mb-3 border p-3 rounded-3xl">
                    <p className="mb-3">
                      <span className="h4 mr-3">16. Fonetik Alfabeli Soru</span>
                      Fonetik klavyeyi açmak için sayfanın başındaki butona
                      basınız.
                    </p>
                    <div className="d-flex flex-row align-items-center mb-2">
                      <label className="col-md-2" htmlFor="input-title16">
                        Başlık
                      </label>
                      <input
                        id="input-title16"
                        className="form-control"
                        placeholder="Başlık..."
                        value={title16}
                        onChange={(e) => setTitle16(e.target.value)}
                      />
                    </div>
                    <div className="d-flex flex-row align-items-center mb-2">
                      <label className="col-md-2" htmlFor="input-question16">
                        Soru
                      </label>
                      <input
                        id="input-question16"
                        className="form-control"
                        placeholder="Soru..."
                        value={question16}
                        onChange={(e) => setQuestion16(e.target.value)}
                      />
                    </div>
                    <div className="d-flex flex-row align-items-center mb-2">
                      <label className="col-md-2" htmlFor="input-turkish">
                        Ses
                      </label>
                      <audio ref={audio16} src={voice16}></audio>
                      {/* {voice16 !== null && updateClicked && !isPlaying && (
                        <SpeakerphoneIcon
                          className="!w-7 cursor-pointer ml-2"
                          onClick={() => voicePlayedWithRef(audio16)}
                        />
                      )}
                      {voice16 !== null && updateClicked && isPlaying && (
                        <PauseIcon
                          className="!w-7 cursor-pointer ml-2"
                          onClick={() => voicePlayedWithRef(audio16)}
                        />
                      )} */}
                      <CloudUploadIcon
                        className="!w-7 cursor-pointer ml-2"
                        onClick={() =>
                          document.getElementById("input-voice16").click()
                        }
                      />
                      <input
                        id="input-voice16"
                        type="file"
                        className="hidden"
                        onChange={(e) => setVoice16(e.target.files[0])}
                      />
                      {voice16 !== null && <p>&nbsp;{voice16.name}</p>}
                    </div>
                    <div className="d-flex flex-row align-items-center mb-2">
                      <label className="col-md-2" htmlFor="input-answer16">
                        Cevap - 1
                      </label>
                      <input
                        id="input-answer16"
                        className="form-control"
                        placeholder="Cevap..."
                        value={answer16}
                        onChange={(e) => setAnswer16(e.target.value)}
                      />
                    </div>
                    <div className="d-flex flex-row align-items-center mb-2">
                      <label className="col-md-2" htmlFor="input-answer161">
                        Cevap - 2
                      </label>
                      <input
                        id="input-answer161"
                        className="form-control"
                        placeholder="Cevap..."
                        value={answer161}
                        onChange={(e) => setAnswer161(e.target.value)}
                      />
                    </div>
                    <div className="d-flex flex-row align-items-center mb-2">
                      <label className="col-md-2" htmlFor="input-answer162">
                        Cevap - 3
                      </label>
                      <input
                        id="input-answer162"
                        className="form-control"
                        placeholder="Cevap..."
                        value={answer162}
                        onChange={(e) => setAnswer162(e.target.value)}
                      />
                    </div>
                    <div className="d-flex flex-row align-items-center mb-2">
                      <label className="col-md-2" htmlFor="input-answer163">
                        Cevap - 4
                      </label>
                      <input
                        id="input-answer163"
                        className="form-control"
                        placeholder="Cevap..."
                        value={answer163}
                        onChange={(e) => setAnswer163(e.target.value)}
                      />
                    </div>
                  </div>
                )}

                {/* 17. Fonetik Alfabeli Soru */}
                {(questionType.includes("all") ||
                  questionType.includes("phonetic2")) && (
                  <div className="mb-3 border p-3 rounded-3xl">
                    <p className="mb-3">
                      <span className="h4 mr-3">17. Fonetik Alfabeli Soru</span>
                      Fonetik klavyeyi açmak için sayfanın başındaki butona
                      basınız.
                    </p>
                    <div className="d-flex flex-row align-items-center mb-2">
                      <label className="col-md-2" htmlFor="input-title17">
                        Başlık
                      </label>
                      <input
                        id="input-title17"
                        className="form-control"
                        placeholder="Başlık..."
                        value={title17}
                        onChange={(e) => setTitle17(e.target.value)}
                      />
                    </div>
                    <div className="d-flex flex-row align-items-center mb-2">
                      <label className="col-md-2" htmlFor="input-question17">
                        Soru
                      </label>
                      <input
                        id="input-question17"
                        className="form-control"
                        placeholder="Soru..."
                        value={question17}
                        onChange={(e) => setQuestion17(e.target.value)}
                      />
                    </div>
                    <div className="d-flex flex-row align-items-center mb-2">
                      <label className="col-md-2" htmlFor="input-turkish">
                        Ses
                      </label>
                      <audio ref={audio17} src={voice17}></audio>
                      {/* {voice17 !== null && updateClicked && !isPlaying && (
                        <SpeakerphoneIcon
                          className="!w-7 cursor-pointer ml-2"
                          onClick={() => voicePlayedWithRef(audio17)}
                        />
                      )}
                      {voice17 !== null && updateClicked && isPlaying && (
                        <PauseIcon
                          className="!w-7 cursor-pointer ml-2"
                          onClick={() => voicePlayedWithRef(audio17)}
                        />
                      )} */}
                      <CloudUploadIcon
                        className="!w-7 cursor-pointer ml-2"
                        onClick={() =>
                          document.getElementById("input-voice17").click()
                        }
                      />
                      <input
                        id="input-voice17"
                        type="file"
                        className="hidden"
                        onChange={(e) => setVoice17(e.target.files[0])}
                      />
                      {voice17 !== null && <p>&nbsp;{voice17.name}</p>}
                    </div>
                    <div className="d-flex flex-row align-items-center mb-2">
                      <label className="col-md-2" htmlFor="input-answer17">
                        Cevap - 1
                      </label>
                      <input
                        id="input-answer17"
                        className="form-control"
                        placeholder="Cevap..."
                        value={answer17}
                        onChange={(e) => setAnswer17(e.target.value)}
                      />
                    </div>
                    <div className="d-flex flex-row align-items-center mb-2">
                      <label className="col-md-2" htmlFor="input-answer171">
                        Cevap - 2
                      </label>
                      <input
                        id="input-answer171"
                        className="form-control"
                        placeholder="Cevap..."
                        value={answer171 || ""}
                        onChange={(e) => setAnswer171(e.target.value)}
                      />
                    </div>
                    <div className="d-flex flex-row align-items-center mb-2">
                      <label className="col-md-2" htmlFor="input-answer172">
                        Cevap - 3
                      </label>
                      <input
                        id="input-answer172"
                        className="form-control"
                        placeholder="Cevap..."
                        value={answer172 || ""}
                        onChange={(e) => setAnswer172(e.target.value)}
                      />
                    </div>
                    <div className="d-flex flex-row align-items-center mb-2">
                      <label className="col-md-2" htmlFor="input-answer173">
                        Cevap - 4
                      </label>
                      <input
                        id="input-answer173"
                        className="form-control"
                        placeholder="Cevap..."
                        value={answer173 || ""}
                        onChange={(e) => setAnswer173(e.target.value)}
                      />
                    </div>
                  </div>
                )}

                {/* 18. Fonetik Alfabeli Soru */}
                {(questionType.includes("all") ||
                  questionType.includes("phonetic3")) && (
                  <div className="mb-3 border p-3 rounded-3xl">
                    <p className="mb-3">
                      <span className="h4 mr-3">18. Fonetik Alfabeli Soru</span>
                      Fonetik klavyeyi açmak için sayfanın başındaki butona
                      basınız.
                    </p>
                    <div className="d-flex flex-row align-items-center mb-2">
                      <label className="col-md-2" htmlFor="input-title18">
                        Başlık
                      </label>
                      <input
                        id="input-title18"
                        className="form-control"
                        placeholder="Başlık..."
                        value={title18}
                        onChange={(e) => setTitle18(e.target.value)}
                      />
                    </div>
                    <div className="d-flex flex-row align-items-center mb-2">
                      <label className="col-md-2" htmlFor="input-question18">
                        Soru
                      </label>
                      <input
                        id="input-question18"
                        className="form-control"
                        placeholder="Soru..."
                        value={question18}
                        onChange={(e) => setQuestion18(e.target.value)}
                      />
                    </div>
                    <div className="d-flex flex-row align-items-center mb-2">
                      <label className="col-md-2" htmlFor="input-turkish">
                        Ses
                      </label>
                      <audio ref={audio18} src={voice18}></audio>
                      {/* {voice18 !== null && updateClicked && !isPlaying && (
                        <SpeakerphoneIcon
                          className="!w-7 cursor-pointer ml-2"
                          onClick={() => voicePlayedWithRef(audio18)}
                        />
                      )}
                      {voice18 !== null && updateClicked && isPlaying && (
                        <PauseIcon
                          className="!w-7 cursor-pointer ml-2"
                          onClick={() => voicePlayedWithRef(audio18)}
                        />
                      )} */}
                      <CloudUploadIcon
                        className="!w-7 cursor-pointer ml-2"
                        onClick={() =>
                          document.getElementById("input-voice18").click()
                        }
                      />
                      <input
                        id="input-voice18"
                        type="file"
                        className="hidden"
                        onChange={(e) => setVoice18(e.target.files[0])}
                      />
                      {voice18 !== null && <p>&nbsp;{voice18.name}</p>}
                    </div>
                    <div className="d-flex flex-row align-items-center mb-2">
                      <label className="col-md-2" htmlFor="input-answer18">
                        Cevap - 1
                      </label>
                      <input
                        id="input-answer18"
                        className="form-control"
                        placeholder="Cevap..."
                        value={answer18}
                        onChange={(e) => setAnswer18(e.target.value)}
                      />
                    </div>
                    <div className="d-flex flex-row align-items-center mb-2">
                      <label className="col-md-2" htmlFor="input-answer181">
                        Cevap - 2
                      </label>
                      <input
                        id="input-answer181"
                        className="form-control"
                        placeholder="Cevap..."
                        value={answer181 || ""}
                        onChange={(e) => setAnswer181(e.target.value)}
                      />
                    </div>
                    <div className="d-flex flex-row align-items-center mb-2">
                      <label className="col-md-2" htmlFor="input-answer182">
                        Cevap - 3
                      </label>
                      <input
                        id="input-answer182"
                        className="form-control"
                        placeholder="Cevap..."
                        value={answer182 || ""}
                        onChange={(e) => setAnswer182(e.target.value)}
                      />
                    </div>
                    <div className="d-flex flex-row align-items-center mb-2">
                      <label className="col-md-2" htmlFor="input-answer183">
                        Cevap - 4
                      </label>
                      <input
                        id="input-answer183"
                        className="form-control"
                        placeholder="Cevap..."
                        value={answer183 || ""}
                        onChange={(e) => setAnswer183(e.target.value)}
                      />
                    </div>
                  </div>
                )}

                {/* Soru Tipi Ekle */}
                {!updateClicked && (
                  <div className="d-flex align-items-center my-5">
                    <Select
                      className="col-sm-11"
                      onChange={(e) => setOptionQuestionTypeValue(e.value)}
                      options={optionsQuestionType}
                      placeholder={"Soru tipi ekleyiniz..."}
                    />
                    <PlusSmIcon
                      className="w-8 h-8 cursor-pointer"
                      onClick={() =>
                        setQuestionType(questionType + optionQuestionTypeValue)
                      }
                    />
                  </div>
                )}

                {/* Soruları Veri Tabanına Ekle */}
                <button
                  type="button"
                  className="btn bg-green-700 hover:bg-green-800 text-white btn-block col-md-3 ml-0"
                  onClick={() => {
                    updateClicked ? updateTest() : addClickHandler();
                  }}
                >
                  {updateClicked ? "Güncelle" : "Ekle"}
                </button>
                <button
                  type="button"
                  className="btn bg-sky-700 hover:bg-sky-800 text-white btn-block col-md-3 ml-0"
                  onClick={() => {
                    setIsPhoneticHidden(!isPhoneticHidden);
                  }}
                >
                  {isPhoneticHidden
                    ? "Fonetik Klavyeyi Aç"
                    : "Fonetik Klavyeyi Kapat"}
                </button>
                {!isPhoneticHidden && <Phonetic />}
              </div>
              {/* /.card-body */}
            </div>
            {/* /.card */}
          </form>
        </div>
      </div>

      {/* Soru Listesi */}
      <div className="card card-info">
        <div className="card-header">
          <h3 className="card-title">Soru Listesi</h3>
        </div>
        {/* /.card-header */}
        {/* form start */}
        <div className="card-body row">
          <form className="col-md-12">
            <div className="card">
              <div className="card-header row">
                <div>
                  Soru listesindeki detayları burada düzenleyebilirsiniz.
                </div>
                <div className="col-md-4 ml-auto flex flex-wrap items-center justify-end">
                  {/* <Select
                    defaultValue={getAdminLevel}
                    onChange={(e) => setGetAdminLevel(e.value)}
                    options={Var.optionsLevel}
                    placeholder={"Seviye"}
                  /> */}
                  <Select
                    defaultValue={getAdminWeek}
                    onChange={(e) => setGetAdminWeek(e.value)}
                    options={Var.optionsWeek}
                    placeholder={"Hafta"}
                  />
                  <button
                    type="button"
                    className="btn bg-sky-700 hover:bg-sky-800 text-white w-40 h-10 m-1"
                    onClick={() => GetAdminForWeek()}
                  >
                    Soruları Getir
                  </button>
                </div>
                <div className="col-md-4 ml-auto flex flex-wrap items-center justify-end">
                  {!updateClicked && (
                    <button
                      type="button"
                      className="btn bg-green-700 hover:bg-green-800 text-white w-40 h-10 m-1"
                      onClick={() => updateClickHandler()}
                    >
                      Güncelle
                    </button>
                  )}
                  {updateClicked && (
                    <button
                      type="button"
                      className="btn bg-yellow-500 hover:bg-yellow-600 text-white w-40 h-10 m-1"
                      onClick={() => setUpdateClicked(false)}
                    >
                      Vazgeç
                    </button>
                  )}
                  <button
                    type="button"
                    className="btn bg-red-700 hover:bg-red-800 text-white w-40 h-10 m-1"
                    onClick={() => deleteClickHandler()}
                  >
                    Sil
                  </button>
                </div>
              </div>
              {/* /.card-header */}
              <div className="card-body">
                {dataReady && (
                  <MDBDataTableV5
                    hover
                    entriesOptions={[5, 20, 25]}
                    entries={5}
                    pagesAmount={4}
                    data={datatable}
                    // scrollX
                    checkbox="true"
                    searchTop
                    searchBottom={false}
                    headCheckboxID="id2"
                    bodyCheckboxID="checkboxes2"
                    getValueCheckBox={(e) => {
                      checkbox === ""
                        ? setCheckbox(e)
                        : checkbox.id === e.id
                        ? setCheckbox("")
                        : setCheckbox(e);
                    }}
                  />
                )}
              </div>
              {/* /.card-body */}
            </div>
            {/* /.card */}
          </form>
        </div>
      </div>
    </div>
  );
};

export default QuestionBank;
