import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";

const Header = () => {
  const [cookie, setCookie, removeCookie] = useCookies();

  const logout = () => {
    removeCookie("admin_token");
    removeCookie("admin_user");
    window.location.reload();
  };

  return (
    <div>
      <nav className="main-header navbar navbar-expand navbar-white navbar-light">
        <ul className="navbar-nav">
          <li className="nav-item">
            <a
              className="nav-link"
              data-widget="pushmenu"
              href="#"
              role="button"
            >
              <i className="fas fa-bars" />
            </a>
          </li>

          <li className="nav-item d-none d-sm-inline-block">
            <Link to="/" className="nav-link">
              Genel
            </Link>
          </li>
          <li className="nav-item d-none d-sm-inline-block">
            <Link to="/bize-ulasin" className="nav-link">
              Bize Ulaşın
            </Link>
          </li>
        </ul>

        <ul className="navbar-nav ml-auto">
          <li className="nav-item dropdown">
            <a className="nav-link" data-toggle="dropdown" href="#">
              <i className="far fa-comments" />
              <span className="badge badge-danger navbar-badge">1</span>
            </a>
            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-override">
              <a href="#" className="dropdown-item">
                <div className="media">
                  <img
                    src="dist/img/user1-128x128.jpg"
                    alt="User Avatar"
                    className="img-size-50 mr-3 img-circle"
                  />
                  <div className="media-body">
                    <h3 className="dropdown-item-title">
                      Öğrenci
                      <span className="float-right text-sm text-danger">
                        <i className="fas fa-star" />
                      </span>
                    </h3>
                    <p className="text-sm">Sorum Var!</p>
                    <p className="text-sm text-muted">
                      <i className="far fa-clock mr-1" /> 4 Saat Önce
                    </p>
                  </div>
                </div>
              </a>
              <div className="dropdown-divider" />
              <a href="#" className="dropdown-item dropdown-footer">
                Tüm Soruları Gör
              </a>
            </div>
          </li>
          <li className="nav-item dropdown">
            <a className="nav-link" data-toggle="dropdown" href="#">
              <i className="far fa-bell" />
              <span className="badge badge-warning navbar-badge">3</span>
            </a>
            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-override">
              <span className="dropdown-header">3 Bildirim</span>
              <div className="dropdown-divider" />
              <a href="#" className="dropdown-item">
                <i className="fas fa-envelope mr-2" /> 1 yeni soru
                <span className="float-right text-muted text-sm">
                  4 saat önce
                </span>
              </a>
              <div className="dropdown-divider" />
              <a href="#" className="dropdown-item">
                <i className="fas fa-users mr-2" /> 2 yeni kayıt
                <span className="float-right text-muted text-sm">
                  12 dakika önce
                </span>
              </a>
              <div className="dropdown-divider" />
              <a href="#" className="dropdown-item">
                <i className="fas fa-file mr-2" /> 1 teknik problem
                <span className="float-right text-muted text-sm">
                  15 saat önce
                </span>
              </a>
              <div className="dropdown-divider" />
              <a href="#" className="dropdown-item dropdown-footer">
                Tüm Bildirimleri Gör
              </a>
            </div>
          </li>
          <li className="nav-item dropdown">
            <a className="nav-link" data-toggle="dropdown" href="#">
              <i className="far fa-user" />
            </a>
            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-override">
              <div className="dropdown-divider" />
              <a href="#" className="dropdown-item dropdown-footer" onClick={logout}>
                Çıkış Yap
              </a>
            </div>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              data-widget="fullscreen"
              href="#"
              role="button"
            >
              <i className="fas fa-expand-arrows-alt" />
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Header;
