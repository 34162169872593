import React from 'react'
import Var from './variables'
import { useCookies } from 'react-cookie'
import { useState, useEffect } from 'react'

const CreateBills = () => {

  const cookies = useCookies();

  const [changingClasses, setChangingClasses] = useState([])

  const getChangingClasses = () => {
    fetch(`${Var.api_url}/changingClass` ,{
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookies.admin_token}`,
      },
    }).then(res => {
      console.log(res);
    }).catch(err => {
      console.log(err);
    })
  }

  useEffect(() => {
    getChangingClasses()
  } ,[])

  return (
    <div>a</div>
  )
}

export default CreateBills