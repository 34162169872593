import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import Var from "./variables";
import { useCookies } from "react-cookie";
import "./Login.css";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [token, setToken] = useState("");
  const [message, setMessage] = useState("");
  const [cookies, setCookie, removeCookie] = useCookies();
  const navigate = useNavigate();

  const handleLogin = (e) => {
    e.preventDefault();

    fetch(`${Var.api_url}/auth/login/`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email, password, isAdmin: 1 }),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.token !== undefined) {
          setToken(data.token);
          setCookie("admin_token", data.token, { path: "/" });
        }
        setMessage(data.message);
      });
  };

  useEffect(() => {
    if (token !== "") {
      fetch(`${Var.api_url}/user/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          if (!res.ok) {
            setMessage(res.message);
          }
          return res.json();
        })
        .then((data) => {
          setCookie("admin_user", data, { path: "/" });
          navigate("/");
        });
    }
  }, [token]);

  return (
    <div className="h-screen flex items-center justify-center flex-col">
      {cookies.admin_user !== undefined && navigate("/")}
      {cookies.admin_user === undefined && (
        <>
          <div className="w-44 h-44 flex items-center mb-4">
            <a href={`${Var.site_url}`}>
              <img
                className="bg-contain "
                src={require("./assets/img/IDLAC_logo_circle.png")}
                alt="Logo"
              />
            </a>
          </div>
          <form className="inputs space-y-4 mb-6">
            <div className="bg-slate-50 w-96 h-16 rounded-lg border-2 border-slate-100 flex flex-col py-1 px-3">
              <label
                className="text-xs text-slate-400 mb-1"
                htmlFor="login-email"
              >
                Email
              </label>
              <input
                className="bg-slate-50 focus:outline-0"
                id="login-email"
                type="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="bg-slate-50 w-96 h-16 rounded-lg border-2 border-slate-100 flex flex-col py-1 px-3">
              <label
                className="text-xs text-slate-400 mb-1"
                htmlFor="login-password"
              >
                Şifre
              </label>
              <input
                className="bg-slate-50 focus:outline-0"
                id="login-password"
                type="password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <p className="text-red-500 max-w-sm">{message}</p>
            <button
              onClick={handleLogin}
              className="bg-blue-500 w-96 h-16 rounded-full text-center text-white font-semibold"
            >
              Giriş Yap
            </button>
            {/* <div className="flex flex-row w-96 justify-between items-center">
              <hr className="w-1/3" />
              <p className="text-slate-400 text-xs my-3">
                veya ile giriş yapın
              </p>
              <hr className="w-1/3" />
            </div> */}
            {/* <button className="bg-pink-50 w-96 h-14 rounded-full text-black flex flex-row justify-center items-center text-sm">
              <img
                className="mr-6 -ml-16 h-8 w-8"
                src={require("../assets/img/google.png")}
                alt="Logo"
              />{" "}
              Google ile Giriş Yap
            </button> */}
            <div className="flex flex-row justify-between p-8 pt-16">
              <p className="text-slate-500 text-sm">
                Hesabınız yok mu?{" "}
                <Link to={"/kaydol"} className="text-red-500">
                  Üye Ol
                </Link>
              </p>
              <p className="text-slate-500 text-sm">
                Şifremi{" "}
                <Link to={`/sifremi-unuttum`} className="text-red-500">
                  Unuttum!
                </Link>
              </p>
            </div>
          </form>
        </>
      )}
    </div>
  );
};

export default Login;
