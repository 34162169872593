import { useState, useEffect } from "react";
import Swal from "sweetalert2";
import Var from "./variables";
import { useCookies } from "react-cookie";
import { MDBDataTableV5 } from "mdbreact";
import Select from "react-select";
import JoditEditor from "jodit-react";
import Moment from "moment";

const PastLiveVideoPage = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [classId, setClassId] = useState("");
  const [link, setLink] = useState("");
  const [PastLiveVideoID, setPastLiveVideoID] = useState("");

  const [updateClicked, setUpdateClicked] = useState(false);

  const [classes, setClasses] = useState([]);
  const [classesDefault, setClassesDefault] = useState([]);
  const [classesReady, setClassesReady] = useState(false);
  const [dataReady, setDataReady] = useState(false);
  const [cookies] = useCookies();

  const config = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
  };

  const [datatable, setDatatable] = useState({
    columns: [
      {
        label: "Başlık",
        field: "title",
      },
      {
        label: "Açıklama",
        field: "description",
      },
      // {
      //   label: "Sınıf",
      //   field: "classId",
      // },
      {
        label: "Sınıflar",
        field: "className",
      },
      {
        label: "Link",
        field: "link",
      },
    ],
    rows: [],
  });
  const [checkbox, setCheckbox] = useState("");

  const updatePastLiveVideo = () => {
    if (title !== "" && description !== "" && classId !== "" && link !== "") {
      fetch(`${Var.api_url}/pastLiveVideo/${PastLiveVideoID}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${cookies.admin_token}`,
        },
        body: JSON.stringify({ title, description, classId, link }),
      })
        .then((res) => {
          if (!res.ok) {
            Toast.fire({
              icon: "error",
              title: "Bir hata oluştu",
            });
          }
          return res.json();
        })
        .then((data) => {
          Toast.fire({
            icon: "success",
            title: data.message,
          });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        });
    } else {
      alert("Tüm boşlukları doğru bir şekilde doldurunuz");
    }
  };

  const updateClickHandler = () => {
    if (checkbox !== "") {
      setUpdateClicked(true);

      setTitle(checkbox.title);
      setDescription(checkbox.description);
      setClassId(checkbox.classId);
      setLink(checkbox.link);
      setPastLiveVideoID(checkbox.id);
    } else {
      Toast.fire({
        icon: "error",
        title: "Önce seçim yapınız!",
      });
    }
  };

  const deleteClickHandler = () => {
    if (checkbox !== "") {
      if (
        window.confirm(
          checkbox.title +
            " geçmiş canlı yayınını silmek istedğinize emin misiniz?"
        )
      ) {
        fetch(`${Var.api_url}/pastLiveVideo/${checkbox.id}`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookies.admin_token}`,
          },
        })
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            Toast.fire({
              icon: "success",
              title: data.message,
            });
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else {
      Toast.fire({
        icon: "error",
        title: "Önce seçim yapınız!",
      });
    }
  };

  var Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 5000,
  });

  const addClickHandler = () => {
    if (title !== "" && description !== "" && classId !== "" && link !== "") {
      fetch(`${Var.api_url}/pastLiveVideo/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${cookies.admin_token}`,
        },
        body: JSON.stringify({
          title,
          description,
          classId: classId.slice(1),
          link,
        }),
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          Toast.fire({
            icon: "success",
            title: data.message,
          });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch((err) => alert(err));
    } else {
      alert("Tüm boşlukları doğru bir şekilde doldurunuz");
    }
  };

  useEffect(() => {
    fetch(`${Var.api_url}/pastLiveVideo/admin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookies.admin_token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        for (let i = 0; i < data.length; i++) {
          data[i].className = "";
        }
        if (classesReady) {
          for (let i = 0; i < data.length; i++) {
            for (let j = 0; j < classesDefault.length; j++) {
              if (
                data[i].classId
                  .split(",")
                  .includes(classesDefault[j].id.toString())
              ) {
                data[i].className += classesDefault[j].title;
                data[i].className += "\n";
              }
            }
          }
        }
        setDatatable({ ...datatable, rows: data });
      })
      .then(() => {
        setTimeout(() => {
          setDataReady(true);
        }, 5000);
      })
      .catch((err) => console.log(err));
  }, [classesReady]);

  useEffect(() => {
    if (datatable.length !== 0) {
      fetch(`${Var.api_url}/class/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${cookies.admin_token}`,
        },
      }) 
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          setClassesDefault(data);
          setClassesCLickHandler(data);
        })
        .catch((err) => console.log(err));
    }
  }, [datatable]);

  useEffect(() => {
    setTimeout(() => {
      setClassesReady(false);
      setClassesReady(true);
    }, 3000);
  }, []);

  const setClassesCLickHandler = (datas) => {
    let tempClasses = [];
    datas.map((data, i) => {
      tempClasses.push({
        value: data.id,
        label: data.title,
      });
    });
    setClasses(tempClasses);
  };

  return (
    <div>
      <div className="card card-info">
        <div className="card-header">
          <h3 className="card-title">Geçmiş Canlı Yayın Ekleme</h3>
        </div>
        {/* /.card-header */}
        {/* form start */}
        <div className="card-body row">
          <form className="col-md-12">
            <div className="card">
              <div className="card-header row">
                <div>
                  Geçmiş canlı yayın listesinde gözükmesini istediklerinizi
                  aşağıya ekleyebilirsiniz.
                </div>
              </div>
              {/* /.card-header */}
              <div className="card-body">
                <label>
                  Başlık <label className="text-danger">*</label>
                </label>
                <input
                  className="form-control"
                  placeholder="Başlık..."
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
                <br />
                <label>
                  Açıklama <label className="text-danger">*</label>
                </label>
                <JoditEditor
                  value={description}
                  config={config}
                  tabIndex={1} // tabIndex of textarea
                  onBlur={(newContent) => setDescription(newContent)} // preferred to use only this option to update the content for performance reasons
                />
                <br />
                <label>
                  Linki <label className="text-danger">*</label>
                </label>
                <input
                  className="form-control"
                  placeholder="Link..."
                  value={link}
                  onChange={(e) => setLink(e.target.value)}
                />
                <br />
                <div id="classes" className="flex flex-row space-x-2 pb-4">
                  {classId
                    .split(",")
                    .slice(1)
                    .map((item, index) => (
                      <div
                        key={index}
                        className="bg-gray-200 p-2 w-28 text-center text-xs cursor-pointer rounded-full border-2 border-gray-300 hover:bg-red-600 duration-300"
                        onClick={() => {
                          let tempClassId = classId.split(",");
                          tempClassId.splice(index + 1, 1);
                          setClassId(tempClassId.join(","));
                        }}
                      >
                        {classesDefault && classesDefault.length !== 0 ? ( 
                          classesDefault.map((item2, index2) => {
                            if (item2.id === parseInt(item)) {
                              return item2.title;
                            }
                          })
                        ) : (
                          <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                        )}
                      </div>
                    ))}
                </div>
                <Select
                  className="w-60"
                  defaultValue={classId}
                  onChange={(e) => setClassId(classId + "," + e.value)}
                  options={classes}
                  placeholder={"Sınıf"}
                />
                <br />
                <br />
                <button
                  type="button"
                  className="btn bg-green-700 hover:bg-green-800 text-white btn-block col-md-2 ml-0"
                  onClick={() => {
                    updateClicked ? updatePastLiveVideo() : addClickHandler();
                  }}
                >
                  {updateClicked ? "Güncelle" : "Ekle"}
                </button>
              </div>
              {/* /.card-body */}
            </div>
            {/* /.card */}
          </form>
        </div>
      </div>

      {/* Geçmiş Canlı Yayın Listesi */}
      <div className="card card-info">
        <div className="card-header">
          <h3 className="card-title">Geçmiş Canlı Yayın Listesi</h3>
        </div>
        {/* /.card-header */}
        {/* form start */}
        <div className="card-body row">
          <form className="col-md-12">
            <div className="card">
              <div className="card-header row">
                <div>
                  Geçmiş canlı yayın listesindeki detayları burada
                  düzenleyebilirsiniz.
                </div>
                <div className="col-md-4 ml-auto flex flex-wrap items-center justify-end">
                  {!updateClicked && (
                    <button
                      type="button"
                      className="btn bg-green-700 hover:bg-green-800 text-white w-32 h-10 m-1"
                      onClick={() => updateClickHandler()}
                    >
                      Güncelle
                    </button>
                  )}
                  {updateClicked && (
                    <button
                      type="button"
                      className="btn bg-yellow-500 hover:bg-yellow-600 text-white w-40 h-10 m-1"
                      onClick={() => setUpdateClicked(false)}
                    >
                      Vazgeç
                    </button>
                  )}
                  <button
                    type="button"
                    className="btn bg-red-700 hover:bg-red-800 text-white w-32 h-10 m-1"
                    onClick={() => deleteClickHandler()}
                  >
                    Sil
                  </button>
                </div>
              </div>
              {/* /.card-header */}
              <div className="card-body">
                {dataReady && (
                  // checkbox is disappearing 
                  // when i use MDBDataTableV5
                  <MDBDataTableV5
                    hover
                    entriesOptions={[5, 20, 25]}
                    entries={5}
                    pagesAmount={4}
                    data={datatable}
                    checkbox="true"
                    searchTop
                    searchBottom={false}
                    headCheckboxID="id2"
                    bodyCheckboxID="checkboxes2"
                    getValueCheckBox={(e) => {
                      checkbox === ""
                        ? setCheckbox(e)
                        : checkbox.id === e.id
                        ? setCheckbox("")
                        : setCheckbox(e);
                    }}
                  />
                )}
              </div>
              {/* /.card-body */}
            </div>
            {/* /.card */}
          </form>
        </div>
      </div>
    </div>
  );
};

export default PastLiveVideoPage;
