import { useState, useEffect, useRef } from "react";
import Swal from "sweetalert2";
import Var from "./variables";
import { useCookies } from "react-cookie";
import { MDBDataTableV5 } from "mdbreact";
import Select from "react-select";
import Moment from "moment";
import {
  PlayIcon,
  PauseIcon,
  SpeakerphoneIcon,
  CloudUploadIcon,
} from "@heroicons/react/solid";

const DictionaryPage = () => {
  const [english, setEnglish] = useState("");
  const [turkish, setTurkish] = useState("");
  const [englishVoice, setEnglishVoice] = useState("");
  const [turkishVoice, setTurkishVoice] = useState("");
  const [englishSize, setEnglishSize] = useState("");
  const [turkishSize, setTurkishSize] = useState("");
  const [type, setType] = useState("");
  const [wordID, setWordID] = useState("");
  const [isPlayingEnglish, setIsPlayingEnglish] = useState(false);
  const [isPlayingTurkish, setIsPlayingTurkish] = useState(false);

  const isPlayingVoice = [];
  const audioTurkish = useRef(null);
  const audioEnglish = useRef(null);

  const [updateClicked, setUpdateClicked] = useState(false);

  const [dataReady, setDataReady] = useState(false);
  const [cookies] = useCookies();

  const config = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
  };

  const [tempData, setTempData] = useState([]);
  const [datatable, setDatatable] = useState({
    columns: [
      {
        label: "İngilizcesi",
        field: "english",
      },
      {
        label: "Türkçesi",
        field: "turkish",
      },
      {
        label: "İngilizce Ses",
        field: "englishLink",
      },
      {
        label: "Türkçe Ses",
        field: "turkishLink",
      },
      {
        label: "Tipi",
        field: "type",
      },
      {
        label: "Tarih",
        field: "date",
      },
    ],
    rows: [],
  });
  const [checkbox, setCheckbox] = useState("");

  const deleteClickHandler = () => {
    if (checkbox !== "") {
      if (
        window.confirm(
          checkbox.title + " içeriğini silmek istedğinize emin misiniz?"
        )
      ) {
        fetch(`${Var.api_url}/word/${checkbox.id}`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookies.admin_token}`,
          },
        })
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            Toast.fire({
              icon: "success",
              title: data.message,
            });
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          })
          .catch((err) => console.log(err));
      }
    } else {
      Toast.fire({
        icon: "error",
        title: "Önce seçim yapınız!",
      });
    }
  };

  var Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 5000,
  });

  const addClickHandler = () => {
    const formData = new FormData();
    if (english !== "" && turkish !== "" && type !== "") {
      if (englishVoice !== "" && turkishVoice === "") {
        formData.append("voiceType", "english");
        formData.append("voice", englishVoice);
      } else if (englishVoice === "" && turkishVoice !== "") {
        formData.append("voiceType", "turkish");
        formData.append("voice", turkishVoice);
      } else if (englishVoice !== "" && turkishVoice !== "") {
        formData.append("voice", englishVoice);
        formData.append("voice", turkishVoice);
      }

      formData.append("english", english);
      formData.append("turkish", turkish);
      formData.append("type", type);

      fetch(`${Var.api_url}/word/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${cookies.admin_token}`,
        },
        body: formData,
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          Toast.fire({
            icon: "success",
            title: data.message,
          });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch((err) => alert(err));
    } else {
      alert("Tüm boşlukları doğru bir şekilde doldurunuz");
    }
  };

  useEffect(() => {
    fetch(`${Var.api_url}/word/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookies.admin_token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => setTempData(data))
      .then(() => setDataReady(true))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (tempData.length !== 0) {
      let tempArray = [...tempData];
      tempData.map((data, index) => {
        isPlayingVoice.push(false);
        if (tempArray[index].englishVoice !== null) {
          let audioLink1 = new Audio(tempArray[index].englishVoice);
          tempArray[index].englishLink = (
            <>
              <PlayIcon
                id={"PlayIconEnglish-" + index}
                className="!w-7 cursor-pointer"
                onClick={() => voicePlayed(audioLink1, index, "english")}
              />
              <PauseIcon
                id={"PauseIconEnglish-" + index}
                className="!w-7 cursor-pointer hidden"
                onClick={() => voicePlayed(audioLink1, index, "english")}
              />
            </>
          );
        }
        if (tempArray[index].turkishVoice !== null) {
          let audioLink4 = new Audio(tempArray[index].turkishVoice);
          tempArray[index].turkishLink = (
            <>
              <PlayIcon
                id={"PlayIconTurkish-" + index}
                className="!w-7 cursor-pointer"
                onClick={() => voicePlayed(audioLink4, index, "turkish")}
              />
              <PauseIcon
                id={"PauseIconTurkish-" + index}
                className="!w-7 cursor-pointer hidden"
                onClick={() => voicePlayed(audioLink4, index, "turkish")}
              />
            </>
          );
        }
        tempArray[index].date = Moment(tempArray[index].date).format(
          "DD/MM/YYYY HH:mm:ss"
        );
      });
      setDatatable({ ...datatable, rows: tempArray });
      setDataReady(true);
    }
  }, [tempData]);

  const voicePlayed = (audioLink, index, language) => {
    if (language === "english") {
      if (!isPlayingVoice[index]) {
        audioLink.play();
        isPlayingVoice[index] = true;
        document
          .getElementById(`PlayIconEnglish-${index}`)
          .classList.add("hidden");
        document
          .getElementById(`PauseIconEnglish-${index}`)
          .classList.remove("hidden");
      } else if (isPlayingVoice[index]) {
        audioLink.pause();
        isPlayingVoice[index] = false;
        document
          .getElementById(`PlayIconEnglish-${index}`)
          .classList.remove("hidden");
        document
          .getElementById(`PauseIconEnglish-${index}`)
          .classList.add("hidden");
      }
    } else if (language === "turkish") {
      if (!isPlayingVoice[index]) {
        audioLink.play();
        isPlayingVoice[index] = true;
        document
          .getElementById(`PlayIconTurkish-${index}`)
          .classList.add("hidden");
        document
          .getElementById(`PauseIconTurkish-${index}`)
          .classList.remove("hidden");
      } else if (isPlayingVoice[index]) {
        audioLink.pause();
        isPlayingVoice[index] = false;
        document
          .getElementById(`PlayIconTurkish-${index}`)
          .classList.remove("hidden");
        document
          .getElementById(`PauseIconTurkish-${index}`)
          .classList.add("hidden");
      }
    }
  };

  const voicePlayedWithRef = (ref, language) => {
    if (language === "turkish" && !isPlayingTurkish) {
      ref.current.play();
      setIsPlayingTurkish(true);
    } else if (language === "turkish" && isPlayingTurkish) {
      ref.current.pause();
      setIsPlayingTurkish(false);
    } else if (language === "english" && !isPlayingEnglish) {
      ref.current.play();
      setIsPlayingEnglish(true);
    } else if (language === "english" && isPlayingEnglish) {
      ref.current.pause();
      setIsPlayingEnglish(false);
    }
  };

  const updateWord = () => {
    const formDataUpdate = new FormData();

    if (english !== "" && turkish !== "" && type !== "") {
      if (englishVoice !== "" && turkishVoice === "") {
        formDataUpdate.append("voiceType", "english");
        formDataUpdate.append("voice", englishVoice);
      } else if (englishVoice === "" && turkishVoice !== "") {
        formDataUpdate.append("voiceType", "turkish");
        formDataUpdate.append("voice", turkishVoice);
      } else if (englishVoice !== "" && turkishVoice !== "") {
        formDataUpdate.append("voice", englishVoice);
        formDataUpdate.append("voice", turkishVoice);
      }

      formDataUpdate.append("english", english);
      formDataUpdate.append("turkish", turkish);
      formDataUpdate.append("type", type);

      fetch(`${Var.api_url}/word/${wordID}`, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${cookies.admin_token}`,
        },
        body: formDataUpdate,
      })
        .then((res) => {
          if (!res.ok) {
            Toast.fire({
              icon: "error",
              title: "Bir hata oluştu",
            });
          }
          return res.json();
        })
        .then((data) => {
          Toast.fire({
            icon: "success",
            title: data.message,
          });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        });
    } else {
      alert("Tüm boşlukları doğru bir şekilde doldurunuz");
    }
  };

  const updateClickHandler = () => {
    if (checkbox !== "") {
      setUpdateClicked(true);

      setEnglish(checkbox.english);
      setEnglishVoice(checkbox.englishVoice);
      setTurkish(checkbox.turkish);
      setTurkishVoice(checkbox.turkishVoice);
      setType(checkbox.type);

      setWordID(checkbox.id);
    } else {
      Toast.fire({
        icon: "error",
        title: "Önce seçim yapınız!",
      });
    }
  };

  useEffect(() => {
    if (turkishVoice.size < 1000)
      setTurkishSize(parseInt(turkishVoice.size) + "B");
    if (turkishVoice.size >= 1000 && turkishVoice.size < 1000000)
      setTurkishSize(parseInt(turkishVoice.size / 1000) + "KB");
    if (turkishVoice.size >= 1000000 && turkishVoice.size < 1000000000)
      setTurkishSize(parseInt(turkishVoice.size / 1000000) + "MB");
    if (turkishVoice.size >= 1000000000)
      setTurkishSize(parseInt(turkishVoice.size / 1000000000) + "GB");
  }, [turkishVoice]);

  useEffect(() => {
    if (englishVoice.size < 1000)
      setEnglishSize(parseInt(englishVoice.size) + "B");
    if (englishVoice.size >= 1000 && englishVoice.size < 1000000)
      setEnglishSize(parseInt(englishVoice.size / 1000) + "KB");
    if (englishVoice.size >= 1000000 && englishVoice.size < 1000000000)
      setEnglishSize(parseInt(englishVoice.size / 1000000) + "MB");
    if (englishVoice.size >= 1000000000)
      setEnglishSize(parseInt(englishVoice.size / 1000000000) + "GB");
  }, [englishVoice]);

  return (
    <div>
      <div className="card card-info">
        <div className="card-header">
          <h3 className="card-title">Sözlük Ekleme</h3>
        </div>
        {/* /.card-header */}
        {/* form start */}
        <div className="card-body row">
          <form className="col-md-12">
            <div className="card">
              <div className="card-header row">
                <div>
                  Sözlük listesinde gözükmesini istediklerinizi aşağıya
                  ekleyebilirsiniz.
                </div>
              </div>
              {/* /.card-header */}
              <div className="card-body">
                <div className="d-flex flex-row align-items-center mb-2">
                  <label className="col-md-1 mr-2" htmlFor="input-turkish">
                    Türkçe
                  </label>
                  <input
                    id="input-turkish"
                    className="form-control"
                    placeholder="Türkçe cümle..."
                    value={turkish}
                    onChange={(e) => setTurkish(e.target.value)}
                  />
                  <audio ref={audioTurkish} src={englishVoice}></audio>
                  {turkishVoice !== "" &&
                    updateClicked &&
                    !isPlayingTurkish && (
                      <SpeakerphoneIcon
                        className="!w-7 cursor-pointer ml-2"
                        onClick={() =>
                          voicePlayedWithRef(audioTurkish, "turkish")
                        }
                      />
                    )}
                  {turkishVoice !== "" && updateClicked && isPlayingTurkish && (
                    <PauseIcon
                      className="!w-7 cursor-pointer ml-2"
                      onClick={() =>
                        voicePlayedWithRef(audioTurkish, "turkish")
                      }
                    />
                  )}
                  <CloudUploadIcon
                    className="!w-7 cursor-pointer ml-2"
                    onClick={() =>
                      document.getElementById("input-turkishVoice").click()
                    }
                  />
                  <input
                    id="input-turkishVoice"
                    type="file"
                    className="hidden"
                    onChange={(e) => setTurkishVoice(e.target.files[0])}
                  />
                  {turkishSize !== "" && (
                    <strong className="w-20 text-right">
                      &nbsp;{turkishSize}
                    </strong>
                  )}
                </div>

                <div className="d-flex flex-row align-items-center mb-2">
                  <label className="col-md-1 mr-2" htmlFor="input-english">
                    İngilizce
                  </label>
                  <input
                    id="input-english"
                    className="form-control"
                    placeholder="İngilizce cümle..."
                    value={english}
                    onChange={(e) => setEnglish(e.target.value)}
                  />
                  <audio ref={audioEnglish} src={englishVoice}></audio>
                  {englishVoice !== "" &&
                    updateClicked &&
                    !isPlayingEnglish && (
                      <SpeakerphoneIcon
                        className="w-7 cursor-pointer ml-2"
                        onClick={() =>
                          voicePlayedWithRef(audioEnglish, "english")
                        }
                      />
                    )}
                  {englishVoice !== "" && updateClicked && isPlayingEnglish && (
                    <PauseIcon
                      className="w-7 cursor-pointer ml-2"
                      onClick={() =>
                        voicePlayedWithRef(audioEnglish, "english")
                      }
                    />
                  )}
                  <CloudUploadIcon
                    className="!w-7 cursor-pointer ml-2"
                    onClick={() =>
                      document.getElementById("input-englishVoice").click()
                    }
                  />
                  <input
                    id="input-englishVoice"
                    type="file"
                    className="hidden"
                    onChange={(e) => setEnglishVoice(e.target.files[0])}
                  />
                  {englishSize !== "" && (
                    <strong className="w-20 text-right">{englishSize}</strong>
                  )}
                </div>

                <br />

                <label className="mr-2" type="text">
                  Tipi
                </label>
                <Select
                  defaultValue={type}
                  onChange={(e) => setType(e.value)}
                  options={Var.wordTypes}
                  placeholder={type || "Tipi"}
                />

                <br />
                {/* <div className="col-md-12 d-flex flex-row mb-5 justify-content-around">
                    <div className="d-flex flex-row align-items-center">
                      <label className="mr-2" type="text">
                        Seviye
                      </label>
                      <Select
                        defaultValue={level}
                        onChange={(e) => setLevel(e.value)}
                        options={Var.optionsLevel}
                        placeholder={level}
                      />
                    </div>
                    <div className="d-flex flex-row align-items-center">
                      <label className="mr-2" type="text">
                        Hafta
                      </label>
                      <Select
                        defaultValue={week}
                        onChange={(e) => setWeek(e.value)}
                        options={Var.optionsWeek}
                        placeholder={week}
                      />
                    </div>
                    <div className="d-flex flex-row align-items-center">
                      <label className="mr-2" type="text">
                        Kapsam
                      </label>
                      <Select isDisabled />
                    </div>
                  </div>
                </div> */}

                <button
                  type="button"
                  className="btn bg-green-700 hover:bg-green-800 text-white btn-block col-md-2 ml-0"
                  onClick={() => {
                    updateClicked ? updateWord() : addClickHandler();
                  }}
                >
                  {updateClicked ? "Güncelle" : "Ekle"}
                </button>
              </div>
              {/* /.card-body */}
            </div>
            {/* /.card */}
          </form>
        </div>
      </div>

      {/* Sözlük Listesi */}
      <div className="card card-info">
        <div className="card-header">
          <h3 className="card-title">Sözlük Listesi</h3>
        </div>
        {/* /.card-header */}
        {/* form start */}
        <div className="card-body row">
          <form className="col-md-12">
            <div className="card">
              <div className="card-header row">
                <div>
                  Sözlük listesindeki detayları burada düzenleyebilirsiniz.
                </div>
                <div className="col-md-4 ml-auto flex flex-wrap items-center justify-end">
                  {!updateClicked && (
                    <button
                      type="button"
                      className="btn bg-green-700 hover:bg-green-800 text-white w-40 h-10 m-1"
                      onClick={() => updateClickHandler()}
                    >
                      Güncelle
                    </button>
                  )}
                  {updateClicked && (
                    <button
                      type="button"
                      className="btn bg-yellow-500 hover:bg-yellow-600 text-white w-40 h-10 m-1"
                      onClick={() => setUpdateClicked(false)}
                    >
                      Vazgeç
                    </button>
                  )}
                  <button
                    type="button"
                    className="btn bg-red-700 hover:bg-red-800 text-white w-40 h-10 m-1"
                    onClick={() => deleteClickHandler()}
                  >
                    Sil
                  </button>
                </div>
              </div>
              {/* /.card-header */}
              <div className="card-body">
                {dataReady && (
                  <MDBDataTableV5
                    hover
                    entriesOptions={[5, 20, 25]}
                    entries={5}
                    pagesAmount={4}
                    data={datatable}
                    // scrollX
                    checkbox="true"
                    searchTop
                    searchBottom={false}
                    headCheckboxID="id2"
                    bodyCheckboxID="checkboxes2"
                    getValueCheckBox={(e) => {
                      checkbox === ""
                        ? setCheckbox(e)
                        : checkbox.id === e.id
                        ? setCheckbox("")
                        : setCheckbox(e);
                    }}
                  />
                )}
              </div>
              {/* /.card-body */}
            </div>
            {/* /.card */}
          </form>
        </div>
      </div>
    </div>
  );
};

export default DictionaryPage;
