import { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import Var from './variables';
import { useCookies } from 'react-cookie';
import { MDBDataTableV5 } from 'mdbreact';
import Select from 'react-select';
import Moment from 'moment';
import { PlusSmIcon, TrashIcon } from '@heroicons/react/solid';

const WatchPage = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [classId, setClassId] = useState('');
  const [selectedClassId, setSelectedClassId] = useState('');
  const [link, setLink] = useState('');
  const [week, setWeek] = useState('');
  const [level, setLevel] = useState('');
  const [videoID, setVideoID] = useState('');

  const [updateClicked, setUpdateClicked] = useState(false);

  const [classes, setClasses] = useState([]);
  const [classesInput, setClassesInput] = useState([]);
  const [dataReady, setDataReady] = useState(false);
  const [cookies] = useCookies();

  const [tempData, setTempData] = useState([]);
  const [datatable, setDatatable] = useState({
    columns: [
      {
        label: 'Başlık',
        field: 'title',
      },
      {
        label: 'Sınıf',
        field: 'classId',
      },
      {
        label: 'Link',
        field: 'link',
      },
      {
        label: 'Hafta',
        field: 'week',
        sort: 'asc',
      },
      {
        label: 'Seviye',
        field: 'level',
      },
      {
        label: 'Tarih',
        field: 'date',
      },
    ],
    rows: [],
  });
  const [checkbox, setCheckbox] = useState('');

  const deleteClickHandler = () => {
    if (checkbox !== '') {
      if (
        window.confirm(
          checkbox.title + ' videosunu silmek istedğinize emin misiniz?'
        )
      ) {
        fetch(`${Var.api_url}/video/${checkbox.id}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${cookies.admin_token}`,
          },
        })
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            Toast.fire({
              icon: 'success',
              title: data.message,
            });
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else {
      Toast.fire({
        icon: 'error',
        title: 'Önce seçim yapınız!',
      });
    }
  };

  var Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 5000,
  });

  const addClickHandler = () => {
    if (
      title !== '' &&
      description !== '' &&
      classId !== '' &&
      link !== '' &&
      week !== '' &&
      level !== '' &&
      !isNaN(week) &&
      !isNaN(level)
    ) {
      fetch(`${Var.api_url}/video/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${cookies.admin_token}`,
        },
        body: JSON.stringify({
          title,
          description,
          classId,
          link,
          week,
          level,
        }),
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          Toast.fire({
            icon: 'success',
            title: data.message,
          });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch((err) => alert(err));
    } else {
      alert('Tüm boşlukları doğru bir şekilde doldurunuz');
    }
  };

  useEffect(() => {
    fetch(`${Var.api_url}/video/admin`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${cookies.admin_token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => setTempData(data))
      .then(() => setDataReady(true))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (tempData.length !== 0) {
      let tempArray = [...tempData];
      tempData.map((data, index) => {
        tempArray[index].date = Moment(tempArray[index].date).format(
          'DD/MM/YYYY HH:mm:ss'
        );
      });
      setDatatable({ ...datatable, rows: tempArray });
      setDataReady(true);
    }
  }, [tempData]);

  const updateVideo = () => {
    if (
      title !== '' &&
      description !== '' &&
      classId !== '' &&
      link !== '' &&
      week !== '' &&
      level !== '' &&
      !isNaN(week) &&
      !isNaN(level)
    ) {
      fetch(`${Var.api_url}/video/${videoID}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${cookies.admin_token}`,
        },
        body: JSON.stringify({
          title,
          description,
          classId,
          link,
          week,
          level,
        }),
      })
        .then((res) => {
          if (!res.ok) {
            Toast.fire({
              icon: 'error',
              title: 'Bir hata oluştu',
            });
          }
          return res.json();
        })
        .then((data) => {
          Toast.fire({
            icon: 'success',
            title: data.message,
          });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        });
    } else {
      alert('Tüm boşlukları doğru bir şekilde doldurunuz');
    }
  };

  const updateClickHandler = () => {
    if (checkbox !== '') {
      setUpdateClicked(true);

      setTitle(checkbox.title);
      setDescription(checkbox.description);
      setClassId(checkbox.classId);
      setLink(checkbox.link);
      setLevel(checkbox.level);
      setWeek(checkbox.week);
      setVideoID(checkbox.id);
    } else {
      Toast.fire({
        icon: 'error',
        title: 'Önce seçim yapınız!',
      });
    }
  };

  const getClassByID = (id) => {
    return classes.filter(function (classes) {
      return classes.id == id;
    });
  };

  useEffect(() => {
    if (datatable.length !== 0) {
      fetch(`${Var.api_url}/class/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${cookies.admin_token}`,
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          setClassesCLickHandler(data);
          setClasses(data);
        })
        .catch((err) => console.log(err));
    }
  }, [datatable]);

  const setClassesCLickHandler = (datas) => {
    let tempClasses = [];
    datas.map((data, i) => {
      tempClasses.push({
        value: data.id,
        label: data.title,
      });
    });
    setClassesInput(tempClasses);
  };

  const addClassId = () => {
    if (selectedClassId !== '') {
      if (
        !classId.toString().split(',').includes(selectedClassId.toString()) &&
        classId !== ''
      ) {
        setClassId(classId + ',' + selectedClassId);
      } else if (classId.toString() === '') {
        setClassId(selectedClassId);
      } else {
        Toast.fire({
          icon: 'warning',
          title: 'Bu sınıf zaten kayıtlı!',
        });
      }
    } else {
      Toast.fire({
        icon: 'warning',
        title: 'Lütfen seçim yapınız!',
      });
    }
  };

  const removeClassId = () => {
    if (selectedClassId !== '') {
      if (classId.toString().split(',').includes(selectedClassId.toString())) {
        let tempClassIdArray = classId.split(',');
        for (let i = 0; i < tempClassIdArray.length; i++)
          if (tempClassIdArray[i] === selectedClassId.toString())
            tempClassIdArray.splice(i, 1);
        setClassId(tempClassIdArray.join(','));
      } else {
        Toast.fire({
          icon: 'warning',
          title: 'Bu sınıf zaten kayıtlı değil!',
        });
      }
    } else {
      Toast.fire({
        icon: 'warning',
        title: 'Lütfen seçim yapınız!',
      });
    }
  };

  return (
    <div>
      <div className='card card-info'>
        <div className='card-header'>
          <h3 className='card-title'>Video Ekleme</h3>
        </div>
        {/* /.card-header */}
        {/* form start */}
        <div className='card-body row'>
          <form className='col-md-12'>
            <div className='card'>
              <div className='card-header row'>
                <div>
                  Video listesinde gözükmesini istediklerinizi aşağıya
                  ekleyebilirsiniz.
                </div>
              </div>
              {/* /.card-header */}
              <div className='card-body'>
                <label>
                  Başlık <label className='text-danger'>*</label>
                </label>
                <input
                  className='form-control'
                  placeholder='Başlık...'
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
                <br />
                <label>
                  Açıklama <label className='text-danger'>*</label>
                </label>
                <textarea
                  className='form-control'
                  rows={5}
                  placeholder='Açıklama...'
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
                <br />
                <label>
                  Video Linki <label className='text-danger'>*</label>
                </label>
                <input
                  className='form-control'
                  placeholder='Link...'
                  value={link}
                  onChange={(e) => setLink(e.target.value)}
                />
                <br />
                <label>
                  Sınıfları <label className='text-danger'>*</label>
                </label>
                <div className='flex flex-row mb-4'>
                  {classId !== '' &&
                    classId.toString().includes(',') &&
                    classId.split(',').map((id, i) => {
                      if (id !== '')
                        return (
                          <p className='border rounded-xl p-2 mr-2' key={i}>
                            {getClassByID(id)[0].title}
                          </p>
                        );
                    })}
                  {classId !== '' && !classId.toString().includes(',') && (
                    <p className='border rounded-xl p-2 mr-2'>
                      {getClassByID(classId)[0].title}
                    </p>
                  )}
                </div>
                <div className='flex flex-row'>
                  <Select
                    className='w-60 mr-2'
                    onChange={(e) => setSelectedClassId(e.value)}
                    options={classesInput}
                    placeholder={'Sınıf'}
                  />
                  <PlusSmIcon
                    className='w-7 cursor-pointer'
                    onClick={addClassId}
                  />
                  <div className='border-r mx-2' />
                  <TrashIcon
                    className='w-7 cursor-pointer'
                    onClick={removeClassId}
                  />
                </div>
                <br />
                <div className='col-md-12 d-flex flex-row mb-5 justify-content-around'>
                  <div className='d-flex flex-row align-items-center'>
                    <label className='mr-2' type='text'>
                      Seviye
                    </label>
                    <Select
                      defaultValue={level}
                      onChange={(e) => setLevel(e.value)}
                      options={Var.optionsLevel}
                      placeholder={level}
                    />
                  </div>
                  <div className='d-flex flex-row align-items-center'>
                    <label className='mr-2' type='text'>
                      Hafta
                    </label>
                    <Select
                      defaultValue={week}
                      onChange={(e) => setWeek(e.value)}
                      options={Var.optionsWeek}
                      placeholder={week}
                    />
                  </div>
                  <div className='d-flex flex-row align-items-center'>
                    <label className='mr-2' type='text'>
                      Kapsam
                    </label>
                    <Select isDisabled />
                  </div>
                </div>
                <br />
                <button
                  type='button'
                  className='btn bg-green-700 hover:bg-green-800 text-white btn-block col-md-2 ml-0'
                  onClick={() => {
                    updateClicked ? updateVideo() : addClickHandler();
                  }}
                >
                  {updateClicked ? 'Güncelle' : 'Ekle'}
                </button>
              </div>
              {/* /.card-body */}
            </div>
            {/* /.card */}
          </form>
        </div>
      </div>

      {/* Video Listesi */}
      <div className='card card-info'>
        <div className='card-header'>
          <h3 className='card-title'>Video Listesi</h3>
        </div>
        {/* /.card-header */}
        {/* form start */}
        <div className='card-body row'>
          <form className='col-md-12'>
            <div className='card'>
              <div className='card-header row'>
                <div>
                  Video listesindeki detayları burada düzenleyebilirsiniz.
                </div>
                <div className='col-md-4 ml-auto flex flex-wrap items-center justify-end'>
                  {!updateClicked && (
                    <button
                      type='button'
                      className='btn bg-green-700 hover:bg-green-800 text-white w-40 h-10 m-1'
                      onClick={() => updateClickHandler()}
                    >
                      Güncelle
                    </button>
                  )}
                  {updateClicked && (
                    <button
                      type='button'
                      className='btn bg-yellow-500 hover:bg-yellow-600 text-white w-40 h-10 m-1'
                      onClick={() => setUpdateClicked(false)}
                    >
                      Vazgeç
                    </button>
                  )}
                  <button
                    type='button'
                    className='btn bg-red-700 hover:bg-red-800 text-white w-40 h-10 m-1'
                    onClick={() => deleteClickHandler()}
                  >
                    Sil
                  </button>
                </div>
              </div>
              {/* /.card-header */}
              <div className='card-body'>
                {dataReady && (
                  <MDBDataTableV5
                    hover
                    entriesOptions={[5, 20, 25]}
                    entries={5}
                    pagesAmount={4}
                    data={datatable}
                    // scrollX
                    checkbox='true'
                    searchTop
                    searchBottom={false}
                    headCheckboxID='id2'
                    bodyCheckboxID='checkboxes2'
                    getValueCheckBox={(e) => {
                      checkbox === ''
                        ? setCheckbox(e)
                        : checkbox.id === e.id
                        ? setCheckbox('')
                        : setCheckbox(e);
                    }}
                  />
                )}
              </div>
              {/* /.card-body */}
            </div>
            {/* /.card */}
          </form>
        </div>
      </div>
    </div>
  );
};

export default WatchPage;
