import { useState, useEffect } from "react";
import Swal from "sweetalert2";
import Var from "./variables";
import { useCookies } from "react-cookie";
import Select from "react-select";

const CreateMemberPage = () => {
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("")
  const [classId, setClassId] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [isPaid, setIsPaid] = useState("");


  const [selectClasses, setSelectClasses] = useState([]);
  const [classesReady, setClassesReady] = useState(false);
  const [cookies] = useCookies();

  var Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 5000,
  });

  useEffect(() => {
    fetch(`${Var.api_url}/class/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookies.admin_token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setClassesCLickHandler(data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (selectClasses.length !== 0) {
      setClassesReady(true);
    }
  }, [selectClasses]);

  const setClassesCLickHandler = (datas) => {
    let tempClasses = [];
    datas.map((data, i) => {
      tempClasses.push({
        value: data.id,
        label: data.title,
      });
    });
    setSelectClasses(tempClasses);
  };

  const createUser = () => {
    if (
      name !== "" &&
      surname !== "" &&
      email !== "" &&
      phoneNumber !== "" &&
      classId !== "" &&
      isPaid !== ""
    ) {
      fetch(`${Var.api_url}/user/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${cookies.admin_token}`,
        },
        body: JSON.stringify({
          name,
          surname,
          email,
          phoneNumber,
          classId,
          isPaid,
        }),
      })
        .then((res) => {
          if (!res.ok) {
            Toast.fire({
              icon: "error",
              title: "Bir hata oluştu",
            });
          }
          return res.json();
        })
        .then((data) => {
          Toast.fire({
            icon: "success",
            title: data.message,
          });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        });
    } else {
      alert("Tüm boşlukları doğru bir şekilde doldurunuz");
    }
  };

  return (
    <div>
      <div className="card card-info">
        <div className="card-header">
          <h3 className="card-title">Üye Oluşturma</h3>
        </div>
        {/* /.card-header */}
        {/* form start */}
        <div className="card-body row">
          <form className="col-md-12">
            <div className="card">
              <div className="card-header row">
                <div>
                  Üye listesinde gözükmesini istediklerinizi aşağıda
                  güncelleyebilirsiniz.
                </div>
              </div>
              {/* /.card-header */}
              <div className="card-body">
                <label>
                  Ad <label className="text-danger">*</label>
                </label>
                <input
                  className="form-control"
                  placeholder="Adı..."
                  value={name || ""}
                  onChange={(e) => setName(e.target.value)}
                />
                <br />
                <label>
                  Soyad <label className="text-danger">*</label>
                </label>
                <input
                  className="form-control"
                  placeholder="Adı..."
                  value={surname || ""}
                  onChange={(e) => setSurname(e.target.value)}
                />
                <br />
                <label>
                  Sınıf <label className="text-danger">*</label>
                </label>
                {classesReady && (
                  <Select
                    className="w-full"
                    onChange={(e) => setClassId(e.value)}
                    options={selectClasses}
                    placeholder={"Sınıf"}
                  />
                )}
                <br />
                <label>
                  Telefon No <label className="text-danger">*</label>
                </label>
                <input
                  className="form-control"
                  placeholder="Telefon No..."
                  value={phoneNumber || ""}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
                <br />
                <label>
                  Email <label className="text-danger">*</label>
                </label>
                <input
                  className="form-control"
                  placeholder="Email..."
                  value={email || ""}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <br />
                <label className="mr-2" type="text">
                  Ödedi
                </label>
                <Select
                  defaultValue={isPaid}
                  onChange={(e) => setIsPaid(e.value)}
                  options={Var.isTrue}
                  placeholder={isPaid}
                />
                <br />
                <p>NOT: Kullanıcı şifresi <span className="font-bold">idlactr22</span> olarak tanımlanacaktır.</p>
                <br />
                <button
                  type="button"
                  className="btn bg-green-700 hover:bg-green-800 text-white btn-block col-md-2 ml-0"
                  onClick={() => {
                    createUser();
                  }}
                >
                  Ekle
                </button>
              </div>
              {/* /.card-body */}
            </div>
            {/* /.card */}
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateMemberPage;
