import React, { useState, useEffect } from 'react';
import { ArrowNarrowLeftIcon, ChevronDownIcon } from "@heroicons/react/solid";
import Draggable from 'react-draggable';
import reactTriggerChange from 'react-trigger-change'

const Phonetic = () => {
    const phoneticAlphabet = ['ɑ', 'ɔ', 'ʌ', 'ɛ', 'u', 'ʊ', 'r', 'ɹ', 'ɾ', 'm', 'b', 'p', 'left_arrow', 'aʊ', 'ɔɪ', 'oʊ', 'eɪ', 'i', 'ɪ', 'ʤ', 'd', 'ʒ', 'j', 'h', 'g', 'k', 'aɪ', 'eə', 'ɪə', 'ɒ', 'ə', 'v', 's', 'z', 'f', 'ʧ', 'ʃ', 'l', 'ɫ', 'æ', 'ʊə', 'əʊ', 'ɜː', 'ɚ', 'w', 'Ɵ', 'ð', 'n', 'ŋ', 't', 'ʔ', '̚', 'ˌ', 'ˈ', 'space', 'ǀ', 'ǁ', 'ː', 'ʲ', 'ʷ', 'enter'];

    const KeyboardClickHandler = (e) => {
        let inputValue = document.getElementById(document.activeElement.id);

        if (e === "left_arrow") 
            inputValue.value = inputValue.value.substring(0, inputValue.value.length - 1);
        else if (e.target.innerText === "enter")
            inputValue.value = inputValue.value + '\n';
        else if (e.target.innerText === "space")
            inputValue.value = inputValue.value + ' ';
        else
            inputValue.value = inputValue.value + e.target.innerText;

        reactTriggerChange(inputValue)
    }

    const [isPhoneticActive, setIsPhoneticActive] = useState(false);

    return (
        <div className='!z-50 absolute' onMouseDown={(e)=>e.preventDefault()}>
            <Draggable cancel=".btn">
                <div className="bg-slate-300/75 rounded-md w-fit p-1 flex flex-col text-black cursor-move shadow-lg xs:text-sm">
                    <div className='p-2 rounded-md bg-idlac-blue/50 m-0.5 text-white flex flex-row items-center justify-between'>
                        <p>Phonetic Keyboard</p>
                        <ChevronDownIcon className='w-7 ml-4 cursor-pointer hover:fill-slate-400' onClick={() => setIsPhoneticActive(!isPhoneticActive)} />
                    </div>
                    {!isPhoneticActive && (
                        <div>
                            <div className="flex flex-row">
                                {phoneticAlphabet.slice(0, 13).map((char, index) => {
                                    if (char === 'left_arrow') return <div key={index} className="btn phonetic-keyboard !w-12 xs:!w-8" onClick={() => KeyboardClickHandler("left_arrow")}><ArrowNarrowLeftIcon className="w-6 fill-slate-300" /></div>
                                    else return <div key={index} className="btn phonetic-keyboard" onClick={KeyboardClickHandler}>{char}</div>
                                })}
                            </div>
                            <div className="flex flex-row ml-2">
                                {phoneticAlphabet.slice(13, 26).map((char, index) => (
                                    <div key={index} className="btn phonetic-keyboard" onClick={KeyboardClickHandler}>{char}</div>
                                ))}
                            </div>
                            <div className="flex flex-row">
                                {phoneticAlphabet.slice(26, 39).map((char, index) => {
                                    if (char === 'eə' || char === 'ɪə' || char === 'ɒ') return <div key={index} className="btn phonetic-keyboard !bg-slate-300" onClick={KeyboardClickHandler}>{char}</div>
                                    else return <div key={index} className="btn phonetic-keyboard" onClick={KeyboardClickHandler}>{char}</div>
                                })}
                            </div>
                            <div className="flex flex-row ml-2">
                                {phoneticAlphabet.slice(39, 52).map((char, index) => {
                                    if (char === 'ʊə' || char === 'əʊ' || char === 'ɜː') return <div key={index} className="btn phonetic-keyboard !bg-slate-300" onClick={KeyboardClickHandler}>{char}</div>
                                    else return <div key={index} className="btn phonetic-keyboard" onClick={KeyboardClickHandler}>{char}</div>
                                })}
                            </div>
                            <div className="flex flex-row ml-4">
                                {phoneticAlphabet.slice(52).map((char, index) => {
                                    if (char === 'enter') return <div key={index} className="btn phonetic-keyboard !w-14 xs:!w-8" onClick={KeyboardClickHandler}><img src={require("./assets/img/enter.png")} color="#fff" className="w-6" /></div>
                                    else if (char === 'space') return <div key={index} className="btn phonetic-keyboard !w-44 xs:!w-20" onClick={KeyboardClickHandler}>&nbsp;</div>
                                    else return <div key={index} className="btn phonetic-keyboard" onClick={KeyboardClickHandler}>{char}</div>
                                })}
                            </div>
                        </div>
                    )}
                </div>
            </Draggable>
        </div>
    );
}
 
export default Phonetic;