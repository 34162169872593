import React from "react";
import Var from "./variables";
import { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import Swal from "sweetalert2";
import JoditEditor from "jodit-react";
import { Router } from "react-router";

const Finans = () => {
  const cookies = useCookies();
  const config = {
    buttons: [
      "source",
      "|",
      "bold",
      "italic",
      "underline",
      "strikethrough",
      "|",
      "font",
      "fontsize",
      "brush",
      "paragraph",
      "|",
      "align",
      "undo",
      "redo",
      "|",
      "image",
      "video",
      "|", // Resim ve video eklemek için gerekli düğmeler
      "hr",
      "eraser",
      "copyformat",
      "|",
      "ul",
      "ol",
      "outdent",
      "indent",
      "|",
      "table",
      "link",
      "|",
      "fullsize",
    ],
  };
  const [tabs, setTabs] = useState([]);
  const [deletingTabId, setDeletingTabId] = useState(0);
  const [updateClicked, setUpdateClicked] = useState(false);

  const [ulke, setUlke] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [createDate, setCreateDate] = useState("");
  const [userName, setUserName] = useState("");
  const [packageId, setPackageId] = useState(0);
  const [price, setPrice] = useState(0);
  const [validityDate, setValidityDate] = useState("");
  const [discountPrice, setDiscountPrice] = useState(0);
  const [taxNo, setTaxNo] = useState(0);
  const [taxOffice, setTaxOffice] = useState("");
  const [ref, setRef] = useState("");
  const [sentKolaybi, setSentKolaybi] = useState(0);
  const [companyName, setCompanyName] = useState("");
  const [type, setType] = useState(0);
  const [userId, setUserId] = useState("");
  const [identityNo, setIdentityNo] = useState("");

  useEffect(() => {
    getTabs();
  }, []);

  const postTabs = () => {
    if (
      price !== "" ||
      discountPrice !== "" ||
      address !== null ||
      address !== null ||
      identityNo !== null ||
      userId !== null ||
      packageId !== null ||
      type !== null ||
      userName !== null ||
      createDate !== null ||
      city !== null ||
      createDate !== null
    ) {
      fetch(`${Var.api_url}/bill/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${cookies.admin_token}`,
        },
        body: JSON.stringify({
          price: price,
          discountPrice: discountPrice,
          address: address,

          identityNo: identityNo,
          userId: userId,
          packageId: packageId,
          type: type,
          userName: userName,
          createDate: createDate,
          city: city,
          createDate: createDate,
        }),
      })
        .then((res) => {
          console.log(res.json());
          if (res.status === 200) {
            Swal.fire(
              "Başarılı!",
              "Referans kodu başarıyla oluşturuldu!",
              "success"
            );
          } else {
            Swal.fire("Hata!", "Referans kodu oluşturulamadı!", "error");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log("Please fill all the fields");
    }
  };

  const getTabs = () => {
    fetch(`${Var.api_url}/bill/0`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookies[0].admin_token}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        setTabs(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <div id="post-tab">
        <div className="card card-info">
          <div className="card-header">
            <h3 className="card-name">Sekme Ekleme</h3>
          </div>
          <div className="card-body row">
            <form className="col-md-12">
              <div className="card">
                <div className="card-header col">
                  <div>Buradan sekmelere ekleme yapabilirsiniz.</div>
                  <div className="card-body">
                    <label>User Name</label>
                    <input
                      onChange={(e) => {
                        setUserName(e.target.value);
                      }}
                      value={userName || ""}
                      type="text"
                      className="form-control"
                    />
                    <br />
                    <label>Ülke</label>
                    <input
                      onChange={(e) => {
                        setUlke(e.target.value);
                      }}
                      value={ulke || ""}
                      type="text"
                      className="form-control"
                    />
                    <br />
                    <label>Adres</label>
                    {/* <input
                      onChange={(e) => {
                        setAddress(e.target.value);
                      }}
                      type="text"
                      className="form-control"
                    /> */}
                    <JoditEditor
                      value={address || ""}
                      config={config}
                      tabIndex={1} // tabIndex of textarea
                      onBlur={(newContent) => setAddress(newContent)} // preferred to use only this option to update the content for performance reasons
                    />
                    <br />
                    <label>Fatura Tarihi</label>
                    <input
                      onChange={(e) => {
                        setCreateDate(e.target.value);
                      }}
                      value={createDate || ""}
                      type="date"
                      className="form-control"
                    />
                    <br />

                    <label>PaketId</label>
                    <input
                      onChange={(e) => {
                        setPackageId(e.target.value);
                      }}
                      value={packageId}
                      type="number"
                      className="form-control"
                    />
                    <br />
                    <label>Fiyat</label>
                    <input
                      onChange={(e) => {
                        setPrice(e.target.value);
                      }}
                      value={price}
                      type="number"
                      className="form-control"
                    />
                    <br />
                    <label>Indirimli Fiyat</label>
                    <input
                      onChange={(e) => {
                        setDiscountPrice(e.target.value);
                      }}
                      value={discountPrice}
                      type="number"
                      className="form-control"
                    />
                    <br />
                    <label>Type</label>
                    <input
                      onChange={(e) => {
                        setType(e.target.value);
                      }}
                      value={discountPrice}
                      type="number"
                      className="form-control"
                    />
                    <br />
                  </div>
                  <button
                    onClick={() => {
                      postTabs();
                    }}
                    type="button"
                    className={
                      "btn hover:bg-green-800 text-white btn-block col-md-2 mt-4" +
                      (!updateClicked ? " bg-green-600" : " bg-yellow-600")
                    }
                  >
                    {updateClicked ? "Güncelle" : "Ekle"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div id="get-tabs">
        <div className="card card-info">
          <div className="card-header">
            <h3 className="card-name">Sekmeler</h3>
          </div>
          <div className="card-body row">
            <form className="col-md-12">
              <div className="card">
                <div className="card-header col">
                  <div>Buradan sekmelere erişebilirsiniz.</div>
                  <div className="card-body">
                    <div className="flex flex-row items-center space-x-8">
                      <div>
                        Silinecek sekme:{" "}
                        {tabs.map((tab) =>
                          tab.id === deletingTabId ? tab.id : ""
                        )}
                      </div>
                      <div></div>
                      <div>
                        <button
                          type="submit"
                          className="w-32 bg-yellow-600 rounded-md px-4 py-2 text-white"
                          onClick={(e) => {
                            e.preventDefault();
                            const selectedTab = tabs.find(
                              (tab) => tab.id === deletingTabId
                            );
                            setUserName(selectedTab.userName);
                            setUlke(selectedTab.country);
                            setAddress(selectedTab.address);
                            setPackageId(selectedTab.packageId);
                            setCreateDate(selectedTab.createDate);
                            setCity(selectedTab.city);
                            setPrice(selectedTab.price);
                            setDiscountPrice(selectedTab.discountPrice);
                            setType(selectedTab.type);
                            setUserId(selectedTab.userId);
                            setIdentityNo(selectedTab.identityNo);

                            if (!updateClicked) {
                              setUpdateClicked(true);
                            } else {
                              setUpdateClicked(false);
                            }
                          }}
                        >
                          {!updateClicked ? "Güncelle" : "Vazgeç"}
                        </button>
                      </div>
                    </div>
                    <div className="card-body col space-y-2">
                      <div
                        id="list-header"
                        className="flex flex-row justify-between font-bold p-2"
                      >
                        {/* <p className="text-red-500">Sil</p> */}
                        <p className="col-md-1">id</p>
                        <p className="col-md-1">Kullanıcı id</p>
                        <p className="col-md-1">Kullanıcı adı</p>
                        <p className="col-md-1">Ulke</p>
                        <p className="col-md-1">Şehir</p>
                        <p className="col-md-1">Adres</p>
                        <p className="col-md-1">Identitiy No</p>
                        <p className="col-md-1">Paket id</p>
                        <p className="col-md-1">Fiyat</p>
                        <p className="col-md-1">İndirimli Fiyat</p>
                        <p className="col-md-1">TaxNo</p>
                        <p className="col-md-1">TaxOffice</p>
                        <p className="col-md-1">Olusturulma tarihi</p>
                        <p className="col-md-1">Referans</p>
                        <p className="col-md-1">SentKolaybi</p>
                        <p className="col-md-1">Şirket adı</p>
                        <p className="col-md-1">Type</p>
                      </div>
                      <div className="flex flex-col-reverse">
                        {tabs.map((tab, index) => {
                          return (
                            <div
                              onClick={() => {
                                if (deletingTabId !== 0) {
                                  setDeletingTabId(0);
                                  setDeletingTabId(tab.id);
                                } else {
                                  setDeletingTabId(tab.id);
                                }
                              }}
                              key={index}
                              className={`cursor-pointer flex flex-row col-md-12 justify-between items-center p-2 ${
                                index % 2 ? "" : "bg-gray-200"
                              } ${
                                deletingTabId === tab.id
                                  ? "bg-red-500 duration-500 ease-in-out"
                                  : ""
                              }`}
                            >
                              <div className="flex flex-row items-center col-md-1 w-1/8 min-h-[100px]">
                                {tab.id}
                              </div>
                              <div className="flex flex-row items-center col-md-1 w-1/8 min-h-[100px]">
                                {tab.userId}
                              </div>
                              <div className="flex flex-row items-center col-md-1 w-1/8 min-h-[100px]">
                                {tab.userName}
                              </div>
                              <div className="flex flex-row items-center col-md-1 w-1/8 min-h-[100px]">
                                {tab.country}
                              </div>
                              <div className="flex flex-row items-center col-md-1 w-1/8 min-h-[100px]">
                                {tab.city}
                              </div>
                              <div className="flex flex-row items-center col-md-1 w-1/8 min-h-[100px]">
                                {tab.address}
                              </div>
                              <div className="flex flex-row items-center col-md-1 w-1/8 min-h-[100px]">
                                {tab.identityNo}
                              </div>
                              <div className="flex flex-row items-center col-md-1 w-1/8 min-h-[100px]">
                                {tab.packageId}
                              </div>
                              <div className="flex flex-row items-center col-md-1 w-1/8 min-h-[100px]">
                                {tab.price}
                              </div>
                              <div className="flex flex-row items-center col-md-1 w-1/8 min-h-[100px]">
                                {tab.discountPrice}
                              </div>
                              <div className="flex flex-row items-center col-md-1 w-1/8 min-h-[100px]">
                                {tab.taxNo}
                              </div>
                              <div className="flex flex-row items-center col-md-1 w-1/8 min-h-[100px]">
                                {tab.taxOffice}
                              </div>
                              <div className="flex flex-row items-center col-md-1 w-1/8 min-h-[100px]">
                                {tab.createDate}
                              </div>
                              <div className="flex flex-row items-center col-md-1 w-1/8 min-h-[100px]">
                                {tab.ref}
                              </div>
                              <div className="flex flex-row items-center col-md-1 w-1/8 min-h-[100px]">
                                {tab.sentKolaybi}
                              </div>{" "}
                              <div className="flex flex-row items-center col-md-1 w-1/8 min-h-[100px]">
                                {tab.companyName}
                              </div>
                              <div className="flex flex-row items-center col-md-1 w-1/8 min-h-[100px]">
                                {tab.type}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Finans;
