import React from "react";
import Var from "./variables";
import { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import Swal from "sweetalert2";
import JoditEditor from "jodit-react";
import { Router } from "react-router";
import ModalComp from "./ModalComp";
import axios from "axios"; // Import axios
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import Quill from "quill";

import { useRef } from "react";
const Panels = () => {
  const cookies = useCookies();
  const config = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
    limitChars: false,
  };

  const [tabs, setTabs] = useState([]);
  const [deletingTabId, setDeletingTabId] = useState(0);
  const [updateClicked, setUpdateClicked] = useState(false);
  const quillRef = useRef(null);
  const [title, setTitle] = useState("");
  const [summary, setSummary] = useState("");
  const [description, setDescription] = useState("");
  const [releaseDate, setReleaseDate] = useState("");
  const [parentId, setParentId] = useState(0);
  const [permalink, setPermalink] = useState("");
  const [access, setAccess] = useState(0);
  const [validityDate, setValidityDate] = useState("");
  const [limit, setLimit] = useState(0);
  const [teacher, setTeacher] = useState("");
  const [image, setImage] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalState, setModalState] = useState();

  useEffect(() => {
    getTabs();
    setModalIsOpen(true);
  }, []);

  useEffect(() => {
    setParentId(modalState);
  }, [modalState]);

  const postTabs = async () => {
    if (
      title !== "" &&
      summary !== "" &&
      description !== "" &&
      releaseDate !== "" &&
      parentId !== "" &&
      access !== "" &&
      validityDate !== "" &&
      limit !== "" &&
      teacher !== "" &&
      image !== null &&
      permalink !== ""
    ) {
      try {
        const formData = new FormData();
        formData.append("title", title);
        formData.append("summary", summary);
        formData.append("description", description);
        formData.append("releaseDate", releaseDate);
        formData.append("parentId", parseInt(parentId));
        formData.append("permalink", permalink);
        formData.append("access", parseInt(access));
        formData.append("validityDate", validityDate);
        formData.append("limit", parseInt(limit));
        formData.append("teacher", teacher);
        formData.append("image", image);
        const response = await fetch(`https://academy.idlac.com/api/tab/`, {
          method: "POST",
          headers: {
            //"Content-Type": "application/json",
            Authorization: `Bearer ${Var.tempAdminToken}`,
          },
          body: formData,
        });
        console.log("res", response);
        const data = await response.json();

        if (!response.ok) {
          // Handle non-successful responses
          throw new Error("Network response was not ok");
        }

        // Process the response data
        console.log("res", data);
        Swal.fire({
          icon: "success",
          title: "Başarılı",
          text: "Sekme başarıyla eklendi.",
        });

        // Optionally, perform additional actions after successful POST
      } catch (error) {
        // Handle errors
        console.log("err", error);
        Swal.fire({
          icon: "error",
          title: "Hata",
          text: "Sekme eklenirken bir hata oluştu.",
        });
      }
    }
  };
  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);
  const handleSetModalState = (value) => setModalState(value);

  const updateTab = () => {
    if (
      title !== "" &&
      summary !== "" &&
      description !== "" &&
      releaseDate !== "" &&
      validityDate !== "" &&
      parentId !== "" &&
      permalink !== "" &&
      access !== "" &&
      limit !== "" &&
      teacher !== ""
    ) {
      const formData = new FormData();
      formData.append("title", title);
      formData.append("summary", summary);
      formData.append("description", description);
      formData.append("releaseDate", releaseDate);
      formData.append("parentId", parseInt(parentId));
      formData.append("permalink", permalink);
      formData.append("access", parseInt(access));
      formData.append("validityDate", validityDate);
      formData.append("limit", parseInt(limit));
      formData.append("teacher", teacher);
      formData.append("image", image);

      fetch(`${Var.api_url}/tab/${deletingTabId}`, {
        method: "PUT",
        headers: {
          // "Content-Type": "application/json",
          Authorization: `Bearer ${Var.tempAdminToken}`,
        },
        body: formData,
        // JSON.stringify({
        //   title: title,
        //   summary: summary,
        //   description: description,
        //   releaseDate: releaseDate,
        //   parentId: parseInt(parentId),
        //   access: parseInt(access),
        //   validityDate: validityDate,
        //   limit: parseInt(limit),
        //   teacher: teacher,
        // }),
      })
        .then((res) => res.json())
        .then((res) => {
          console.log(res);
          Swal.fire({
            icon: "success",
            title: "Başarılı",
            text: "Sekme başarıyla güncellendi.",
          });
          // setTimeout(() => {
          //   window.location.reload();
          // }, 1000);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      Swal.fire({
        icon: "error",
        title: "Hata",
        text: "Lütfen tüm alanları doldurun.",
      });
    }
  };
  const handleImageUpload = async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post("/api/upload", formData); // Replace with your backend endpoint
      const imageUrl = response.data.imageUrl; // Assuming your backend returns the image URL
      insertImage(imageUrl); // Custom function to insert the image into the editor
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  // Custom function to insert the image into the editor
  const insertImage = (imageUrl) => {
    const range = quillRef.getEditor().getSelection();
    quillRef.getEditor().insertEmbed(range.index, "image", imageUrl);
  };
  const getTabs = () => {
    fetch(`${Var.api_url}/tab/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookies[0].admin_token}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        setTabs(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteTab = () => {
    fetch(`${Var.api_url}/tab/${deletingTabId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Var.tempAdminToken}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image", "video"], // Add 'video' here
      ["clean"],
    ],

    // Add other required modules
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "image",
    "video", // Add 'video' here
    // Add other required formats
  ];
  return (
    <div>
      <div id="post-tab">
        <div className="card card-info">
          <div className="card-header">
            <h3 className="card-title">Sekme Ekleme</h3>
          </div>
          <div className="card-body row">
            <form className="col-md-12">
              <div className="card">
                <div className="card-header col">
                  <ModalComp
                    isOpen={modalIsOpen}
                    closeModal={closeModal}
                    setModalState={handleSetModalState}
                  />

                  <div>Buradan sekmelere ekleme yapabilirsiniz.</div>
                  <div className="card-body">
                    <label>Sekme Başlığı</label>
                    <input
                      onChange={(e) => {
                        setTitle(e.target.value);
                      }}
                      value={title || ""}
                      type="text"
                      className="form-control"
                    />
                    <br />
                    <label>Özet</label>
                    <input
                      onChange={(e) => {
                        setSummary(e.target.value);
                      }}
                      value={summary || ""}
                      type="text"
                      className="form-control"
                    />
                    <br />
                    <label>Açıklama</label>
                    <ReactQuill
                      value={description}
                      onChange={setDescription}
                      placeholder="İçerik oluşturucu"
                      modules={modules}
                      formats={formats}
                      style={{ height: "200px" }}
                    />
                    {/* <input
                      onChange={(e) => {
                        setDescription(e.target.value);
                      }}
                      type="text"
                      className="form-control"
                    /> */}

                    <br />
                    <br />
                    <br />
                    <label>Yayınlanma tarihi</label>
                    <input
                      onChange={(e) => {
                        setReleaseDate(e.target.value);
                      }}
                      value={releaseDate || ""}
                      type="date"
                      className="form-control"
                    />
                    <br />
                    <label>Geçerlilik tarihi</label>
                    <input
                      onChange={(e) => {
                        setValidityDate(e.target.value);
                      }}
                      value={validityDate || ""}
                      type="date"
                      className="form-control"
                    />
                    <br />
                    <label>Kategori</label>
                    <input
                      onChange={(e) => {
                        setParentId(e.target.value);
                      }}
                      value={parentId}
                      type="number"
                      className="form-control"
                    />
                    <br />
                    <label>Permalink</label>
                    <input
                      onChange={(e) => {
                        setPermalink(e.target.value);
                      }}
                      value={permalink}
                      type="text"
                      className="form-control"
                    />
                    <br />
                    <label>Erişim</label>
                    <input
                      onChange={(e) => {
                        setAccess(e.target.value);
                      }}
                      value={access}
                      type="number"
                      className="form-control"
                    />
                    <br />
                    <label>Limit</label>
                    <input
                      onChange={(e) => {
                        setLimit(e.target.value);
                      }}
                      value={limit}
                      type="number"
                      className="form-control"
                    />
                    <br />
                    <label>Öğretmen</label>
                    <input
                      onChange={(e) => {
                        setTeacher(e.target.value);
                      }}
                      value={teacher || ""}
                      type="text"
                      className="form-control"
                    />
                    <br />
                    <label className="mr-2">
                      Thumbnail <label className="text-danger">*</label>
                    </label>
                    <input
                      type="file"
                      placeholder="Dosya seçin..."
                      onChange={(e) => setImage(e.target.files[0])}
                    />
                    <br />
                  </div>
                  <button
                    onClick={() => {
                      if (!updateClicked) {
                        postTabs();
                      } else {
                        updateTab();
                      }
                    }}
                    type="button"
                    className={
                      "btn hover:bg-green-800 text-white btn-block col-md-2 mt-4" +
                      (!updateClicked ? " bg-green-600" : " bg-yellow-600")
                    }
                  >
                    {updateClicked ? "Güncelle" : "Ekle"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div id="get-tabs">
        <div className="card card-info">
          <div className="card-header">
            <h3 className="card-title">Sekmeler</h3>
          </div>
          <div className="card-body row">
            <form className="col-md-12">
              <div className="card">
                <div className="card-header col">
                  <div>Buradan sekmelere erişebilirsiniz.</div>
                  <div className="card-body">
                    <div className="flex flex-row items-center space-x-8">
                      <div>
                        Silinecek sekme:{" "}
                        {tabs.map((tab) =>
                          tab.id === deletingTabId ? tab.id : ""
                        )}
                      </div>
                      <div>
                        <button
                          type="submit"
                          className="w-32 bg-red-600 rounded-md px-4 py-2 text-white"
                          onClick={deleteTab}
                        >
                          Sil
                        </button>
                      </div>
                      <div>
                        <button
                          type="submit"
                          className="w-32 bg-yellow-600 rounded-md px-4 py-2 text-white"
                          onClick={(e) => {
                            e.preventDefault();
                            const selectedTab = tabs.find(
                              (tab) => tab.id === deletingTabId
                            );
                            setTitle(selectedTab.title);
                            setSummary(selectedTab.summary);
                            setDescription(selectedTab.description);
                            setParentId(selectedTab.parentId);
                            setPermalink(selectedTab.permalink);
                            setAccess(selectedTab.access);
                            setLimit(selectedTab.limit);
                            setTeacher(selectedTab.teacher);

                            if (!updateClicked) {
                              setUpdateClicked(true);
                            } else {
                              setUpdateClicked(false);
                            }
                          }}
                        >
                          {!updateClicked ? "Güncelle" : "Vazgeç"}
                        </button>
                      </div>
                    </div>
                    <div className="card-body col space-y-2">
                      <div
                        id="list-header"
                        className="flex flex-row justify-between font-bold p-2"
                      >
                        {/* <p className="text-red-500">Sil</p> */}
                        <p className="col-md-1">Başlık</p>
                        <p className="col-md-1">Özet</p>
                        <p className="col-md-1">Açıklama</p>
                        <p className="col-md-1">Yayın Tarihi</p>
                        <p className="col-md-1">Kategori ID</p>
                        <p className="col-md-1">Permalink</p>
                        <p className="col-md-1">Erişim</p>
                        <p className="col-md-1">Limit</p>
                        <p className="col-md-1">Öğretmen</p>
                        <p className="col-md-1">Thumbnail</p>
                      </div>
                      <div className="flex flex-col-reverse">
                        {tabs.map((tab, index) => {
                          return (
                            <div
                              onClick={() => {
                                if (deletingTabId !== 0) {
                                  setDeletingTabId(0);
                                  setDeletingTabId(tab.id);
                                } else {
                                  setDeletingTabId(tab.id);
                                }
                              }}
                              key={index}
                              className={`cursor-pointer flex flex-row col-md-12 justify-between items-center p-2 ${
                                index % 2 ? "" : "bg-gray-200"
                              } ${
                                deletingTabId === tab.id
                                  ? "bg-red-500 duration-500 ease-in-out"
                                  : ""
                              }`}
                            >
                              <div className="flex flex-row items-center col-md-1 w-1/8 min-h-[100px]">
                                {tab.title.length <= 15
                                  ? tab.title
                                  : tab.title.slice(0, 15) + "..."}
                              </div>
                              <div className="flex flex-row items-center col-md-1 w-1/8 min-h-[100px]">
                                {tab.summary.length <= 20
                                  ? tab.summary
                                  : tab.summary.slice(0, 10) + "..."}
                              </div>
                              <div className="flex flex-row items-center col-md-1 w-1/8 min-h-[100px]">
                                {tab.description.length <= 10
                                  ? tab.description
                                  : tab.description.slice(0, 10) + "..."}
                              </div>
                              <div className="flex flex-row items-center col-md-1 w-1/8 min-h-[100px]">
                                {tab.releaseDate.split("T")[0]}
                              </div>
                              <div className="flex flex-row items-center col-md-1 w-1/8 min-h-[100px]">
                                {tab.parentId ? tab.parentId : "---"}
                              </div>
                              <div className="flex flex-row items-center col-md-1 w-1/8 min-h-[100px]">
                                {tab.permalink ? tab.permalink : "---"}
                              </div>
                              <div className="flex flex-row items-center col-md-1 w-1/8 min-h-[100px]">
                                {tab.access ? "Var" : "Yok"}
                              </div>
                              <div className="flex flex-row items-center col-md-1 w-1/8 min-h-[100px]">
                                {tab.limit ? tab.limit : "---"}
                              </div>
                              <div className="flex flex-row items-center col-md-1 w-1/8 min-h-[100px]">
                                {tab.teacher ? tab.teacher : "---"}
                              </div>
                              <div className="flex flex-row items-center col-md-1 w-1/8 min-h-[100px]">
                                {tab.image ? tab.image : "---"}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Panels;
