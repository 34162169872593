import { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import Var from './variables';
import { useCookies } from 'react-cookie';
import { MDBDataTableV5 } from 'mdbreact';
import Select from 'react-select';
import JoditEditor from 'jodit-react';
import Moment from 'moment';
import { PlusSmIcon, TrashIcon } from '@heroicons/react/solid';

const PackagesPage = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [discountPrice, setDiscountPrice] = useState('');
  const [buttonTitle, setButtonTitle] = useState('');
  const [features, setFeatures] = useState('');

  const [packageID, setPackageID] = useState('');
  const [updateClicked, setUpdateClicked] = useState(false);
  const [dataReady, setDataReady] = useState(false);
  const [cookies] = useCookies();

  const config = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
  };

  const [tempData, setTempData] = useState([]);
  const [datatable, setDatatable] = useState({
    columns: [
      {
        label: 'Başlık',
        field: 'title',
      },
      {
        label: 'Açıklama',
        field: 'description',
      },
      {
        label: 'Fiyatı',
        field: 'price',
      },
      {
        label: 'İndirimli Fiyatı',
        field: 'discountPrice',
      },
      {
        label: 'Buton Başlığı',
        field: 'buttonTitle',
      },
      {
        label: 'Özellikler',
        field: 'features',
      },
    ],
    rows: [],
  });
  const [checkbox, setCheckbox] = useState('');

  const deleteClickHandler = () => {
    if (checkbox !== '') {
      if (
        window.confirm(
          checkbox.title + ' paketini silmek istedğinize emin misiniz?'
        )
      ) {
        fetch(`${Var.api_url}/iyzicoPackages/${checkbox.id}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${cookies.admin_token}`,
          },
        })
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            Toast.fire({
              icon: 'success',
              title: data.message,
            });
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else {
      Toast.fire({
        icon: 'error',
        title: 'Önce seçim yapınız!',
      });
    }
  };

  var Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 5000,
  });

  const addClickHandler = () => {
    if (
      title !== '' &&
      description !== '' &&
      price !== '' &&
      !isNaN(price) &&
      (discountPrice === '' ||
        (discountPrice !== '' && !isNaN(discountPrice))) &&
      buttonTitle !== '' &&
      features !== ''
    ) {
      fetch(`${Var.api_url}/iyzicoPackages/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${cookies.admin_token}`,
        },
        body: JSON.stringify({
          title,
          description,
          price,
          discountPrice,
          buttonTitle,
          features,
        }),
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          Toast.fire({
            icon: 'success',
            title: data.message,
          });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch((err) => {
          alert(err);
        });
    } else {
      alert('Tüm boşlukları doğru bir şekilde doldurunuz');
    }
  };

  useEffect(() => {
    fetch(`${Var.api_url}/iyzicoPackages/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${cookies.admin_token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => setDatatable({ ...datatable, rows: data }))
      .then(() => setDataReady(true))
      .catch((err) => console.log(err));
  }, []);

  const updatePackage = () => {
    if (
      title !== '' &&
      description !== '' &&
      price !== '' &&
      !isNaN(price) &&
      (discountPrice === '' ||
        (discountPrice !== '' && !isNaN(discountPrice))) &&
      buttonTitle !== '' &&
      features !== ''
    ) {
      fetch(`${Var.api_url}/iyzicoPackages/${packageID}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${cookies.admin_token}`,
        },
        body: JSON.stringify({
          title,
          description,
          price,
          discountPrice,
          buttonTitle,
          features,
        }),
      })
        .then((res) => {
          if (!res.ok) {
            Toast.fire({
              icon: 'error',
              title: 'Bir hata oluştu',
            });
          }
          return res.json();
        })
        .then((data) => {
          Toast.fire({
            icon: 'success',
            title: data.message,
          });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        });
    } else {
      alert('Tüm boşlukları doğru bir şekilde doldurunuz');
    }
  };

  const updateClickHandler = () => {
    if (checkbox !== '') {
      setUpdateClicked(true);
      setPackageID(checkbox.id);

      setTitle(checkbox.title);
      setDescription(checkbox.description);
      setPrice(checkbox.price);
      setDiscountPrice(checkbox.discountPrice);
      setButtonTitle(checkbox.buttonTitle);
      setFeatures(checkbox.features);
    } else {
      Toast.fire({
        icon: 'error',
        title: 'Önce seçim yapınız!',
      });
    }
  };

  return (
    <div>
      <div className='card card-info'>
        <div className='card-header'>
          <h3 className='card-title'>Paket Ekleme</h3>
        </div>
        {/* /.card-header */}
        {/* form start */}
        <div className='card-body row'>
          <form className='col-md-12'>
            <div className='card'>
              <div className='card-header row'>
                <div>
                  Paketler listesinde gözükmesini istediklerinizi aşağıya
                  ekleyebilirsiniz.
                </div>
              </div>
              {/* /.card-header */}
              <div className='card-body'>
                <label>
                  Başlık <label className='text-danger'>*</label>
                </label>
                <input
                  type={'text'}
                  className='form-control'
                  placeholder='Başlık...'
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
                <br />
                <label>
                  Açıklama <label className='text-danger'>*</label>
                </label>
                <input
                  type={'text'}
                  className='form-control'
                  placeholder='Açıklama...'
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
                <br />
                <label>
                  Fiyatı (₺) <label className='text-danger'>*</label>
                </label>
                <input
                  type={'number'}
                  className='form-control'
                  placeholder='Fiyatı...'
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                />
                <br />
                <label className='mr-3'>İndirimli Fiyatı (₺)</label>
                (Opsiyonel)
                <input
                  type={'number'}
                  className='form-control'
                  placeholder='İndirimli Fiyatı...'
                  value={discountPrice}
                  onChange={(e) => setDiscountPrice(e.target.value)}
                />
                <br />
                <label>
                  Buton Başlığı <label className='text-danger'>*</label>
                </label>
                <input
                  type={'text'}
                  className='form-control'
                  placeholder='Buton Başlığı...'
                  value={buttonTitle}
                  onChange={(e) => setButtonTitle(e.target.value)}
                />
                <br />
                <label className='mr-3'>
                  Özellikler <label className='text-danger'>*</label>
                </label>
                Özelliklerin arasını virgül işareti (,) ile ayırınız.
                <input
                  type={'text'}
                  className='form-control'
                  placeholder='Özellikler...'
                  value={features}
                  onChange={(e) => setFeatures(e.target.value)}
                />
                <br />
                <button
                  type='button'
                  className='btn bg-green-700 hover:bg-green-800 text-white btn-block col-md-2 ml-0'
                  onClick={() => {
                    updateClicked ? updatePackage() : addClickHandler();
                  }}
                >
                  {updateClicked ? 'Güncelle' : 'Ekle'}
                </button>
              </div>
              {/* /.card-body */}
            </div>
            {/* /.card */}
          </form>
        </div>
      </div>

      {/* Paketler Listesi */}
      <div className='card card-info'>
        <div className='card-header'>
          <h3 className='card-title'>Paketler Listesi</h3>
        </div>
        {/* /.card-header */}
        {/* form start */}
        <div className='card-body row'>
          <form className='col-md-12'>
            <div className='card'>
              <div className='card-header row'>
                <div>
                  Paketler listesindeki detayları burada düzenleyebilirsiniz.
                </div>
                <div className='col-md-4 ml-auto flex flex-wrap items-center justify-end'>
                  {!updateClicked && (
                    <button
                      type='button'
                      className='btn bg-green-700 hover:bg-green-800 text-white w-40 h-10 m-1'
                      onClick={() => updateClickHandler()}
                    >
                      Güncelle
                    </button>
                  )}
                  {updateClicked && (
                    <button
                      type='button'
                      className='btn bg-yellow-500 hover:bg-yellow-600 text-white w-40 h-10 m-1'
                      onClick={() => setUpdateClicked(false)}
                    >
                      Vazgeç
                    </button>
                  )}
                  <button
                    type='button'
                    className='btn bg-red-700 hover:bg-red-800 text-white w-40 h-10 m-1'
                    onClick={() => deleteClickHandler()}
                  >
                    Sil
                  </button>
                </div>
              </div>
              {/* /.card-header */}
              <div className='card-body'>
                {dataReady && (
                  <MDBDataTableV5
                    hover
                    entriesOptions={[5, 20, 25]}
                    entries={5}
                    pagesAmount={4}
                    data={datatable}
                    // scrollX
                    checkbox='true'
                    searchTop
                    searchBottom={false}
                    headCheckboxID='id2'
                    bodyCheckboxID='checkboxes2'
                    getValueCheckBox={(e) => {
                      checkbox === ''
                        ? setCheckbox(e)
                        : checkbox.id === e.id
                        ? setCheckbox('')
                        : setCheckbox(e);
                    }}
                  />
                )}
              </div>
              {/* /.card-body */}
            </div>
            {/* /.card */}
          </form>
        </div>
      </div>
    </div>
  );
};

export default PackagesPage;
