import { useEffect } from "react";
import { useState } from "react";
import { useCookies } from "react-cookie";
import Swal from "sweetalert2";
import Var from "./variables";

const References = () => {
  const [referenceCode, setReferenceCode] = useState([]);
  const [endDate, setEndDate] = useState("");
  const [courses, setCourses] = useState([]);
  const [courseId, setCourseId] = useState(null);
  const [typeId, setTypeId] = useState("");
  const [count, setCount] = useState(0);
  const [unLimited, setUnLimited] = useState(false);
  const [discountPrice, setDiscountPrice] = useState(0);
  const [percent, setPercent] = useState(0);
  const [referenceCodes, setReferenceCodes] = useState([]);
  const [deletingCodeId, setDeletingCodeId] = useState(0);

  const [cookies] = useCookies();

  const postReference = () => {
    if (referenceCode !== "" || typeId !== "" || courseId !== null) {
      fetch(`${Var.api_url}/coupon/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${cookies.admin_token}`,
        },
        body: JSON.stringify({
          referenceCode: referenceCode,
          endDate: endDate !== "" ? endDate : null,
          typeId: typeId,
          count: count !== 0 ? count : null,
          unLimited: unLimited,
          discountPrice: discountPrice !== 0 ? discountPrice : null,
          percent: percent !== 0 ? percent : null,
          courseId: courseId,
        }),
      })
        .then((res) => {
          console.log(res.json());
          if (res.status === 200) {
            Swal.fire(
              "Başarılı!",
              "Referans kodu başarıyla oluşturuldu!",
              "success"
            );
            getReferenceCodes();
          } else {
            Swal.fire("Hata!", "Referans kodu oluşturulamadı!", "error");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log("Please fill all the fields");
    }
  };

  const deleteReferenceCode = () => {
    fetch(`${Var.api_url}/coupon/${deletingCodeId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookies.admin_token}`,
      },
    }).then((res) => {
      const promise = res.json();
      promise.then((response) => {
        console.log(response);
      });
    });
  };

  const getReferenceCodes = () => {
    fetch(`${Var.api_url}/coupon/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookies.admin_token}`,
      },
    }).then((res) => {
      const dataPromise = res.json();
      dataPromise.then((response) => {
        setReferenceCodes(response);
      });
    });
  };
  const getReferences = () => {
    fetch(`${Var.api_url}/educationPackages/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookies.admin_token}`,
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      })
      .then((response) => {
        setCourses(response);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  console.log(courses);
  useEffect(() => {
    getReferenceCodes();
    getReferences();
  }, []);

  return (
    <div id="references">
      {/* Referans ekleme */}
      <div className="card card-info">
        <div className="card-header">
          <h3 className="card-title">Referans Ekleme</h3>
        </div>
        <div className="card-body row">
          <form className="col-md-12">
            <div className="card">
              <div className="card-header col">
                <div>Buradan referans kodlarına ekleme yapabilirsiniz.</div>
                <div className="card-body">
                  <label>
                    Referans Kodu <span>*</span>
                  </label>
                  <input
                    type="text"
                    value={referenceCode}
                    className="form-control"
                    onChange={(e) => setReferenceCode(e.target.value)}
                  />
                  <br />
                  <label>Bitiş tarihi</label>
                  <input
                    type="date"
                    value={endDate}
                    className="form-control"
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                  <br />
                  <label>Geçerli olduğu eğitim</label>
                  <select
                    className="form-control"
                    onChange={(e) => setCourseId(e.target.value)}
                    defaultValue={0}
                  >
                    <option value={0} disabled>
                      Kurs Seçiniz
                    </option>
                    {courses.map((course, index) => {
                      return (
                        <option key={index} value={index + 1}>
                          {course?.name}
                        </option>
                      );
                    })}
                  </select>
                  <br />
                  <label>İndirim tipi</label>
                  <input
                    type="text"
                    placeholder="0- Fiyat, 1- Yüzde indirim"
                    value={typeId}
                    className="form-control"
                    onChange={(e) => setTypeId(e.target.value)}
                  />
                  <br />
                  <label>Limitsiz Referans</label>
                  <input
                    type="checkbox"
                    value={unLimited}
                    className="form-check"
                    onChange={(e) => setUnLimited(e.target.checked)}
                  />
                  <br />
                  {!unLimited && (
                    <div id="ref-type">
                      <label>Referans Adedi</label>
                      <input
                        type="number"
                        value={count}
                        min={0}
                        className="form-control"
                        onChange={(e) => setCount(e.target.value)}
                      />
                    </div>
                  )}
                  {typeId === "0" && (
                    <div id="ref-type" className="pt-4">
                      <label>Referans İndirim Miktarı</label>
                      <input
                        type="text"
                        value={discountPrice}
                        className="form-control"
                        onChange={(e) => setDiscountPrice(e.target.value)}
                      />
                    </div>
                  )}
                  {typeId === "1" && (
                    <div id="ref-type" className="pt-4">
                      <label>Referans İndirim Yüzdesi</label>
                      <input
                        type="text"
                        value={percent}
                        max={100}
                        className="form-control"
                        onChange={(e) => setPercent(e.target.value)}
                      />
                    </div>
                  )}
                  <button
                    onClick={postReference}
                    type="button"
                    className="btn bg-green-700 hover:bg-green-800 text-white btn-block col-md-2 mt-4"
                  >
                    Referans Kodu Ekle
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      {/* Referanslar */}
      <div className="card card-info">
        <div className="card-header">
          <h3 className="card-title">Referans Kodları</h3>
        </div>
        <div className="card-body row">
          <form className="col-md-12">
            <div className="card">
              <div className="card-header row justify-between m-2">
                <div>Burada Referans kodları listelenir.</div>
                <div className="flex flex-row items-center space-x-8">
                  <div>
                    Silinecek referans kodu:{" "}
                    {referenceCodes.map((code) =>
                      code.id === deletingCodeId ? code.referenceCode : ""
                    )}
                  </div>
                  <div className="">
                    <button
                      type="submit"
                      className="bg-red-600 rounded-md px-4 py-2 text-white"
                      onClick={deleteReferenceCode}
                    >
                      Sil
                    </button>
                  </div>
                </div>
                <div className="card-body col space-y-2">
                  <div
                    id="list-header"
                    className="flex flex-row justify-between font-bold p-2"
                  >
                    {/* <p className="text-red-500">Sil</p> */}
                    <p>Referans Kodu</p>
                    <p>Kurs Adı</p>
                    <p>Bitiş Tarihi</p>
                    <p>Referans tipi</p>
                    <p>Sınırsız Referans</p>
                    <p>İndirim adedi</p>
                    <p>İndirim yüzdesi</p>
                    <p>İndirim Miktarı</p>
                  </div>
                  <div className="flex flex-col-reverse">
                    {referenceCodes.map((code, index) => {
                      return (
                        <div
                          onClick={() => {
                            if (deletingCodeId !== 0) {
                              setDeletingCodeId(0);
                              setDeletingCodeId(code.id);
                            } else {
                              setDeletingCodeId(code.id);
                            }
                          }}
                          key={index}
                          className={`cursor-pointer flex flex-row col-md-12 justify-between items-center p-2 ${
                            index % 2 ? "" : "bg-gray-200"
                          } ${
                            deletingCodeId === code.id
                              ? "bg-red-500 duration-500 ease-in-out"
                              : ""
                          }`}
                        >
                          {/* <input
                          type="checkbox"
                          onChange={(e) =>
                            e.target.checked
                              ? setDeletingCodeId(code.id)
                              : setDeletingCodeId(null)
                          }
                        /> */}
                          <div className="flex flex-row items-center col-md-1 min-w-[100px]">
                            {code.referenceCode}
                          </div>
                          <div>
                            {code.courseId === "1"
                              ? "Dinleme ve Sesletim"
                              : code.courseId === "2"
                              ? "İngilizce Okuryazarlık"
                              : code.courseId === "3"
                              ? "Kampüs"
                              : "---"}
                          </div>
                          <div className="flex flex-row items-center col-md-1 min-h-[100px]">
                            {code.endDate ? code.endDate.split("T")[0] : "---"}
                          </div>
                          <div className="flex flex-row items-center col-md-1">
                            {code.typeId
                              ? "Yüzdelik indirim"
                              : "Fiyat indirimi"}
                          </div>
                          <div className="flex flex-row items-center col-md-1">
                            {code.unLimited ? "Sınırsız" : "Sınırlı"}
                          </div>
                          <div className="flex flex-row items-center col-md-1">
                            {code.count ? code.count : "---"}
                          </div>
                          <div className="flex flex-row items-center col-md-1">
                            {code.percent
                              ? "%" + code.percent.toFixed(2)
                              : "---"}
                          </div>
                          <div className="flex flex-row items-center col-md-1">
                            {code.discountPrice ? code.discountPrice : "---"}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default References;
