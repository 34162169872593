const api_url = "https://academy.idlac.com/api";
const site_url = "https://admin.idlac.com/";

const tempAdminToken =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MzksInVzZXJUeXBlIjo2LCJpYXQiOjE2OTAxOTc5MDUsImV4cCI6MjAwNTc3MzkwNX0.b6S5BEa9TnjuxyD5PIXwf4KG0kGbC36Xjbddx7wHPrU";

const optionsWeek = [
  { value: "1", label: "Başlangıç" },
  { value: "2", label: "Hazırlık" },
  { value: "3", label: "İngilizce 101" },
  { value: "4", label: "Var-Yok Kavramı" },
  { value: "5", label: "Teyit Soruları" },
  { value: "6", label: "Es-Zıt Anlamlı Kelimeler" },
  { value: "7", label: "Ekler ve zamanlar" },
  { value: "8", label: "Basit sıfat cümlecikleri" },
  { value: "9", label: "İngilizcede algı yönetimi" },
  { value: "10", label: "Edilgen Çatılar" },
  { value: "11", label: "Ettirgen Çatılar" },
  { value: "12", label: "Çok amaçlı kelimeler" },
  { value: "13", label: "Deyimsel Fiiller" },
  { value: "14", label: "Detaylı Sıfat Cümleleri" },
  { value: "15", label: "İsim Cümleleri" },
  { value: "16", label: "Aktarımlar" },
  { value: "17", label: "Bağlaçlar" },
  { value: "18", label: "Koşul Cümleleri" },
  { value: "19", label: "Devrik Cümleler" },
  { value: "20", label: "Fonetik Bilgisi" },
  { value: "21", label: "Diksiyon Kuralları" },
  { value: "22", label: "Sınavlarda Çıkmış Sorular" },
  { value: "23", label: "İş İngilizcesi" },
  { value: "24", label: "Sunum / Yazım Kuralları" },
  { value: "25", label: "Duyduğunu yazma / Diyalog" },
  { value: "26", label: "Durum senaryo / VİDEO SORULAR" },
  { value: "27", label: "SEVİYE TESPİT SINAVI 1" },
  { value: "28", label: "STS 2" },
  { value: "29", label: "STS 3" },
  { value: "30", label: "STS 4" },
  { value: "31", label: "STS 5" },
  { value: "32", label: "STS 6" },
  { value: "33", label: "MEZUNİYET SINAVI 1" },
  { value: "34", label: "MS 2" },
  { value: "35", label: "MS 3" },
  { value: "36", label: "MS 4" },
  { value: "37", label: "MS 5" },
  { value: "38", label: "MS 6" },
];

const optionsLevel = [
  { value: "1", label: "Dil öğrenmeye hazırlık" },
  { value: "2", label: "Yabancı dile adaptasyon" },
  { value: "3", label: "Temel düzeyde dil bilgisi" },
  { value: "4", label: "Orta düzeyde dil bilgisi" },
  { value: "5", label: "İleri düzeyde dil bilgisi" },
  { value: "6", label: "Fonetik semboller ve ses bilgisi" },
  { value: "7", label: "Diksiyon kusurları ve önlemler" },
  { value: "8", label: "Dil sınavlarına hazırlık" },
  { value: "9", label: "İş alanında yer alan ifade biçimleri" },
  { value: "10", label: "Sunuş prensiplerini kullanma" },
  { value: "11", label: "Duyduğunu anlama ve yazıya dökme" },
  { value: "12", label: "Hayat boyu öğrenme" },
  { value: "13", label: "SEVİYE TESPİT SINAVLARI" },
  { value: "14", label: "MEZUNİYET SINAVLARI" },
];

const isTrue = [
  { value: "1", label: "Evet" },
  { value: "0", label: "Hayır" },
];

const genders = [
  { value: "1", label: "Kadın" },
  { value: "0", label: "Erkek" },
];

const sectors = [
  { value: "1", label: "Adalet ve Güvenlik" },
  { value: "2", label: "Ağaç İşleri, Kağıt ve Kağıt Ürünleri" },
  { value: "3", label: "Bilişim Teknolojileri" },
  { value: "4", label: "Cam, Çimento ve Toprak" },
  { value: "5", label: "Çevre" },
  { value: "6", label: "Eğitim" },
  { value: "7", label: "Elektrik ve Elektronik" },
  { value: "8", label: "Enerji" },
  { value: "9", label: "Finans" },
  { value: "10", label: "Gıda" },
  { value: "11", label: "İnşaat" },
  { value: "12", label: "İş ve Yönetim" },
  { value: "13", label: "Kimya, Petrol, Lastik ve Plastik" },
  { value: "14", label: "Kültür, Sanat ve Tasarım" },
  { value: "15", label: "Maden" },
  { value: "16", label: "Makine" },
  { value: "17", label: "Medya, İletişim ve Yayıncılık" },
  { value: "18", label: "Metal" },
  { value: "19", label: "Otomotiv" },
  { value: "20", label: "Sağlık ve Sosyal Hizmetler" },
  { value: "21", label: "Spor ve Rekreasyon" },
  { value: "22", label: "Tarım, Avcılık ve Balıkçılık" },
  { value: "23", label: "Tekstil, Hazır Giyim, Deri" },
  { value: "24", label: "Ticaret (Satış ve Pazarlama)" },
  { value: "25", label: "Toplumsal ve Kişisel Hizmetler" },
  { value: "26", label: "Turizm, Konaklama, Yiyecek-İçecek Hizmetleri" },
  { value: "27", label: "Ulaştırma, Lojistik ve Haberleşme" },
];

const pollType = [
  { value: "choice", label: "Şıklı" },
  { value: "input", label: "Cümle" },
];

const wordTypes = [
  { value: "edat", label: "Edat" },
  { value: "sıfat", label: "Sıfat" },
];

export default {
  api_url,
  site_url,
  tempAdminToken,
  optionsWeek,
  optionsLevel,
  isTrue,
  genders,
  sectors,
  pollType,
  wordTypes,
};
