import { useEffect } from "react";
import { Link } from "react-router-dom";

const Menu = ({ page }) => {
  return (
    <div>
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        <Link to="/" className="brand-link text-center">
          <span className="brand-text">IDLAC</span>
        </Link>
        <div className="sidebar">
          <div className="user-panel mt-3 mb-3 d-flex justify-content-center">
            <div className="info">
              <p className="text-white">Admin</p>
            </div>
          </div>
          {/* <div className="form-inline">
            <div className="input-group" data-widget="sidebar-search">
              <input
                className="form-control form-control-sidebar"
                type="search"
                placeholder="Search"
                aria-label="Search"
              />
              <div className="input-group-append">
                <button className="btn btn-sidebar">
                  <i className="fas fa-search fa-fw" />
                </button>
              </div>
            </div>
          </div> */}
          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              <li className="nav-item menu-open">
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link
                      to="/"
                      className={
                        "nav-link " + (page === "Anasayfa" ? "active" : "")
                      }
                    >
                      <i className="far fa-circle nav-icon" />
                      <p>Anasayfa</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/soru-bankasi"
                      className={
                        "nav-link " + (page === "Soru Bankası" ? "active" : "")
                      }
                    >
                      <i className="far fa-circle nav-icon" />
                      <p>Soru Bankası</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/sozluk"
                      className={
                        "nav-link " + (page === "Sözlük" ? "active" : "")
                      }
                    >
                      <i className="far fa-circle nav-icon" />
                      <p>Sözlük</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/oku"
                      className={"nav-link " + (page === "Oku" ? "active" : "")}
                    >
                      <i className="far fa-circle nav-icon" />
                      <p>Oku</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/izle"
                      className={
                        "nav-link " + (page === "İzle" ? "active" : "")
                      }
                    >
                      <i className="far fa-circle nav-icon" />
                      <p>İzle</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/sorular"
                      className={
                        "nav-link " + (page === "Sorular" ? "active" : "")
                      }
                    >
                      <i className="far fa-circle nav-icon" />
                      <p>Sorular</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/soru-cevapla"
                      className={
                        "nav-link " + (page === "Soru Cevapla" ? "active" : "")
                      }
                    >
                      <i className="far fa-circle nav-icon" />
                      <p>Soru Cevapla</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/blog"
                      className={
                        "nav-link " + (page === "Blog" ? "active" : "")
                      }
                    >
                      <i className="far fa-circle nav-icon" />
                      <p>Blog</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/canli-dersler"
                      className={
                        "nav-link " + (page === "Canlı Dersler" ? "active" : "")
                      }
                    >
                      <i className="far fa-circle nav-icon" />
                      <p>Canlı Dersler</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/snap"
                      className={
                        "nav-link " + (page === "Snap" ? "active" : "")
                      }
                    >
                      <i className="far fa-circle nav-icon" />
                      <p>Snap</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/anket"
                      className={
                        "nav-link " + (page === "Anket" ? "active" : "")
                      }
                    >
                      <i className="far fa-circle nav-icon" />
                      <p>Anket</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/gecmis-canli-dersler"
                      className={
                        "nav-link " +
                        (page === "Geçmiş Canlı Dersler" ? "active" : "")
                      }
                    >
                      <i className="far fa-circle nav-icon" />
                      <p>Geçmiş Canlı Dersler</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/canli-ders-takvimi"
                      className={
                        "nav-link " +
                        (page === "Canlı Ders Takvimi" ? "active" : "")
                      }
                    >
                      <i className="far fa-circle nav-icon" />
                      <p>Canlı Ders Takvimi</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/uye-islemleri"
                      className={
                        "nav-link " + (page === "Üye İşlemleri" ? "active" : "")
                      }
                    >
                      <i className="far fa-circle nav-icon" />
                      <p>Üye İşlemleri</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/uye-olusturma"
                      className={
                        "nav-link " + (page === "Üye Oluşturma" ? "active" : "")
                      }
                    >
                      <i className="far fa-circle nav-icon" />
                      <p>Üye Oluşturma</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/sinif-islemleri"
                      className={
                        "nav-link " +
                        (page === "Sınıf İşlemleri" ? "active" : "")
                      }
                    >
                      <i className="far fa-circle nav-icon" />
                      <p>Sınıf İşlemleri</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/odeme-paketleri"
                      className={
                        "nav-link " +
                        (page === "Ödeme Paketleri" ? "active" : "")
                      }
                    >
                      <i className="far fa-circle nav-icon" />
                      <p>Ödeme Paketleri</p>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/referanslar"
                      className={
                        "nav-link " + (page === "Referanslar" ? "active" : "")
                      }
                    >
                      <i className="far fa-circle nav-icon" />
                      <p>Referanslar</p>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/fatura-olustur"
                      className={
                        "nav-link " +
                        (page === "Fatura Oluştur" ? "active" : "")
                      }
                    >
                      <i className="far fa-circle nav-icon" />
                      <p>Fatura Oluştur</p>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/paneller"
                      className={
                        "nav-link " + (page === "Paneller" ? "active" : "")
                      }
                    >
                      <i className="far fa-circle nav-icon" />
                      <p>Paneller</p>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/finans"
                      className={
                        "nav-link " + (page === "Finans" ? "active" : "")
                      }
                    >
                      <i className="far fa-circle nav-icon" />
                      <p>Finans</p>
                    </Link>
                  </li>
                  {/* <li className="nav-item">
                    <Link
                      to="/mobil-uygulama"
                      className={
                        "nav-link " + (page === "Mobil Uygulama" ? "active" : "")
                      }
                    >
                      <i className="far fa-circle nav-icon" />
                      <p>Mobil Uygulama</p>
                    </Link>
                  </li> */}
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </aside>
    </div>
  );
};

export default Menu;
