import { useState, useEffect } from "react";
import Swal from "sweetalert2";
import Var from "./variables";
import { useCookies } from "react-cookie";
import { MDBDataTableV5 } from "mdbreact";
import Select from "react-select";
import JoditEditor from "jodit-react";
import Moment from "moment";
import { PlusSmIcon, TrashIcon } from "@heroicons/react/solid";

const BlogPage = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [classId, setClassId] = useState("");
  const [selectedClassId, setSelectedClassId] = useState("");
  const [type, setType] = useState("");
  const [link, setLink] = useState("");
  const [photo, setPhoto] = useState(null);
  const [BlogID, setBlogID] = useState("");
  const [blogs, setBlogs] = useState([]);

  const [updateClicked, setUpdateClicked] = useState(false);

  const [classes, setClasses] = useState([]);
  const [classesReady, setClassesReady] = useState(false);
  const [classesInput, setClassesInput] = useState([]);
  const [dataReady, setDataReady] = useState(false);
  const [cookies] = useCookies();

  const config = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
  };

  const [tempData, setTempData] = useState([]);
  const [datatable, setDatatable] = useState({
    columns: [
      {
        label: "Başlık",
        field: "title",
      },
      // {
      //   label: "SınıfId",
      //   field: "classId",
      // },
      {
        label: "Sınıflar",
        field: "className",
      },
      {
        label: "Tür",
        field: "type",
      },
      {
        label: "Link",
        field: "link",
      },
      {
        label: "Fotoğraf",
        field: "photo",
      },
      {
        label: "Tarih",
        field: "date",
      },
    ],
    rows: [],
  });
  const [checkbox, setCheckbox] = useState("");

  const optionsType = [
    { value: "photo", label: "Fotoğraf" },
    { value: "live", label: "Canlı Ders" },
  ];

  const updateBlog = () => {
    const formDataUpdate = new FormData();

    if (type === "live") {
      if (
        title !== "" &&
        description !== "" &&
        classId !== "" &&
        photo !== null &&
        link !== ""
      ) {
        formDataUpdate.append("title", title);
        formDataUpdate.append("description", description);
        formDataUpdate.append("classId", classId);
        formDataUpdate.append("type", type);
        formDataUpdate.append("photo", photo);
        formDataUpdate.append("link", link);
        fetch(`${Var.api_url}/blog/${BlogID}`, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookies.admin_token}`,
          },
          body: formDataUpdate,
        })
          .then((res) => {
            if (!res.ok) {
              Toast.fire({
                icon: "error",
                title: "Bir hata oluştu",
              });
            }
            return res.json();
          })
          .then((data) => {
            Toast.fire({
              icon: "success",
              title: data.message,
            });
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          });
      } else {
        alert("Tüm boşlukları doğru bir şekilde doldurunuz");
      }
    } else if (type === "photo") {
      if (
        title !== "" &&
        description !== "" &&
        classId !== "" &&
        photo !== null
      ) {
        formDataUpdate.append("title", title);
        formDataUpdate.append("description", description);
        formDataUpdate.append("classId", classId);
        formDataUpdate.append("type", type);
        formDataUpdate.append("photo", photo);
        fetch(`${Var.api_url}/blog/${BlogID}`, {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${cookies.admin_token}`,
          },
          body: formDataUpdate,
        })
          .then((res) => {
            if (!res.ok) {
              Toast.fire({
                icon: "error",
                title: "Bir hata oluştu",
              });
            }
            return res.json();
          })
          .then((data) => {
            Toast.fire({
              icon: "success",
              title: data.message,
            });
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          });
      } else {
        alert("Tüm boşlukları doğru bir şekilde doldurunuz");
      }
    }
  };

  const updateClickHandler = () => {
    if (checkbox !== "") {
      setUpdateClicked(true);

      if (checkbox.type === "live") setLink(checkbox.link);
      setTitle(checkbox.title);
      setDescription(checkbox.description);
      setClassId(checkbox.classId);
      setType(checkbox.type);
      setPhoto(checkbox.photo);
      setBlogID(checkbox.id);
    } else {
      Toast.fire({
        icon: "error",
        title: "Önce seçim yapınız!",
      });
    }
  };

  const deleteClickHandler = () => {
    if (checkbox !== "") {
      if (
        window.confirm(
          checkbox.title + " bloğunu silmek istedğinize emin misiniz?"
        )
      ) {
        fetch(`${Var.api_url}/blog/${checkbox.id}`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookies.admin_token}`,
          },
          body: JSON.stringify({ path: link }),
        })
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            Toast.fire({
              icon: "success",
              title: data.message,
            });
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else {
      Toast.fire({
        icon: "error",
        title: "Önce seçim yapınız!",
      });
    }
  };

  var Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 5000,
  });

  const addClickHandler = () => {
    if (type === "photo") {
      if (
        title !== "" ||
        description !== "" ||
        classId !== "" ||
        photo !== null
      ) {
        fetch(`${Var.api_url}/blog/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookies.admin_token}`,
          },
          body: JSON.stringify({
            title: title,
            description: description,
            classId: classId,
            photo: photo,
            type: type,
          }),
        })
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            Toast.fire({
              icon: "success",
              title: data.message,
            });
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          })
          .catch((err) => alert(err));
      } else {
        alert("Tüm boşlukları doğru bir şekilde doldurunuz");
      }
    } else if (type === "live") {
      if (
        title !== "" &&
        description !== "" &&
        classId !== "" &&
        photo !== null &&
        link !== ""
      ) {
        fetch(`${Var.api_url}/blog/`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${cookies.admin_token}`,
          },
          body: JSON.stringify({
            title: title,
            description: description,
            classId: classId,
            photo: photo,
            link: link,
            type: type,
          }),
        })
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            Toast.fire({
              icon: "success",
              title: data.message,
            });
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          })
          .catch((err) => alert(err));
      } else {
        alert("Tüm boşlukları doğru bir şekilde doldurunuz");
      }
    } else {
      alert("Tüm boşlukları doğru bir şekilde doldurunuz");
    }
  };

  useEffect(() => {
    if (classesReady) {
      fetch(`${Var.api_url}/blog/admin`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${cookies.admin_token}`,
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          setTempData(data);
          setBlogs(data);
        })
        .catch((err) => console.log(err));
    }
  }, [classesReady]);

  useEffect(() => {
    if (datatable.length !== 0) {
      fetch(`${Var.api_url}/class/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${cookies.admin_token}`,
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          setClassesCLickHandler(data);
          setClassesReady(true);
          setClasses(data);
        })
        .catch((err) => console.log(err));
    }
  }, [datatable]);

  useEffect(() => {
    if (tempData.length !== 0) {
      let tempArray = [...tempData];
      tempData.map((data, index) => {
        tempArray[index].date = Moment(tempArray[index].date).format(
          "DD/MM/YYYY HH:mm:ss"
        );
      });

      for (let i = 0; i < tempData.length; i++) {
        tempArray[i].className = "";
      }

      if (classesReady) {
        for (let i = 0; i < tempArray.length; i++) {
          for (let j = 0; j < classes.length; j++) {
            if (
              tempArray[i].classId.split(",").includes(classes[j].id.toString())
            ) {
              tempArray[i].className += classes[j].title;
              tempArray[i].className += "\n";
            }
          }
        }
      }

      console.log(tempArray);
      setDatatable({ ...datatable, rows: tempArray });
      setDataReady(true);
    }
  }, [tempData, classesReady]);

  const getClassByID = (id) => {
    return classes.filter(function (classes) {
      return classes.id == id;
    });
  };

  const setClassesCLickHandler = (datas) => {
    let tempClasses = [];
    datas.map((data, i) => {
      tempClasses.push({
        value: data.id,
        label: data.title,
      });
    });
    setClassesInput(tempClasses);
  };

  const addClassId = () => {
    if (selectedClassId !== "") {
      if (
        !classId.toString().split(",").includes(selectedClassId.toString()) &&
        classId !== ""
      ) {
        setClassId(classId + "," + selectedClassId);
      } else if (classId.toString() === "") {
        setClassId(selectedClassId);
      } else {
        Toast.fire({
          icon: "warning",
          title: "Bu sınıf zaten kayıtlı!",
        });
      }
    } else {
      Toast.fire({
        icon: "warning",
        title: "Lütfen seçim yapınız!",
      });
    }
  };

  const removeClassId = () => {
    if (selectedClassId !== "") {
      if (classId.toString().split(",").includes(selectedClassId.toString())) {
        let tempClassIdArray = classId.split(",");
        for (let i = 0; i < tempClassIdArray.length; i++)
          if (tempClassIdArray[i] === selectedClassId.toString())
            tempClassIdArray.splice(i, 1);
        setClassId(tempClassIdArray.join(","));
      } else {
        Toast.fire({
          icon: "warning",
          title: "Bu sınıf zaten kayıtlı değil!",
        });
      }
    } else {
      Toast.fire({
        icon: "warning",
        title: "Lütfen seçim yapınız!",
      });
    }
  };

  return (
    <div>
      <div className="card card-info">
        <div className="card-header">
          <h3 className="card-title">Blog Ekleme</h3>
        </div>
        {/* /.card-header */}
        {/* form start */}
        <div className="card-body row">
          <form className="col-md-12">
            <div className="card">
              <div className="card-header row">
                <div>
                  Blog listesinde gözükmesini istediklerinizi aşağıya
                  ekleyebilirsiniz.
                </div>
              </div>
              {/* /.card-header */}
              <div className="card-body">
                <label>
                  Başlık <label className="text-danger">*</label>
                </label>
                <input
                  className="form-control"
                  placeholder="Başlık..."
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
                <br />
                <label>
                  Açıklama <label className="text-danger">*</label>
                </label>
                <JoditEditor
                  value={description}
                  config={config}
                  tabIndex={1} // tabIndex of textarea
                  onBlur={(newContent) => setDescription(newContent)} // preferred to use only this option to update the content for performance reasons
                />
                <br />
                <label>
                  Sınıfları <label className="text-danger">*</label>
                </label>
                <div className="flex flex-row mb-4">
                  {classId !== "" &&
                    classId.toString().includes(",") &&
                    classId.split(",").map((id, i) => {
                      if (id !== "")
                        return (
                          <p className="border rounded-xl p-2 mr-2" key={i}>
                            {getClassByID(id)[0].title}
                          </p>
                        );
                    })}
                  {classId !== "" && !classId.toString().includes(",") && (
                    <p className="border rounded-xl p-2 mr-2">
                      {getClassByID(classId)[0].title}
                    </p>
                  )}
                </div>
                <div className="flex flex-row">
                  <Select
                    className="w-60 mr-2"
                    onChange={(e) => setSelectedClassId(e.value)}
                    options={classesInput}
                    placeholder={"Sınıf"}
                  />
                  <PlusSmIcon
                    className="w-7 cursor-pointer"
                    onClick={addClassId}
                  />
                  <div className="border-r mx-2" />
                  <TrashIcon
                    className="w-7 cursor-pointer"
                    onClick={removeClassId}
                  />
                </div>
                <br />
                <div className="col-md-12 d-flex flex-row p-0">
                  <div className="col-md-12 d-flex flex-row items-center p-0">
                    <label className="m-0 mr-2" type="text">
                      Blog Tipi <label className="text-danger">*</label>
                    </label>
                    <Select
                      className="col-md-3"
                      defaultValue={type}
                      onChange={(e) => setType(e.value)}
                      options={optionsType}
                      placeholder={"Blog tipi seçiniz..."}
                    />
                  </div>
                </div>

                {type === "photo" && (
                  <>
                    <br />
                    <label className="mr-2">
                      Thumbnail <label className="text-danger">*</label>
                    </label>
                    <input
                      type="file"
                      placeholder="Dosya seçin..."
                      onChange={(e) => setPhoto(e.target.files[0])}
                    />
                  </>
                )}

                {type === "live" && (
                  <>
                    <br />
                    <label>
                      Linki <label className="text-danger">*</label>
                    </label>
                    <input
                      className="form-control"
                      placeholder="Link..."
                      value={link}
                      onChange={(e) => setLink(e.target.value)}
                    />
                    <br />
                    <label className="mr-2">
                      Thumbnail <label className="text-danger">*</label>
                    </label>
                    <input
                      type="file"
                      placeholder="Dosya seçin..."
                      onChange={(e) => setPhoto(e.target.files[0])}
                    />
                    <br />
                  </>
                )}

                <br />
                <br />
                <button
                  type="button"
                  className="btn bg-green-700 hover:bg-green-800 text-white btn-block col-md-2 ml-0"
                  onClick={() => {
                    updateClicked ? updateBlog() : addClickHandler();
                  }}
                >
                  {updateClicked ? "Güncelle" : "Ekle"}
                </button>
              </div>
              {/* /.card-body */}
            </div>
            {/* /.card */}
          </form>
        </div>
      </div>

      {/* Blog Listesi */}
      <div className="card card-info">
        <div className="card-header">
          <h3 className="card-title">Blog Listesi</h3>
        </div>
        {/* /.card-header */}
        {/* form start */}
        <div className="card-body row">
          <form className="col-md-12">
            <div className="card">
              <div className="card-header row">
                <div>
                  Blog listesindeki detayları burada düzenleyebilirsiniz.
                </div>
                <div className="col-md-4 ml-auto flex flex-wrap items-center justify-end">
                  {!updateClicked && (
                    <button
                      type="button"
                      className="btn bg-green-700 hover:bg-green-800 text-white w-32 h-10 m-1"
                      onClick={() => updateClickHandler()}
                    >
                      Güncelle
                    </button>
                  )}
                  {updateClicked && (
                    <button
                      type="button"
                      className="btn bg-yellow-500 hover:bg-yellow-600 text-white w-40 h-10 m-1"
                      onClick={() => setUpdateClicked(false)}
                    >
                      Vazgeç
                    </button>
                  )}
                  <button
                    type="button"
                    className="btn bg-red-700 hover:bg-red-800 text-white w-32 h-10 m-1"
                    onClick={() => deleteClickHandler()}
                  >
                    Sil
                  </button>
                </div>
              </div>
              {/* /.card-header */}
              <div className="card-body">
                {dataReady && (
                  <MDBDataTableV5
                    hover
                    entriesOptions={[5, 20, 25]}
                    entries={5}
                    pagesAmount={4}
                    data={datatable}
                    // scrollX
                    checkbox="true"
                    searchTop
                    searchBottom={false}
                    headCheckboxID="id2"
                    bodyCheckboxID="checkboxes2"
                    getValueCheckBox={(e) => {
                      checkbox === ""
                        ? setCheckbox(e)
                        : checkbox.id === e.id
                        ? setCheckbox("")
                        : setCheckbox(e);
                    }}
                  />
                )}
              </div>
              {/* /.card-body */}
            </div>
            {/* /.card */}
          </form>
        </div>
      </div>
    </div>
  );
};

export default BlogPage;
